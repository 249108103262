import React,{useEffect,useState} from "react";

import {Row,Col,Container, Fade} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import Loader from "../All/Loader";
import axios from "axios";
import {Const} from "../../../Utils/Const";
import FadeIn from 'react-fade-in';
import WebRoad, { useWebRoad } from "../WebRoad";
import { Scrollbars } from 'react-custom-scrollbars';

const Component = (props)=>{
    const {history} = props;
    const {backend} = Const;
    const {updateRoads,needhref} = useWebRoad();
    const [categories,setCategories] = useState([]);
    const [isloading,setisloading] = useState(false);
    const handleLoading = (param)=>setisloading(param);
    const execute = (id)=>{
        history.push({
          pathname: "/categorie",
          search: '?id='+id
        });
      }
    const consultCategories = async()=>{
        handleLoading(true);
        let post = {
            type:"consultCategories",
        }
        let r = await axios.post(backend,post).catch(e=>{
            alert("Error al conectar al servidor");
            setCategories([]);
            handleLoading(false)
        })
        if(r){
            if(r.data.error){
                alert(r.data.error);
            }else{
                setCategories(r.data.categories);
            }
            handleLoading(false)
        }
    }
    useEffect(()=>{
        updateRoads({
            level:1,
            element:{
                name:"Categorías",
                value:"",
                execute:needhref?()=>window.location.href=`/findbycetegorie` : ()=>history.push(`/findbycetegorie`)
            }
        })
        if(categories.length===0){
            consultCategories()
        }
    },[])
    const returnLarge = (val)=>{
        switch (val) {
            case 1:
                return "3"
                break;
                case 2:
                    return "6"
                    break;
        
            default:
                return "3"
                break;
        }
    }
    return(
        <div className="backgroundProfesional">
            <Scrollbars style={{display:"flex",width:"100%",height:"100%"}}>
                <Container style={{marginTop:10}}>
                    <WebRoad level={1} history={history}/>
                    <FadeIn>
                        <Row>
                            <Col xs="12">
                                {
                                    categories.length>0 ?
                                    <Row>
                                        {
                                            categories.map((item,index)=>(
                                                <Col xs="12" md={returnLarge(item.type)} key={index} align="center" onClick={()=>execute(item.value)} style={{cursor:"pointer",marginTop:20}}>
                                                    <ImgComponent url={item.image} />
                                                    
                                                </Col>
                                            ))
                                        }
                                    </Row> : 
                                    <div className="d-flex justify-content-center">
                                        <span className="profesionalText" style={{fontSize:25,color:"#000",fontWeight:"bold",textAlign:"center"}}>
                                            Buscando categorías..
                                        </span>
                                    </div>
                                }   
                            
                            </Col>
                        </Row>
                    </FadeIn>
                </Container>
            </Scrollbars>
        </div>
    )
}
const ImgComponent = ({url})=>{
    const [urlImage,setUrlImage] = useState(null);
    const urlImgWaiting = `${process.env.PUBLIC_URL}/img/img/ProfWaitImgQuad.png`;
    const onLoad = ()=>{
        setUrlImage(url);
    }
    return <img src={urlImage||urlImgWaiting} onLoad={onLoad} style={{width:"100%",cursor:"pointer"}} />
}
export default withRouter(Component);

/*
        <div className="backgroundProfesional">
            {
                isloading ? <Loader isVisible={isloading} /> :
                <Container>
                    <Row>
                        <Col xs="12">
                            {
                                categories.length>0 ?
                                <Row>
                                    {
                                        categories.map((item,index)=>(
                                            <Col xs="3" key={index}>
                                                <img onClick={()=>alert("Funcionalidad en desarrollo")} style={{width:"100%",cursor:"pointer"}} src={process.env.PUBLIC_URL + '/img/img/Imagen_cuadrada.png'} alt="testingImage" />
                                                <span className="profesionalText" style={{fontSize:15,color:"#fff",fontWeight:"bold",textAlign:"center"}}>{item.C_Nombre}</span>
                                            </Col>
                                        ))
                                    }
                                </Row> : 
                                <span className="profesionalText" style={{fontSize:25,color:"#fff",fontWeight:"bold",textAlign:"center"}}>
                                    No se encontraron categorias en la busqueda
                                </span>
                            }   
                        
                        </Col>
                    </Row>
                </Container>
            }
        </div>
*/