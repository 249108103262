export const Const = {
  //backend: "http://localhost:8081/", //Ambiente de desarrollo
  backend:"/", //Ambiente productivo
  //backend:"https://profesional712.com/", //Para pruebas en ambiente local con backend productivo
  isSafari: navigator.userAgent.toLowerCase().indexOf('safari') > -1,
  encryptText: (text)=>{
    let texto = text.toString();
    let abc = ["a","b","c","d","e","f","g","h","i","j","k","l","m","n","ñ","o","p","q","r","s","t","u","v","w","x","y","z","0","1","2","3","4","5","6","7","8","9"," "];
    let cba = ["e","v","z","1","a","l","9","h"," ","p","5","t","c","8","j","3","r","u","2","f","ñ","x","d","6","b","y","7","i","4","g","0","m","s","n","q","k","w","o"];
    let arrayString = Array.from(texto);
    let newString = "";
    for (var k = 0; k < arrayString.length; k++) {
      for (var i = 0; i < abc.length; i++) {
        if(arrayString[k].toLowerCase()===abc[i]){
          newString += cba[i];
        }
      }
    }
    return newString;
  }
}
export const checkMail = (mail)=>{
  let reg = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
  if (reg.test(mail)) {
    return null;
  }else{
    return "Formato de correo electrónico inválido";
  }
}
export const getBodySize = ()=>{
  let doc = document.getElementById('root');
  let navbar = document.getElementById('navbar');
  return {
    width:doc.clientWidth,
    height: doc.clientHeight-navbar.clientHeight
  }
}
export const mysqlTimeStampToDate = (timestamp)=>{
  var regex=/^([0-9]{2,4})-([0-1][0-9])-([0-3][0-9]) (?:([0-2][0-9]):([0-5][0-9]):([0-5][0-9]))?$/;
  var parts=timestamp.replace(regex,"$1 $2 $3 $4 $5 $6").split(' ');
  return new Date(parts[0],parts[1]-1,parts[2],parts[3],parts[4],parts[5]);
}
export const imageDoNotExist = `${process.env.PUBLIC_URL}/img/img/ProfWaitImgQuad.png`;
export const isMobile = ()=>{
  if (window.screen.availWidth<=800) {
      return true;
   }else{
      return false;
   }
}
export const encryptText = (text)=>{
    let texto = text.toString();
    let abc = ["a","b","c","d","e","f","g","h","i","j","k","l","m","n","ñ","o","p","q","r","s","t","u","v","w","x","y","z","0","1","2","3","4","5","6","7","8","9"," "];
    let cba = ["e","v","z","1","a","l","9","h"," ","p","5","t","c","8","j","3","r","u","2","f","ñ","x","d","6","b","y","7","i","4","g","0","m","s","n","q","k","w","o"];
    let arrayString = Array.from(texto);
    let newString = "";
    for (var k = 0; k < arrayString.length; k++) {
      for (var i = 0; i < abc.length; i++) {
        if(arrayString[k].toLowerCase()===abc[i]){
          newString += cba[i];
        }
      }
    }
    return newString;
}
