import React from "react";
import { PDFViewer } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import {Container} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import {number_format} from "locutus/php/strings";
const ROUND_NUMBER = (number)=>Math.round(number*100)/100;
const Quotation = (props)=>{
  const {history} = props;
  const products = history.location.state.car;
  const objectUser = history.location.state.userData;
  const folio = history.location.state.folio;
  const tipo_cliente = history.location.state.tipo_cliente;
  const precio_envio = history.location.state.sendCost;
  //const objectUser = useState({name: "Buscando...",id: "Buscando...",rfc: "Buscando...",dir: "Buscando...",giro: "Sin definir",fecha: "Buscando...",tel: "Buscando...",email: "Buscando...",envio: "Buscando..."});
  const getProductCost = (element)=>{
    let precio_bruto = ROUND_NUMBER(element.precio);
    let iva_prod = ROUND_NUMBER(element.newIvaProd);
    let precio_prod = ROUND_NUMBER( ( precio_bruto+iva_prod ) )
    let precio_total = ROUND_NUMBER( ( precio_bruto+iva_prod ) * (element.quantity) );
    return {
      unit:precio_prod,
      total:precio_total
    }
  }
  console.log(products);
  const checkColors = ()=>{
    let limit = products.length;
    if (limit%2===0) {
      console.log("retorno true")
      return true;
    }else{
      console.log("retorno false")
      return false;
    }
  }
  const getSubTotal = ()=>{
    let subtotal = 0;
    products.forEach((item, i) => {
      if (item.promos) {
        var porcentIva = parseFloat("1."+parseFloat(item.ivapa));
        subtotal += (item.promos.newPrice/porcentIva)*item.quantity;
      }else{
        let precio_bruto = ROUND_NUMBER(item.precio);
        let precio_total = ROUND_NUMBER( ( precio_bruto ) * (item.quantity) );
        subtotal += precio_total;
      }
    });
    return subtotal.toFixed(2);
  }
  const getTotal = (addEnvio)=>{
    let total = 0;
    products.forEach((item, i) => {
      if (item.promos) {
        total += item.promos.newPrice*item.quantity;
      }else{
        let precio_bruto = ROUND_NUMBER(item.precio);
        let iva_prod = ROUND_NUMBER(item.newIvaProd);
        let precio_total = ROUND_NUMBER( ( precio_bruto+iva_prod ) * (item.quantity) );
        total += precio_total;
      }
    });
    if(addEnvio){
      let envio = getFullSendCost();
      return (total+envio).toFixed(2);
    }else{
      return total.toFixed(2);
    }
  }
  const getSendCost = ()=>{
    //precio_envio&&precio_envio.total_cost?(parseFloat(precio_envio.total_cost) + parseFloat(precio_envio.costoEstafeta)).toFixed(2):"0.00";
    let sendCost = 0;
    if(precio_envio){
      if(precio_envio.costoEstafeta){
        sendCost += Math.round(precio_envio.costoEstafeta.total*100)/100;
      }
    }
    return sendCost;
  }
  const getServiceCost = ()=>{
    let sendCost = 0;
    if(precio_envio){
      if(precio_envio.total_cost){
        sendCost += Math.round(precio_envio.total_cost*100)/100;
      }
    }
    return sendCost;
  }
  const getFullSendCost = ()=>{
    let estafetaCost = getSendCost();
    let serviceCost = getServiceCost();
    return Math.round((estafetaCost+serviceCost)*100)/100;
  }
    return (
      <Container style={{}}>
        <PDFViewer style={{width:"100%",height:"90vh"}}>
          <Document>
            <Page size="A4">
              {/*Header*/}
              <View style={styles.view}>
                <View style={{width:"60%"}}>
                  <Image src="/img/logo.png" style={{width:"100%"}} />
                </View>
                <View style={{width:"40%",alignItems: "center"}}>
                  <Text style={{fontSize:15,fontFamily: 'Times-Bold'}}>No. de folio</Text>
                  <View style={{width:"100%",backgroundColor:"#c7c7c7",alignItems:"center",padding:5,border:"solid"}}>
                    <Text style={{fontSize:13,fontFamily: 'Times-Roman'}}>{folio}</Text>
                  </View>
                </View>
              </View>
              <View style={{flexDirection: 'row',padding:3,marginTop:-15}}>
                <Text>___________________________________________________________</Text>
              </View>
              {/*Client Data*/}
              <View style={styles.view}>
                <View style={{width:"50%",padding:10}}>
                  <View style={{width:"100%",flexDirection:"row"}}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>No. de cliente:</Text>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold',marginLeft:10}}> {objectUser.Id}</Text>
                  </View>
                  <View style={{width:"100%",flexDirection:"row"}}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>Atención a:</Text>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold',marginLeft:10}}>{objectUser.Nombre_comercial}</Text>
                  </View>
                  <View style={{width:"100%",flexDirection:"row"}}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>Razón Social:</Text>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold',marginLeft:10}}>{objectUser.Nombre_comercial}</Text>
                  </View>
                  <View style={{width:"100%",flexDirection:"row"}}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>RFC:</Text>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold',marginLeft:10}}>{objectUser.RFC}</Text>
                  </View>
                  <View style={{width:"100%",flexDirection:"row"}}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>Dirección Fiscal:</Text>
                  </View>
                  <View style={{width:"100%",flexDirection:"row",alignItems: "center",padding:10}}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>{objectUser.dir}</Text>
                  </View>
                </View>
                <View style={{width:"50%",padding:10}}>
                  <View style={{width:"100%",flexDirection:"row"}}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>Giro:</Text>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold',marginLeft:10}}> {objectUser.giro||"Sin definir"}</Text>
                  </View>
                  <View style={{width:"100%",flexDirection:"row"}}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>Fecha:</Text>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold',marginLeft:10}}>{objectUser.fecha}</Text>
                  </View>
                  <View style={{width:"100%",flexDirection:"row"}}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>Tel:</Text>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold',marginLeft:10}}>{objectUser.Telefono}</Text>
                  </View>
                  <View style={{width:"100%",flexDirection:"row"}}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>Email:</Text>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold',marginLeft:10}}>{objectUser.Correo}</Text>
                  </View>
                  <View style={{width:"100%",flexDirection:"row"}}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>Datos de envío:</Text>
                  </View>
                  <View style={{width:"100%",flexDirection:"row",alignItems: "center",padding:10}}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>{objectUser.dir}</Text>
                  </View>
                </View>
              </View>
              {/*Table Header*/}
              <View style={styles.view}>
                <View style={{width:"15%"}}>
                  <View style={styles.th}>
                    <Text style={{color:"#fff",fontSize:10,fontFamily: 'Times-Bold'}}>CÓDIGO</Text>
                  </View>
                </View>
                <View style={{width:"15%"}}>
                  <View style={styles.th}>
                    <Text style={{color:"#fff",fontSize:10,fontFamily: 'Times-Bold'}}>CANTIDAD</Text>
                  </View>
                </View>
                <View style={{width:"40%"}}>
                  <View style={styles.th}>
                    <Text style={{color:"#fff",fontSize:10,fontFamily: 'Times-Bold'}}>DESCRIPCIÓN</Text>
                  </View>
                </View>
                <View style={{width:"15%"}}>
                  <View style={styles.th}>
                    <Text style={{color:"#fff",fontSize:10,fontFamily: 'Times-Bold'}}>PRECIO</Text>
                  </View>
                </View>
                <View style={{width:"15%"}}>
                  <View style={styles.th}>
                    <Text style={{color:"#fff",fontSize:10,fontFamily: 'Times-Bold'}}>TOTAL</Text>
                  </View>
                </View>
              </View>
              {/*Table Data*/}
              {
                products.map((item,index)=>(
                  <View style={styles.view} key={index}>
                    <View style={{width:"15%"}}>
                      <View style={(index%2===0)?styles.td:styles.td2}>
                        <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>{item.P_Codigo}</Text>
                      </View>
                    </View>
                    <View style={{width:"15%"}}>
                      <View style={(index%2===0)?styles.td:styles.td2}>
                        <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>{item.quantity}</Text>
                      </View>
                    </View>
                    <View style={{width:"40%"}}>
                      <View style={(index%2===0)?styles.td:styles.td2}>
                        <Text style={{fontSize:8,fontFamily: 'Times-Bold'}}>{item.label}</Text>
                      </View>
                    </View>
                    <View style={{width:"15%"}}>
                      <View style={(index%2===0)?styles.td:styles.td2}>
                        <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>$ {(item.promos)?item.promos.newPrice.toFixed(2):number_format(getProductCost(item).unit,2)}</Text>
                      </View>
                    </View>
                    <View style={{width:"15%"}}>
                      <View style={(index%2===0)?styles.td:styles.td2}>
                        <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>$ {(item.promos)?(item.promos.newPrice*item.quantity).toFixed(2):number_format(getProductCost(item).total,2)}</Text>
                      </View>
                    </View>
                  </View>
                ))
              }
              {/*Table Totals*/}

              <View style={styles.view}>
                <View style={{width:"35%"}}>
                  <View style={styles.emptyTd}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>{""}</Text>
                  </View>
                </View>
                <View style={{width:"20%"}}>
                  <View style={styles.emptyTd}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>{""}</Text>
                  </View>
                </View>
                <View style={{width:"15%"}}>
                  <View style={(checkColors())?styles.td:styles.td2}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>{"Subtotal"}</Text>
                  </View>
                </View>
                <View style={{width:"15%"}}>
                  <View style={(checkColors())?styles.td:styles.td2}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>{" "}</Text>
                  </View>
                </View>
                <View style={{width:"15%"}}>
                  <View style={(checkColors())?styles.td:styles.td2}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>$ {number_format(getSubTotal(),2)}</Text>
                  </View>
                </View>
              </View>
              <View style={styles.view}>
                <View style={{width:"35%"}}>
                  <View style={styles.emptyTd}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>{""}</Text>
                  </View>
                </View>
                <View style={{width:"20%"}}>
                  <View style={styles.emptyTd}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>{""}</Text>
                  </View>
                </View>
                <View style={{width:"15%"}}>
                  <View style={(!checkColors())?styles.td:styles.td2}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>{"IVA"}</Text>
                  </View>
                </View>
                <View style={{width:"15%"}}>
                  <View style={(!checkColors())?styles.td:styles.td2}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>{" "}</Text>
                  </View>
                </View>
                <View style={{width:"15%"}}>
                  <View style={(!checkColors())?styles.td:styles.td2}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>$ {number_format((parseFloat(getTotal())-parseFloat(getSubTotal())),2)}</Text>
                  </View>
                </View>
              </View>
              <View style={styles.view}>
                <View style={{width:"35%"}}>
                  <View style={styles.emptyTd}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>{""}</Text>
                  </View>
                </View>
                <View style={{width:"20%"}}>
                  <View style={styles.emptyTd}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>{""}</Text>
                  </View>
                </View>
                <View style={{width:"15%"}}>
                  <View style={(checkColors())?styles.td:styles.td2}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>{"Mensajería"}</Text>
                  </View>
                </View>
                <View style={{width:"15%"}}>
                  <View style={(checkColors())?styles.td:styles.td2}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>{" "}</Text>
                  </View>
                </View>
                <View style={{width:"15%"}}>
                  <View style={(checkColors())?styles.td:styles.td2}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>$ {number_format(getSendCost(),2)}</Text>
                  </View>
                </View>
              </View>
              <View style={styles.view}>
                <View style={{width:"35%"}}>
                  <View style={styles.emptyTd}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>{""}</Text>
                  </View>
                </View>
                <View style={{width:"20%"}}>
                  <View style={styles.emptyTd}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>{""}</Text>
                  </View>
                </View>
                <View style={{width:"15%"}}>
                  <View style={(checkColors())?styles.td:styles.td2}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>{"Servicio"}</Text>
                  </View>
                </View>
                <View style={{width:"15%"}}>
                  <View style={(checkColors())?styles.td:styles.td2}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>{" "}</Text>
                  </View>
                </View>
                <View style={{width:"15%"}}>
                  <View style={(checkColors())?styles.td:styles.td2}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>$ {number_format(getServiceCost(),2)}</Text>
                  </View>
                </View>
              </View>
              <View style={styles.view}>
                <View style={{width:"35%"}}>
                  <View style={styles.emptyTd}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>{""}</Text>
                  </View>
                </View>
                <View style={{width:"20%"}}>
                  <View style={styles.emptyTd}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>{""}</Text>
                  </View>
                </View>
                <View style={{width:"15%"}}>
                  <View style={(!checkColors())?styles.td:styles.td2}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>{"Total M.N."}</Text>
                  </View>
                </View>
                <View style={{width:"15%"}}>
                  <View style={(!checkColors())?styles.td:styles.td2}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>{" "}</Text>
                  </View>
                </View>
                <View style={{width:"15%"}}>
                  <View style={(!checkColors())?styles.td:styles.td2}>
                    <Text style={{fontSize:10,fontFamily: 'Times-Bold'}}>$ {number_format(getTotal(true),2)}</Text>
                  </View>
                </View>
              </View>
              {/*Footer*/}
              <View style={styles.view}>
                <View style={{width:"100%",alignItems:"center",marginTop:30}}>
                  <View style={{width:"50%",textAlign:"center"}}>
                    <Text style={{fontSize:10,fontFamily:"Times-Bold"}}>
                      Esta cotizacion está sujeta a diponibilidad y existencias.
                    </Text>
                  </View>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </Container>
    )
}
const styles = StyleSheet.create({
  view: {
    paddingTop:5,
    paddingBottom:5,
    paddingLeft:20,
    paddingRight:20,
    flexDirection: 'row'
  },
  section: {
    margin: 10,
    padding: 20,
    flexGrow: 1
  },
  td2:{
    width: "100%",
    display: "table",
    alignItems: "center",
    padding: 3,
    borderStyle: "solid",
    borderColor: "white",
    borderWidth: 1,
    backgroundColor: "#e6e6e6",
    color: "#000",
    marginTop: -10
  },
  td:{
    width: "100%",
    display: "table",
    alignItems: "center",
    padding: 3,
    borderStyle: "solid",
    borderColor: "white",
    borderWidth: 1,
    backgroundColor: "#c1c1c1",
    color: "#000",
    marginTop: -10
  },
  emptyTd:{
    width: "100%",
    display: "table",
    alignItems: "center",
    padding: 3,
    borderStyle: "solid",
    borderColor: "white",
    backgroundColor: "#fff",
    borderWidth: 1,
    color: "#000",
    marginTop: -10
  },
  th:{
    width: "100%",
    display: "table",
    backgroundColor: "#232321",
    alignItems: "center",
    padding: 3,
    borderStyle: "solid",
    borderColor: "white",
    borderWidth: 1,
    color: "#fff"
  }
});


export default withRouter(Quotation);
