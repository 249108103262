import React from "react";
import {withRouter} from "react-router-dom";
import {Container} from "react-bootstrap";
import {OrderProvider,useOrder} from "./Context";
import Loader from "../All/Loader";
import Content from "./Content";

const Order = (props)=>{
  const {toast} = props;
  console.log(toast);
  const user = localStorage.conection;
  const folio = props.location.search.replace("?folio=","") || null;
  return(
    <>
    {
      user ?
      <OrderProvider toast={toast} id_order={folio} user={user}>
        <TunnelOrder />
      </OrderProvider> :
        <div className="backgroundProfesional">
          <Container className="d-flex justify-content-center">
            <div style={{borderStyle:"solid",borderWidth:2,borderColor:"#fff",borderRadius:10,width:300,textAlign:"center",padding:20}}>
              <p style={{fontWeight:"bold",fontSize:20,color:"red"}}>
                ERROR
              </p>
              <p style={{color:"#fff"}}>
                {"Por favor inicie sesión para visualizar sus ordenes de compra."}
              </p>
            </div>
          </Container>
        </div>
    }
    </>
  )
}
const TunnelOrder = ()=>{
  const {order,auth,loadingOrder,errorMsg} = useOrder();
  return(
    <>
      {
        loadingOrder ?
          <Loader isVisible={true} /> :
            auth ?
              order ?
                <>
                  <Content />
                </> : null :
                  <Container className="d-flex justify-content-center" style={{marginTop:20}}>
                    <div style={{borderStyle:"solid",borderWidth:2,borderColor:"#fff",borderRadius:10,width:300,textAlign:"center",padding:20,height:150}}>
                      <p style={{fontWeight:"bold",fontSize:20,color:"red"}}>
                        ERROR
                      </p>
                      <p style={{color:"#fff"}}>
                        {errorMsg}
                      </p>
                    </div>
                  </Container>
      }
    </>
  )
}
export default withRouter(Order);
