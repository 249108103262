import React from "react";
import { withRouter } from "react-router-dom";
import { NavBarProvider, useNavBar } from "./store";
import Model from "./model";
import MobileModel from "./MobileModel";

const Navbar = (props)=>{
    return(
        <NavBarProvider {...props}>
            <NavbarTunnel />
        </NavBarProvider>
    )
}

const NavbarTunnel = ()=>{
    return <Model />
}

export default withRouter(Navbar);