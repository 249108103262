import React, {Fragment,createContext,useContext,useMemo,useState,useEffect} from "react";
import {useHistory,useLocation} from "react-router-dom";
import {FaArrowRight, FaWindows} from "react-icons/fa";
import { isMobile } from "../../../Utils/Const";
const WebRoadContext = createContext();

export const WebRoadProvider = (props)=>{
    const location = useLocation();
    const [needhref,setneedhref] = useState(false);
    const history = useHistory();
    const [road,setRoad] = useState([
        {
            name:"Inicio",
            value:"",
            execute:()=>window.location.href="/"
        }
    ])
    const updateRoads = ({level,element})=>{
        let filteredRoads = road.filter((el,i)=>(i<level&&el.name!==element.name));
        filteredRoads.push(element);
        setRoad(filteredRoads.concat());
    }
    useEffect(()=>{
        let quant = location.pathname.split("/").length;
        if(quant>2){
          setneedhref(true)
        }else{
          setneedhref(false)
        }
    },[location.pathname])

    const value = useMemo(()=>{
        return({
          road,
          updateRoads,
          needhref
        })
      },[road,needhref])
    return <WebRoadContext.Provider value={value} {...props}/>
}

export const useWebRoad = ()=>useContext(WebRoadContext)

const WebRoad = (props)=>{
    const {level,history} = props;
    const isWeb = !isMobile();
    const {road} = useWebRoad();
    if(isWeb){
        return(
            <Fragment>
                <div className="d-flex justify-content-start">
                    {
                        road.map((el,i)=><RoadElement key={i} item={el} index={i} level={level} showArrow={road[i+1]?true:false} history={history} />)
                    }
                </div>
            </Fragment>
        )
    }
    return null
}

const RoadElement = ({item,level,index,showArrow,history})=>{
    const {needhref} = useWebRoad();
    const execute = ()=>{
        item.execute()
    }
    return(
        <Fragment>
            <div style={{display:"flex",flexDirection:"row"}}>
                <span onClick={index!==level?execute:null} style={{
                        color:"#fff",
                        fontWeight:index===level?"bold":null,
                        cursor:index===level?"no-drop":"pointer"
                    }}
                >
                    {item.name}
                </span>
                {
                    (index<level)&&showArrow ? 
                    <span 
                        style={{
                            color:"#fff",
                            fontWeight:index===level?"bold":null,
                            cursor:index===level?"no-drop":"pointer",
                            marginLeft:10,
                            marginRight:10
                        }}
                    >
                        {">"}
                    </span>
                    : null
                }
            </div>
        </Fragment>
    )
}

export default WebRoad;
