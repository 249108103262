import React,{useState,useEffect} from 'react';
import Axios from 'axios';
import { Const } from '../../../../Utils/Const';
const Billing = React.createContext();
export const BillingProvider = (props)=>{
    const {toast} = props;
    const [order,setOrder] = useState(null);
    const [pageLoad,setPageLoad] = useState(false);
    const handleLoad = (param)=>setPageLoad(param);
    const [pageError,setPageError] = useState(null);
    const [advancedData,setAdvancedData] = useState(null);
    const [advancedError,setAdvancedError] = useState(null);
    const [advancedLoad,setAdvancedLoad] = useState(false);
    const handleAdvLoad = (arg)=>setAdvancedLoad(arg);
    
    //Consultas
    const searchOrder = async(folio)=>{
        let data = {
            type:"getOrder",
            token:localStorage.conection||null,
            folio
        };
        handleLoad(true);
        let r = await Axios.post(Const.backend+"Billing",data).catch(e=>{
            setPageError("Error al conectar al servidor");
        })
        if(r){
            if(r.data.error){
                setPageError(r.data.error);
            }else{
                setOrder(r.data.result);
            }
        }
        handleLoad(false);
    }
    //Funciones de ayuda
    const reset = ()=>{
        setOrder(null);
        setPageError(null);
    }
    const cutDate = (d)=>{
        if(d){
            return d.split("T")[0];
        }else{
            return "Fecha no especificada."
        }
    }
    //Validaciones
    const validOrder = (fecha_compra)=>{
        let validDate = new Date();
        validDate.setDate(validDate.getDate()-30);
        console.log({
            validDate,
            fecha_compra
        })
        if(fecha_compra>=validDate){
            console.log("Fecha compra es mayor o igual a la fecha valida")
            return true;
        }else{
            return false;
        }
    }

    const checkCP = (cp)=>{
        if(!isNaN(cp)){
            if(cp.trim().length===5){
                return null
            }else{
                return "El código postal debe contener 5 números."
            }
        }else{
            return "Formato de código postal inválido."
        }
    }

    const checkEMPTY = (value,label)=>{
        if(value){
            if(value.length<=255){
                return null;
            }else{
                return `${label} es demaciado largo.`;
            }
        }else{
            return `${label} No puede estar vacio`;
        }
    }

    const checkRFC = (rfc)=>{
        if(!rfc){
            return "Digite su rfc para continuar."
        }else{
            if(rfc.trim().length===12||rfc.trim().length===13){
                return null
            }else{
                return "Formato de rfc inválido."
            }
        }
    }
    //cuando cambia el estado order
    useEffect(()=>{
        if(order&&order.folio){
            const getAdvancedData = async()=>{
                if(order&&order.folio){
                    setAdvancedError(null);
                    let data = {
                        type:"getAdvancedData",
                        token:localStorage.conection||null,
                        order
                    };
                    handleAdvLoad(true);
                    let r = await Axios.post(Const.backend+"Billing",data).catch(e=>{
                        setAdvancedError("Error al conectar al servidor");
                    })
                    if(r){
                        if(r.data.error){
                            setAdvancedError(r.data.error);
                        }else{
                            setAdvancedData(r.data.result);
                        }
                    }
                    handleAdvLoad(false);
                }
            }
            getAdvancedData()
        }
    },[order])
    //retorno de estados
    const value = React.useMemo(()=>{
        return({
            order,
            pageLoad,
            pageError,
            toast,
            advancedError,
            advancedLoad,
            advancedData,
            searchOrder,
            reset,
            cutDate,
            validOrder,
            checkCP,
            checkEMPTY,
            checkRFC
        })
    },[order,pageLoad,pageError,advancedError,advancedLoad,advancedData])
    return <Billing.Provider value={value} {...props}/>
}
export const useBilling = ()=> React.useContext(Billing);