import React,{useState,useEffect} from "react";
import axios from "axios";
import {Const,isMobile} from "../../../Utils/Const";
import {Row,Col,Container} from "react-bootstrap";
import {Typeahead} from 'react-bootstrap-typeahead';
import {withRouter} from "react-router-dom";
import Load from "../All/Loader";
import {FaSearch} from "react-icons/fa";
import FadeIn from 'react-fade-in';
import InputSearch from "../All/InputSearch";
import { Scrollbars } from 'react-custom-scrollbars';
const Search = (props)=>{
  const {history} = props;
  const [products,setProducts] = useState([]);
  const [isVisible,setIsVisible] = useState(false);
  const searchProducts = async()=>{
    setIsVisible(true);
    let error = null;
    const response = await axios.post(Const.backend,{type:"productsConsult"}).catch((e)=>error=e);
    if(error){
      console.log(error)
    }else{
      setProducts(response.data.rows);
    }
    setIsVisible(false);
  }
  useEffect(() => {
    (async()=>{
      searchProducts()
    })();
  }, []);
  return (
    <div className="backgroundProfesional">
      <Scrollbars style={{display:"flex",width:"100%",height:"100%"}}>
        <Container className="mt-5">
          <FadeIn>
            <div style={{flexDirection:"row",display:"flex"}}>
              <div style={{width:isMobile()?"90%":"95%"}}>
                <InputSearch 
                  options={products}
                  onSetValue={(el)=>history.push({
                    pathname: "/products/"+el.value
                  })}
                />
              </div>
              <div style={{width:isMobile()?"10%":"5%"}} className="d-flex justify-content-end">
                <FaSearch style={{color:"#fff",fontSize:60,marginTop:3}} />
              </div>
            </div>
          </FadeIn>
        </Container>
        <Load isVisible={isVisible} color={"#000"}/>
      </Scrollbars>
    </div>
  )
}
/*
<Typeahead id="searchInput"
                inputProps={{placeholder:"Buscar Producto"}}
                filterBy={['P_Nombre']}
                onChange={(selected) => {
                  if(selected[0]){
                    history.push({
                      pathname: "/products/"+selected[0].id
                    });
                  }else{
                    console.log(selected)
                  }
                }}
                style={{width:"100%"}}
                options={products}
              />
*/
export default withRouter(Search);
