import React, {Fragment,useState,useRef} from "react";
import { Const } from "../../../../Utils/Const";
import { useValidate } from "./Store";
import axios from "axios";
import Loader from 'react-loader-spinner';
import { Col, Row } from "react-bootstrap";
import {FaRegFolderOpen} from "react-icons/fa";
import "./styles.css"

const Content = ()=>{
    const {screenData} = useValidate();
    const {status} = screenData;

    if(status){
        return <StatusViewer />
    }
    return <CreateValidation />
}
export default Content;

const CreateValidation = ()=>{
    const {toast,searchData,screenData} = useValidate();
    const {availDocs} = screenData;
    const [file,setFile] = useState(null);
    const inputFileRef = useRef(null);
    const saveImage = async(e)=>{
        if(inputFileRef.current.files[0]){
          let formData = new FormData();
          let token = localStorage.conection||null;
          formData.append("validate-document", inputFileRef.current.files[0]);
          formData.append("token",token);
    
          let r = await axios.post(Const.backend+"vaidate-document",formData,{'content-type': 'multipart/form-data',}).catch(e=>{
              console.log(e);
              toast.error("Error al conectar al servidor.","error");
          })
          if(r){
            if(r.data.error){
                toast.error(r.data.error,"error");
            }else{
                toast.info("Documento actualizado exitosamente.","success")
                searchData(true);
            }
          }
        }else{
            toast.error("Error formato de imágen inválido.","error");
        }
    }
    const returnFileName = ()=>{
        if(file){
            if(file.indexOf("/")!==-1){
                let newStringArray = file.split("/");
                console.log(newStringArray);
                return newStringArray[newStringArray.length-1];
            }else{
                let newStringArray = file.split("\\");
                return newStringArray[newStringArray.length-1];
            }
        }else{
            return "Presiona el folder para seleccionar una imágen."
        }
    }
    return(
        <Fragment>
            <input 
                type="file" 
                accept="image/*" 
                value={file} 
                onChange={(e)=>setFile(e.target.value)} 
                ref={inputFileRef} 
                style={{
                    display:"none"
                }} 
            />
            <div className="d-flex justify-content-center p-2">
                <span style={{color:"#fff",fontSize:15,fontWeight:"bold"}}>
                    El profesional obtiene <strong>PRECIOS PREFERENCIALES</strong> al registrarse.
                    A continuación agrega una imágen que avale su Profesión.
                </span>
            </div>
            <Row>
                <Col xs="12">
                    <span style={{color:"#fff",fontWeight:"bold"}}>
                        Cargar archivo / buscar
                    </span>
                </Col>
                <Col xs="12" md="8">
                    <Row style={{marginTop:15}}>
                        <Col xs="2" className="d-flex justify-content-center">
                            <FaRegFolderOpen
                                onClick={()=>inputFileRef.current.click()}
                                size={60} 
                                style={{
                                    color:"#fff",
                                    cursor:"pointer"
                                }}
                            />
                        </Col>
                        <Col xs="10">
                                <input 
                                    value={returnFileName()}
                                    className="input-document-profesional"
                                    style={{
                                        width:"100%",
                                        paddingLeft:5,
                                        paddingRight:5,
                                        borderStyle:"solid",
                                        borderWidth:2,
                                        borderColor:"#a1a1a1",
                                        marginTop:10
                                    }}
                                    readOnly
                                />
                        </Col>
                        <Col xs="12" className="p-2">
                            <button onClick={saveImage} className="btn-profesional-success">
                                Guardar
                            </button>
                        </Col>
                    </Row>
                </Col>
                <Col xs="12" md="4">
                    <div className="jumbotron" style={{backgroundColor:"#a1a1a1"}}>
                        <div className="d-flex justify-content-center">
                            <span style={{color:"#000"}}>
                                Documentos permitidos:
                            </span>
                        </div>
                        {
                            availDocs.map((doc,indexDoc)=>(
                                <div className="d-flex justify-content-center" key={indexDoc}>
                                    <span style={{color:"#000"}}>
                                        {doc}
                                    </span>
                                </div>
                            ))
                        }
                    </div>
                </Col>
            </Row>
            
        </Fragment>
    )
}
const StatusViewer = ()=>{
    const {screenData} = useValidate();
    const {status} = screenData;
    return(
        <Fragment>
            <div className="jumbotron mt-5">
                <Row>
                    <Col xs="12" md="6">
                        <div className="d-flex justify-content-start">
                            <span>
                                ESTADO DE VALIDACIÓN:
                            </span>
                        </div>
                    </Col>
                    <Col xs="12" md="6">
                        <div className="d-flex justify-content-end">
                            <span style={{color:status.id_status===1?"#a1a1a1":"green"}}>
                                {status.nombre_status}
                            </span>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="d-flex justify-content-center p-4">
                <span style={{color:"#fff",textAlign:"center"}}>
                    {
                        status.id_status === 1 ? 
                        "Estamos validando tu información, al concluir el proceso se te notificará a tu correo o en tu apartado MI CUENTA. Este proceso puede durar algunos días, agradecemos tu comprención." 
                        : 
                        status.id_status === 2 ?
                        "Tu perfíl ha sido correctamente validado ya puedes disfrutar de los beneficios de tu perfíl profesional."
                        :
                        "Lo sentimos tu validación ha sido rechazada."
                    }
                </span>
            </div>
        </Fragment>
    )
}