import React,{useEffect,useState} from "react";
import {Row,Col,Container} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import {toast} from "react-toastify";
import ImageSlider from "../All/ImageSlider";
import FadeIn from 'react-fade-in';
import axios from "axios";
import Loader from 'react-loader-spinner';
import { Const,imageDoNotExist,isMobile,isSafari } from "../../../Utils/Const";
import {useWebRoad} from "../WebRoad";
import { Scrollbars } from 'react-custom-scrollbars';
const Home = (props)=>{
  const {history,user} = props;
  const [slider,setSlider] = useState([]);
  const {updateRoads} = useWebRoad();
  const [elements,setElements] = useState(null);
  const [executed,setExecuted] = useState(true);
  const [redes,setRedes] = useState({
    url_facebook:"",
    url_instagram:"",
    url_twitter:"",
    url_youtube:""
  });
  const [load,setLoad] = useState(false);

  const searchHomeData = async()=>{
    setLoad(true)
    let r = await axios.post(Const.backend,{type:"getHomeData"}).catch(e=>{
      alert("Error al conectar al servidor")
      setLoad(false)
    })
    if(r){
      if(r.data.error){
        alert(r.data.error)
      }else{
        let element = {
          name:"Inicio",
          value:null,
          execute:()=>history.push("/")
        }
        updateRoads({
            level:0,
            element
        })
        let result = r.data.result;
        setSlider(result.slider);
        setElements(result.elements);
        if(result.redes){
          setRedes(result.redes);
        }
      }
      setLoad(false)
    }
  }
  useEffect(()=>{
    if(slider.length<1&&executed){
      setExecuted(false);
      searchHomeData()
    }
  },[slider])
  return(
    <div className="backgroundProfesional">
      <Scrollbars style={{display:"flex",width:"100%",height:"100%"}}>
        {
          load ? 
          <div style={{width:"100%",marginTop:10}} className="d-flex justify-content-center">
                <Loader
                  type="Oval"
                  color={"#fff"}
                  height={150}
                  width={150}
                  visible={true}
                />
          </div> : slider.length > 0 ? <Content slider={slider} redes={redes} elements={elements} history={history} user={user} /> : 
          <span style={{fontSize:20,fontWeight:"bold",color:"#fff"}}>
            Error en la carga de datos..
          </span>
        }
      </Scrollbars>
    </div>
  )
}

export default withRouter(Home);

const Content = (props)=>{
  const {history,slider,elements,user,redes} = props;
  const [email,setEmail] = useState("");
  const executeSlider = (item)=>{
    switch (item.type) {
      case 1:
        history.push({
          pathname:`/products/${item.value}`
        })
        break;
      case 2:
        history.push({
          pathname: "/categorie",
          search: '?id='+(item.value||"")
        })
        break;
      case 3:
        history.push({
          pathname: "/subcategories",
          search: '?id='+(item.value||"")
        })
        break;   
      default:
        history.push({
          pathname: "/family",
          search: '?id='+(item.value||"")
        })
        break;
    }
  }
  return(
    <Container style={{paddingTop:20}}>

        <FadeIn>
        <Row>
          <Col xs="12">
            <Row>
              <Col xs="8" md="8">
                <ImageSlider execute={executeSlider} images={slider} containerStyle={{padding:0}} />
              </Col>
              <Col xs="4" md="4">
                <img 
                  onClick={()=>executeSlider(elements[0])} 
                  style={{width:"100%",cursor:"pointer",backgroundColor:"#fff"}} 
                  src={elements[0].url||imageDoNotExist} 
                  alt="element-0" />
                <img 
                  onClick={()=>executeSlider(elements[1])} 
                  style={{width:"100%",marginTop:30,cursor:"pointer",backgroundColor:"#fff"}} 
                  src={elements[1].url||imageDoNotExist} 
                  alt="element-1" />
              </Col>
            </Row>
            <Row style={{marginTop:20}}>
              <Col xs="4" md="4">
                <img 
                  onClick={()=>executeSlider(elements[2])} 
                  style={{width:"100%",cursor:"pointer",backgroundColor:"#fff"}} 
                  src={elements[2].url||imageDoNotExist} 
                  alt="element-2" />
              </Col>
              <Col xs="8" md="8">
                <img 
                  onClick={()=>executeSlider(elements[3])} 
                  style={{width:"100%",cursor:"pointer",backgroundColor:"#fff"}} 
                  src={elements[3].url||imageDoNotExist} 
                  alt="element-3" />
              </Col>
            </Row>
            <Row>
              <Col xs="4" md="4" style={{marginTop:20}}>
                <img 
                  onClick={()=>executeSlider(elements[4])} 
                  style={{width:"100%",height:"auto",cursor:"pointer",backgroundColor:"#fff"}} 
                  src={elements[4].url||imageDoNotExist} 
                  alt="element-4" />
              </Col>
              <Col xs="4" md="4" style={{marginTop:20}}>
                <img 
                  onClick={()=>executeSlider(elements[5])} 
                  style={{width:"100%",height:"auto",cursor:"pointer",backgroundColor:"#fff"}} 
                  src={elements[5].url||imageDoNotExist} 
                  alt="element-5" />
              </Col>
              <Col xs="4" md="4" style={{marginTop:20}}>
                <img 
                  onClick={()=>executeSlider(elements[6])} 
                  style={{width:"100%",height:"auto",cursor:"pointer",backgroundColor:"#fff"}} 
                  src={elements[6].url||imageDoNotExist} 
                  alt="element-6" />
              </Col>
            </Row>
          </Col>
          <Col xs="12">
            <div className="d-flex justify-content-center">
              <div className="home-footer p-5">
                <Row>
                  <Col xs="12" sm="5">
                    <img src={process.env.PUBLIC_URL + '/img/logo_negativo_blanco.png'} style={{width:"100%",height:"auto"}} alt="logo"/>
                    <p style={{fontSize:23,color:"#fff",marginTop:20}}>
                      <span style={{fontWeight:"bold",fontSize:25}}>¿DUDAS?</span> COMUNICATE CON NOSOTROS
                    </p>
                    <div className="d-flex justify-content-start">
                      <a href="tel:8001712712">
                        <span style={{fontSize:45,color:"#fff"}}>800 1712 712</span>
                      </a>
                    </div>
                  </Col>
                  <Col xs="12" sm="7">
                    <Row>
                      <Col xs="12" sm="4" style={{color:"#fff",textAlign:"right"}}>
                        <span>NOSOTROS</span><br/><br/>
                        <span onClick={()=>history.push("/weare")} style={{cursor:"pointer"}}>Conocenos</span><br/>
                        <span onClick={!user?()=>history.push("/register"):null} style={{cursor:!user?"pointer":"no-drop"}}>Unete</span><br/>
                        <span>Distribuidores</span><br/>
                        <span>Oficinas</span>
                      </Col>
                      <Col xs="12" sm="4" style={{color:"#fff",textAlign:"right"}}>
                        <span>POLÍTICAS</span><br/><br/>
                        <a style={{color:"#fff",fontWeight:"bold",cursor:"pointer"}} href={`${Const.backend}getPolicies?file=privacyPolicies.pdf`} target="_blank">
                          <span>Privacidad</span>
                        </a><br/>
                        <a style={{color:"#fff",fontWeight:"bold",cursor:"pointer"}} href={`${Const.backend}getPolicies?file=returnPolicies.pdf`} target="_blank">
                          <span>Devoluciones</span>
                        </a><br/>
                        <a style={{color:"#fff",fontWeight:"bold",cursor:"pointer"}} href={`${Const.backend}getPolicies?file=shippingPolicies.pdf`} target="_blank">
                          <span>Envíos</span>
                        </a><br/>
                        <a style={{color:"#fff",fontWeight:"bold",cursor:"pointer"}} href={`${Const.backend}getPolicies?file=paymentPolicies.pdf`} target="_blank">
                          <span>Formas de Pago</span>
                        </a>
                      </Col>
                      <Col xs="12" sm="4" style={{color:"#fff",textAlign:"right"}}>
                        <span>REDES</span><br/><br/>
                        <a style={{color:"#fff",fontWeight:"bold",cursor:"pointer"}} href={redes.url_facebook} target="_blank">
                          <span>Facebook</span>
                        </a><br/>
                        <a style={{color:"#fff",fontWeight:"bold",cursor:"pointer"}} href={redes.url_twitter} target="_blank">
                          <span>Twitter</span>
                        </a><br/>
                        <a style={{color:"#fff",fontWeight:"bold",cursor:"pointer"}} href={redes.url_instagram} target="_blank">
                          <span>Instagram</span>
                        </a><br/>
                        <a style={{color:"#fff",fontWeight:"bold",cursor:"pointer"}} href={redes.url_youtube} target="_blank">
                          <span>Youtube</span>
                        </a><br/>
                      </Col>
                    </Row>
                  </Col>
                  {
                    /*
                  <Col xs="12">
                    <div className="d-flex justify-content-end">
                      <a href="https://auwi.mx/">
                        <span style={{fontSize:20,color:"#fff"}}>Administrado en auwi</span>
                      </a>
                    </div>
                  </Col>
                    */
                  }
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        </FadeIn>
      </Container>
  )
}

{
  /*
  <Col xs="12" className="mt-2">
  <Row>
    <Col xs="12" sm="4">
      <div className="d-flex justify-content-start">
        <div>
          <p style={{fontSize:25,color:"#fff",fontWeight:"bold"}}>
            ¿YA ERES CLIENTE?
          </p>
          <p style={{color:"#fff"}}>
            Suscribete a nuestro Newsletter
          </p>
        </div>
      </div>
    </Col>
    <Col xs="12" sm="8">
      <Row>
        <Col xs="12" sm="8">
          <Input value={email} onChange={setEmail} placeholder="ESCRIBE TU EMAIL AQUÍ" />
        </Col>
        <Col xs="12" sm="4">
          <button className="btn btn-light profesionalText" style={{width:"100%",marginTop:13}} disabled={true} onClick={()=>alert("Servicio temporalmente no disponible")}>Enviar</button>
        </Col>
      </Row>
    </Col>
  </Row>
</Col>
  */
}
/*
                <img onClick={()=>history.push({
                  pathname: "/products",
                  search: '?id='+233
                })} style={{width:"100%",cursor:"pointer"}} src={process.env.PUBLIC_URL + '/img/img/primera.png'} alt="testingImage" />


        <Row className="mt-4">
          <Col sm="8">
            <ImageComponent execute={execute} />
          </Col>
          <Col sm="4">
            <ImageComponent execute={execute}/>
            <ImageComponent execute={execute} containerStyle={{marginTop:20}}/>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm="3">
            <ImageComponent execute={execute} />
          </Col>
          <Col sm="3">
            <ImageComponent execute={execute}/>
          </Col>
          <Col sm="3">
            <ImageComponent execute={execute}/>
          </Col>
          <Col sm="3">
            <ImageComponent execute={execute}/>
          </Col>
          <Col sm="3">
            <ImageComponent execute={execute}/>
          </Col>
          <Col sm="3">
            <ImageComponent execute={execute}/>
          </Col>
          <Col sm="3">
            <ImageComponent execute={execute}/>
          </Col>
          <Col sm="3">
            <ImageComponent execute={execute}/>
          </Col>
        </Row>
*/