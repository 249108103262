import React, { Fragment,useEffect } from "react";
import {useWebPayPlus} from "./Context";
import Iframe from 'react-iframe'

const Content = (props)=>{
    const {url} = useWebPayPlus();

    return(
        <Fragment>
            {
                url?
                <Iframe 
                    url={url}
                    position="relative"
                    width="100%"
                    height={650}
                    style={{borderStyle:null}}
                    frameBorder={0}
                /> : null
            }
            <IntervalComponent seconds={5} />
        </Fragment>
    )
}
const IntervalComponent = ({seconds})=>{
    const {checkPaymentStatus} = useWebPayPlus();
    useEffect(() => {
        const interval = setInterval(() => {
            checkPaymentStatus()
        }, seconds*1000);
        return () => clearInterval(interval);
    }, []);
    return "";
}
export default Content;