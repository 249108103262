import React, {Fragment,useState} from "react";
import { isMobile } from "../../../../Utils/Const";
import {Row,Col} from "react-bootstrap";
import { Card } from "react-bootstrap";
import "./styles.css";
const InputSearch = (props)=>{
    const {options,onSetValue,placeholder} = props;
    const [value,setValue] = useState("");
    const [showClass,setShowClass] = useState("hide-container");
    const showClassFunc = ()=>setShowClass("show-container");
    const hideClassFunc = ()=>setShowClass("hide-container");
    const isMob = isMobile();

    const filterItems = ()=>{
        if(!value){
            return options;
        }
        let newOptions = [];
        options.forEach(el => {
            let name_item = el.label.toLowerCase();
            let searching = value.toLowerCase();
            let filteredMetawords = el.metaWords.find((item)=>item.toLowerCase().indexOf(searching)!==-1);
            if(filteredMetawords&&filteredMetawords.length>0){
                newOptions.push(el);
            }else{
                if(name_item.indexOf(searching)!==-1){
                    newOptions.push(el);
                }
            }
        });
        return newOptions;
    }
    const filterMetaWords = (el)=>{
        if(!value){
            return el.metaWords;
        }
        let newMetaWords = [];
        el.metaWords.forEach(item => {
            let name_item = item.toLowerCase();
            let searching = value.toLowerCase();
            if(name_item.indexOf(searching)!==-1){
                newMetaWords.push(item);
            }
        });
        return newMetaWords;
    }
    const getStringFinder = ()=>{
        if(value){
            let n_products = filterItems().length;
            if(n_products>0){
                return `${n_products} productos encontrados con: "${value}"`;
            }else{
                return `No se encontraron coincidencias con: "${value}"`;
            }
        }else{
            return `Buscador de productos Profesional-712`;
        }
    }
    return(
        <Fragment>
            <input 
                type="text"
                className="search-container"
                value={value}
                onChange={(e)=>setValue(e.target.value)}
                placeholder={placeholder||"Buscar"}
            />
            <div className="d-flex justify-content-center">
                <span style={{color:"#fff"}}>
                    {
                        getStringFinder()
                    }
                </span>
            </div>
            <Row>
                <Col xs="12" style={{padding:20}}>
                    <Row>
                        {
                            value ?
                                filterItems().map((prod,prodIndex)=>(
                                    <ProductCard key={prodIndex} onSetValue={onSetValue} prod={prod} />
                                )) : null

                        }
                    </Row>
                </Col>
            </Row>
            
        </Fragment>
    )
}
const ProductCard = ({prod,onSetValue})=>{
    const [urlImage,setUrlImage] = useState(null);
    const urlImgWaiting = `${process.env.PUBLIC_URL}/img/img/ProfWaitImgQuad.png`;
    return(
        <Col onClick={()=>onSetValue(prod)} xd="12" md="6" xl="4" style={{marginTop:15,cursor:"pointer"}}>
            <Card style={{ width: "100%" }}>
                    <Card.Img variant="top" src={urlImage||urlImgWaiting} onLoad={()=>setUrlImage(prod.image)} />
                <Card.Body>
                    <Card.Title style={{fontSize:15,textAlign:"center"}}>{prod.label}</Card.Title>
                </Card.Body>
            </Card>
        </Col>
    )
}
/*
<div className={`${isMob?"search-container-elements-mobile":"search-container-elements"} ${showClass}`}>
    {
        filterItems().map((prod,prodIndex)=>(
            <div key={prodIndex} onClick={()=>console.log({prod})} className="product-container">
                <div className="product-image-container">
                    <img onClick={()=>console.log({prod})} className={isMob?"product-image-mobile":"product-image"} src={prod.image||"https://www.aguayodevs.com/images/profesional712/noImage1-1.jpg"} alt="testingImage" />
                </div>
                <div className="product-tagger-container">
                    <span className={isMob?"product-label-mobile":"product-label"}>
                        {prod.label}
                    </span><br></br>
                    <span className={isMob?"product-label-code-mobile":"product-label-code"}>
                        {prod.code}
                    </span><br></br>
                    <div className="metawords-container">
                        {
                            !isMob?
                            filterMetaWords(prod).map((metaWord)=>(
                                <div className="metawords-div">
                                    <span className={isMob?"meta-word-mobile":"meta-word"}>
                                        {metaWord}
                                    </span>
                                </div>
                            )) : null
                        }
                    </div>
                </div>
            </div>
        ))
    }
</div>
*/
export default InputSearch;