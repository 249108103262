import React from 'react';
import Axios from 'axios';
import { Const } from '../../../../Utils/Const';
import { useCart } from '../../../../Context/ContextCart';

const FastBuy = React.createContext();
export const FastBuyProvider = (props)=>{
    const {history,toast} = props;
    const [products,setProducts] = React.useState(null);
    const {addMultipleItems} = useCart();

    const [val1,setVal1] = React.useState("");
    const [val2,setVal2] = React.useState("");
    const [val3,setVal3] = React.useState("");
    const [val4,setVal4] = React.useState("");
    const [val5,setVal5] = React.useState("");
    const [val6,setVal6] = React.useState("");
    const [val7,setVal7] = React.useState("");
    const [val8,setVal8] = React.useState("");
    const [val9,setVal9] = React.useState("");
    const [val10,setVal10] = React.useState("");

    const [can1,setCan1] = React.useState("");
    const [can2,setCan2] = React.useState("");
    const [can3,setCan3] = React.useState("");
    const [can4,setCan4] = React.useState("");
    const [can5,setCan5] = React.useState("");
    const [can6,setCan6] = React.useState("");
    const [can7,setCan7] = React.useState("");
    const [can8,setCan8] = React.useState("");
    const [can9,setCan9] = React.useState("");
    const [can10,setCan10] = React.useState("");

    const [error,setError] = React.useState(null);
    const [load,setLoad] = React.useState(false);
    const handleLoad = (arg)=>setLoad(arg);
    const [loadCart,setLoadCart] = React.useState(false);
    const handleLoadCart = (arg)=>setLoadCart(arg);
    
    const searchProducts = async()=>{
        const postData = {
            type:"getProductsForFastBuy",
            token:localStorage.conection||null
        }
        handleLoad(true);
        let r = await Axios.post(Const.backend,postData).catch(e=>{
            console.log(e);
            alert("Servidor no disponible.");
        })
        if(r){
            if(r.data.error){
                setError(r.data.error);
            }else{
                setProducts(r.data.products);
            }
        }
        handleLoad(false);
    }
    const getProductsForCart = async(prods)=>{
        const postData = {
            type:"getProductsForCart",
            products:prods
        }
        handleLoadCart(true);
        let r = await Axios.post(Const.backend,postData).catch(e=>{
            toast.error("Servidor no disponible.");
        })
        if(r){
            if(r.data.error){
                toast.error(r.data.error);
            }else{
                addMultipleItems(r.data.prods)
                toast.success("Producto agregado al carrito correctamente.")
            }
        }
        handleLoadCart(false);
    }
    const checkCode = (val)=>{
        if(!val){
            return "Por favor especifique el código del producto."
        }
        if(val.length!==9){
            return "Formato de código de producto inválido."
        }
        return null;
    }
    const checkProductError = (val)=>{
        if(!checkCode(val)){
            let search = val.toUpperCase();
            let found = 0;
            for (let i = 0; i < products.length; i++) {
                const prod = products[i];
                if(found===0){
                    if(prod.P_Codigo.toUpperCase()===search){
                        found++
                    }
                }else{
                    break;
                }
            }
            if(found===0){
                return "Producto no encontrado."
            }else{
                return null
            }
        }else{
            return "Complete el código del producto para autocompletar este valor.";
        }
    }
    const getProductName = (val)=>{
        if(!checkCode(val)){
            let search = val.toUpperCase();
            let selected = "";
            for (let i = 0; i < products.length; i++) {
                const prod = products[i];
                if(!selected){
                    if(prod.P_Codigo.toUpperCase()===search){
                        selected = prod.P_Nombre;
                    }
                }else{
                    break;
                }
            }
            return selected;
        }
        return "";
    }
    const getProductIndex = (val)=>{
        if(!checkCode(val)){
            let search = val.toUpperCase();
            let indice = null;
            for (let i = 0; i < products.length; i++) {
                const prod = products[i];
                if(!indice){
                    if(prod.P_Codigo.toUpperCase()===search){
                        indice = i;
                    }
                }else{
                    break;
                }
            }
            return indice;
        }
        return "";
    }
    const checkCant = (can)=>{
        if(!can){
            return "Especifique la cantidad.";
        }
        if(isNaN(can)){
            return "Formato inválido."
        }
        return null;
    }
    const addToCart = ()=>{
        let prods = [];
        if(getProductName(val1)){
            let indice = getProductIndex(val1);
            if(indice!==null){
                prods.push({
                    can_prod:can1,
                    ...products[indice]
                })
            }else{
                toast.error("Error no se encontro el elemento")
            }
        }
        getProductsForCart(prods)
    }
    const checkFormErrors = ()=>{
        if(getProductName(val1)){
            return false
        }
        return true;
    }
    React.useEffect(()=>{
        if(!products){
            searchProducts();
        }
    },[])
    const value = React.useMemo(()=>{
        return({
            history,
            products,
            error,
            load,
            loadCart,
            val1,setVal1,can1,setCan1,
            val2,setVal2,can2,setCan2,
            val3,setVal3,can3,setCan3,
            val4,setVal4,can4,setCan4,
            val5,setVal5,can5,setCan5,
            val6,setVal6,can6,setCan6,
            val7,setVal7,can7,setCan7,
            val8,setVal8,can8,setCan8,
            val9,setVal9,can9,setCan9,
            val10,setVal10,can10,setCan10,
            checkCant,
            getProductName,
            checkProductError,
            checkCode,
            checkFormErrors,
            addToCart,
        })
    },[products,error,load,loadCart,val1,val2,val3,val4,val5,val6,val7,val8,val9,val10,can1,can2,can3,can4,can4,can5,can6,can7,can8,can9,can10])
    return <FastBuy.Provider value={value} {...props}/>
}
export const useFastBuy = ()=> React.useContext(FastBuy);