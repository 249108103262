import React,{Fragment} from "react";
import { useCatalogo } from "./store";
import Input from "../All/InputV2";
import { Row,Col } from "react-bootstrap";
import Iframe from 'react-iframe'

const Content = ()=>{
    return(
        <Fragment>
            <Interactivo />
            <Fisico />
        </Fragment>
    )
}

const Interactivo = ()=>{
    const {history} = useCatalogo();
    return(
        <Fragment>
            <span onClick={()=>history.push("/interactive-catalog")} style={{fontSize:20,fontWeight:"bold",color:"#fff",cursor:"pointer",marginTop:20}}>
                CATÁLOGO INTERACTIVO
            </span>
        </Fragment>
    )
}
const Fisico = ()=>{
    return(
        <Fragment>
            <Form />
        </Fragment>
    )
}

const Form = ()=>{
    const {form} = useCatalogo();
    const {
        fullName,
        email,
        telefono,
        cp,
        pais,
        estado,
        ciudad,
        calle,
        colonia,
        numeroInt,
        numeroExt
    } = form;

    const checkErrors = ()=>{
        if(!fullName.check()){
            if(!email.check()){
                if(!telefono.check()){
                    if(!cp.check()){
                        if(!pais.check()){
                            if(!estado.check()){
                                if(!ciudad.check()){
                                    if(!calle.check()){
                                        if(!colonia.check()){
                                            if(!numeroInt.check()){
                                                if(!numeroExt.check()){
                                                    return false;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return true;
    }
    return(
        <div className="mt-4">
            <span style={{fontSize:20,fontWeight:"bold",color:"#fff",marginTop:20}}>
                SOLICITA CATÁLOGO FÍSICO
            </span>
            <Row className="mt-3">
                <Col className="mt-2" xs="12">
                    <Input 
                        value={fullName.value}
                        setValue={fullName.setValue}
                        error={fullName.check()}
                        label="Nombre(s) Apellido(s)"
                        useLabelWhite
                    />
                </Col>
                <Col className="mt-3" xs="12">
                    <Input 
                        value={email.value}
                        setValue={email.setValue}
                        error={email.check()}
                        label="Correo Electrónico"
                        useLabelWhite
                    />
                </Col>
                <Col className="mt-3" xs="12">
                    <Input 
                        value={telefono.value}
                        setValue={telefono.setValue}
                        error={telefono.check()}
                        label="Teléfono"
                        useLabelWhite
                    />
                </Col>
                <Col className="mt-3" xs="12">
                    <Input 
                        value={cp.value}
                        setValue={cp.setValue}
                        error={cp.check()}
                        label="Teléfono"
                        useLabelWhite
                    />
                </Col>
                <Col className="mt-3" xs="12">
                    <Input 
                        value={pais.value}
                        setValue={pais.setValue}
                        error={pais.check()}
                        label="Pais"
                        useLabelWhite
                        disabled
                    />
                </Col>
                <Col className="mt-3" xs="12" md="6">
                    <Input 
                        value={estado.value}
                        setValue={estado.setValue}
                        error={estado.check()}
                        label="Estado"
                        useLabelWhite
                        disabled
                    />
                </Col>
                <Col className="mt-3" xs="12" md="6">
                    <Input 
                        value={ciudad.value}
                        setValue={ciudad.setValue}
                        error={ciudad.check()}
                        label="Municipio / Delegación"
                        useLabelWhite
                        disabled
                    />
                </Col>
                <Col className="mt-3" xs="12" md="6">
                    <Input 
                        value={calle.value}
                        setValue={calle.setValue}
                        error={calle.check()}
                        label="Calle"
                        useLabelWhite
                    />
                </Col>
                <Col className="mt-3" xs="12" md="6">
                    <Input 
                        value={colonia.value}
                        setValue={colonia.setValue}
                        error={colonia.check()}
                        label="Colonia"
                        useLabelWhite
                    />
                </Col>
                <Col className="mt-3" xs="12" md="6">
                    <Input 
                        value={numeroInt.value}
                        setValue={numeroInt.setValue}
                        error={numeroInt.check()}
                        label="Número Interior"
                        useLabelWhite
                    />
                </Col>
                <Col className="mt-3" xs="12" md="6">
                    <Input 
                        value={numeroExt.value}
                        setValue={numeroExt.setValue}
                        error={numeroExt.check()}
                        label="Número Exterior"
                        useLabelWhite
                    />
                </Col>
                <Col className="mt-3" xs="12" md="6">
                    <div className="d-flex justify-content-center mt-2">
                        <span style={{fontSize:12,color:"#fff",textAlign:"center"}}>
                            Su información siempre será tratada de acuerdo con las leyes, las directivas, los reglamentos y los principios aplicables a la protección de datos personales.
                        </span>
                    </div>
                </Col>
                <Col className="mt-3" xs="12" md="6">
                    <div className="d-flex justify-content-center">
                        <button onClick={()=>alert("Funcionalidad en desarrollo")} disabled={checkErrors()} className="btn btn-dark mt-2" style={{backgroundColor:"#686868",width:200}}>
                            Solicitar
                        </button>
                    </div>
                </Col>

            </Row>
            
        </div>
    )
}

const LoginComponent = (props)=>{
    const {history} = useCatalogo();
    return(
        <Fragment>
            <div className="d-flex justify-content-center">
                <button className="btn btn-dark mt-2" style={{backgroundColor:"#686868",width:200}} onClick={()=>history.push("/login")}>Iniciar Sesión</button>
            </div>
            <div className="d-flex justify-content-center mt-2">
                <button className="btn btn-dark mt-2" style={{backgroundColor:"#686868",width:200}} onClick={()=>history.push("/register")}>Registrarme</button>
            </div>
        </Fragment>
    )
}
export default Content;