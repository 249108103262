import React,{useState,useEffect,Fragment} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "./App.css";
import Routes from "./Routes";
import {idioms} from "./Utils/Idioms";
import axios from "axios";
import {Const} from "./Utils/Const.js";
import ButtonChat from "./Components/principal/ButtonChat";
import TimerOut from "./Components/principal/TimerOut";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CartProvider,useCart} from "./Context/ContextCart";
import Loader from "./Components/principal/All/Loader";
import { WebRoadProvider } from './Components/principal/WebRoad';

function App() {
  const [idioma,setIdioma] = useState(1);
  const [locate,setLocate] = useState("MX")
  const [user,setUser] = useState(null);
  const [reloadData,setReloadData] = useState(true);
  const [categories,setCategories] = useState([])
  function searchIdiom(locate){
    for (var index = 0; index < idioms.length; index++) {
      for (var i = 0; i < idioms[index].countries.length; i++) {
        const item = idioms[index].countries[i];
        if(item===locate){
          return index;
        }
      }
    }
    return 1;
  }
  useEffect(() => {
    (async()=>{
      if(reloadData){
        let error = null;
        const response = await axios.post(Const.backend,{type:"data",conection:(localStorage.conection)?localStorage.conection:null}).catch((e)=>error=e);
        if(error){
          console.log(error)
        }else{
          setIdioma(searchIdiom(response.data.country));
          setLocate(response.data.country)
          setCategories(response.data.categories);
          if(!response.data.expired){
            setUser(response.data.user);
          }else{
            localStorage.clear()
            setUser(null);
            toast.error("La sesión ha caducado.");
          }
        }
        setReloadData(false);
      }
    })();
  }, [reloadData]);
  return (
    <Fragment>
      {
        reloadData ? <Loader isVisible={true} /> : 
        <Fragment>
          {
            (user&&user.verified===0) ? (
              <div className="watchVerify">
                <TimerOut maxTimeMin={30} msg={"Verificar correo electrónico"} tooltip={"Click aquí para volver a enviar el correo electrónico"} register={user.register} id={localStorage.conection} toast={toast} usr={{user:user,setUser:setUser}} />
              </div>
            ) : null
          }
          <WebRoadProvider>
            <Routes 
              setReloadData={setReloadData} 
              user={user} 
              setUser={setUser} 
              toast={toast} 
              idioms={idioms} 
              idioma={idioma} 
              categories={categories}
              locate={locate}
            />
          </WebRoadProvider>
          {/*<ButtonChat user={user} tempUser={tempUser} setTempUser={setTempUser} idioms={idioms} idioma={idioma} /> */}
          
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            rtl={false}
            pauseOnVisibilityChange
            pauseOnHover
            />
        </Fragment>
      }
    </Fragment>
  );
}

export default ()=><CartProvider>
  <App></App>
</CartProvider>
