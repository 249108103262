import React, { Fragment } from "react";
import { Row,Col } from "react-bootstrap";
import { CepGrabContextProvider, useCepGrabContext } from "./Store";
import InputCepGrab from "./InputCepGrab";

const CepilloGrabado = ({caracteres,setNewValue})=>{
    return(
        <CepGrabContextProvider caracteres={caracteres} setNewValue={setNewValue}>
            <Content />
        </CepGrabContextProvider>
    )
}
const Content = ()=>{
    return (
        <Fragment>
            <Row className="mt-4">
                <Col xs="12">
                    <div className="d-flex justify-content-end">
                        <span style={{color:"#fff",fontWeight:"bold",fontSize:12}}>
                            ** ESTE PRODUCTO REQUIERE PARÁMETROS ADICIONALES **
                        </span>
                    </div>
                </Col>
                <Col xs="12" md="2">
                    <div style={{padding:5}}>
                        <span style={{color:"#fff"}}>
                            Linea 1
                        </span>
                    </div>
                </Col>
                <Col xs="12" md="10">
                    <Linea1 />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col xs="12" md="2">
                    <div style={{padding:5}}>
                        <span style={{color:"#fff"}}>
                            Linea 2
                        </span>
                    </div>
                </Col>
                <Col xs="12" md="10">
                    <Linea2 />
                </Col>
            </Row>
        </Fragment>
    )
}
const Linea1 = ()=>{
    const {caracteres} = useCepGrabContext();
    return(
        <Fragment>
            <div style={{display:"flex",flexDirection:"row"}}>
                {
                    caracteres.filter((el,i)=>i<27)
                        .map((caracter,indexCaracter)=>(
                            <InputCepGrab key={indexCaracter} caracter={caracter} indexCaracter={indexCaracter} />
                        ))
                }
            </div>
        </Fragment>
    )
}

const Linea2 = ()=>{
    const {caracteres} = useCepGrabContext();
    return(
        <Fragment>
            <div style={{display:"flex",flexDirection:"row"}}>
                {
                    caracteres.filter((el,i)=>i>=27)
                        .map((caracter,indexCaracter)=>(
                            <InputCepGrab key={indexCaracter} caracter={caracter} indexCaracter={indexCaracter+27} />
                        ))
                }
            </div>
        </Fragment>
    )
}

export default CepilloGrabado;