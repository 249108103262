import React from "react";
import { Row,Col,Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { FastBuyProvider, useFastBuy } from "./store";
import Loader from 'react-loader-spinner';
import ErrorContainer from "../../All/ErrorContainer";
import Content from "./Content";
const FastBuy = (props)=>{
    const {toast,history} = props;
    return(
        <div className={"backgroundProfesional"}>
            <Container>
                <Row>
                    <Col xs="12">
                        <FastBuyProvider history={history} toast={toast}>
                            <FastBuyTunnel />
                        </FastBuyProvider>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
const FastBuyTunnel = ()=>{
    const {history,error,load,products} = useFastBuy();
    if(load||(!products&&!error)){
        return(
            <div className="d-flex justify-content-center mt-5">
                <Loader
                    type="Oval"
                    color={"#fff"}
                    height={150}
                    width={150}
                    visible={true}
                />
            </div>
        )

    }
    if(error){
        return <ErrorContainer error={error} callback={{execute:()=>history.goBack(),label:"Regresar"}} />
    }
    return <Content />
}
export default withRouter(FastBuy);