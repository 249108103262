import React, { Fragment } from  "react";
import { useMyFavourite } from "./Store";
import {Row,Col,Container} from "react-bootstrap";
import ErrorContainer from "../All/ErrorContainer";

const Content = ()=>{
    return(
        <Container>
            <Row>
                <Validator />
            </Row>
        </Container>
    )
}
export default Content;

const Validator = ()=>{
    const {screenData,history} = useMyFavourite();
    const {products} = screenData;
    if(products.length>0){
        return <ProductMapper products={products} />
    }
    return <div className="p-2"><ErrorContainer error="Aún no tienes productos favoritos." callback={{label:"Regresar",execute:()=>history.goBack()}} /></div>
}

const ProductMapper = ({products})=>{
    return products.map((product,indexProduct)=><ProductViewer product={product} key={indexProduct} />)
}

const ProductViewer = ({product})=>{
    const {history} = useMyFavourite();
    const execute = (id)=>{
        history.push({
            pathname: "/products/"+id
        });
    }
    return(
        <Fragment>
            <Col xs="12" md="4" align="center" onClick={()=>execute(product.id)} style={{cursor:"pointer",marginTop:15}}>
                <img  style={{width:"100%",cursor:"pointer"}} src={product.url} alt="Product-image" />
                <span className="profesionalText" style={{fontSize:15,color:"#fff",fontWeight:"bold",textAlign:"center"}}>{product.label}</span>
            </Col>
        </Fragment>
    )
}