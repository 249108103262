import React, {Fragment} from "react";
import Selector from "../../All/Select";
import { useCartScreen } from "../Context";

const PurchaseComponent = (props)=>{
  const {
    cartScreenData,selectedOcurre,setSelectedOcurre,selectedSendType,
    selectedUbi,setSelectedUbi,history
  } = useCartScreen();
  const {ocurre,ubications} = cartScreenData;


  return(
    <Fragment>
      {
        selectedSendType&&selectedSendType.value === 3 ?
        <Fragment>
          <Selector
              val={selectedOcurre}
              setVal={setSelectedOcurre}
              items={ocurre}
              label="Dirección de recolección"
              placeholder="Seleccione una ubicación de recolección."
          />
        </Fragment> : 
        ubications&&ubications.length>0 ?
        <Fragment>
          <Selector
              val={selectedUbi}
              setVal={setSelectedUbi}
              items={ubications}
              label="Dirección de entrega"
              placeholder="Seleccione una ubicación de entrega."
          />
        </Fragment>
        :
        <button className="btn btn-dark btn-block mt-2" style={{backgroundColor:"#686868"}} onClick={()=>history.push("/ubications")}>Mis ubicaciones</button>
      }
    </Fragment>
  )
}
export default PurchaseComponent;
