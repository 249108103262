import React from "react";
import "./styles.css";
const Button = (props)=>{
    const {style,type,disabled,execute,load} = props;
    
    const btnStyle = {
        ...style
    }
    const noFunc = ()=>alert("No se ha definido función para este boton")
    return(
            <button onClick={execute||noFunc} className={`btn-profesional prof-${type} ${disabled?"btn-profesional-disabled":""}`} style={btnStyle} disabled={disabled||load||false}>
                {!load?props.children:"Espere.."}
            </button>
    )
}
export default Button;