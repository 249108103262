import React from "react";
import "./style.css";
import Loader from 'react-loader-spinner';

export default function Load(props){
  const {isVisible,color} = props;
  if(isVisible){
    return (
      <div className="alineado" style={{position:"fixed",top:0,left:0,width:"100vw",height:"100vh",backgroundColor:"rgba(56, 56, 56, 0.5)",zIndex:999}}>
        <Loader
           type="Oval"
           color={color||"#1a1a1a"}
           height={150}
           width={150}
           visible={true}
        />
      </div>
    )
  }else{
    return(<div></div>);
  }
}
