import React,{useMemo,useContext,createContext,useEffect,useState} from "react";
import Axios from "axios";
import { Const } from "../../../Utils/Const";
import md5 from "md5";

const ProfileContext = createContext();

export const ProfileProvider = (props)=>{
    const {user,history,consult} = props;
    const [fullName,setFullName] = useState("");
    const [iniFullName,setIniFullName] = useState("");
    const [email,setEmail] = useState("");
    const [iniEmail,setIniEmail] = useState("");
    const [tel,setTel] = useState("");
    const [iniTel,setIniTel] = useState("");
    const [psw,setPsw] = useState("");
    const [psw2,setPsw2] = useState("");
    const [error,setError] = useState(null);
    const [isLoading,setIsLoading] = useState(false);
    const handleLoad = (param)=>setIsLoading(param);


    const searchData = async()=>{
        const postData = {
            type:consult.type||"",
            ...consult.postData,
            token:localStorage.conection
        }
        handleLoad(true);
        let r = await Axios.post(Const.backend,postData).catch(e=>{
            console.log(e);
            alert("Servidor no disponible.");
        })
        if(r){
            if(r.data.error){
                setError(r.data.error);
            }else{
                setFullName(r.data.result.userInfo.fullName);
                setIniFullName(r.data.result.userInfo.fullName);
                setEmail(r.data.result.userInfo.email);
                setIniEmail(r.data.result.userInfo.email);
                setTel(r.data.result.userInfo.tel);
                setIniTel(r.data.result.userInfo.tel);
            }
        }
        handleLoad(false);
    }
    const saveNewPsw = async()=>{
        let postData = {
            type:"updateUserPsw",
            value:md5(psw2),
            token:localStorage.conection
        }
        let r = await Axios.post(Const.backend,postData).catch(e=>{
            alert("Servidor no disponible")
        })
        if(r){
            if(r.data.error){
                alert(r.data.error);
            }else{
                setPsw("")
                setPsw2("")
                alert("Contraseña actualizada exitosamente.");
            }
        }
    }
    const saveProfileChanges = async()=>{
        let changes = getChanges();
        if(changes.length>0){
            let postData = {
                type:"saveProfileChanges",
                changes,
                token:localStorage.conection
            }
            let r = await Axios.post(Const.backend,postData).catch(e=>{
                alert("Servidor no disponible.")
            })
            if(r){
                if(r.data.error){
                    alert(r.data.error);
                }else{
                    searchData();
                }
            }
        }else{
            alert("Los valores no han cambiado.")
        }
    }
    const getChanges = ()=>{
        let changes = [];
        if(fullName!==iniFullName){
            changes.push({
                type:"Nombre_Comercial",
                value:fullName
            })
        }
        if(email!==iniEmail){
            changes.push({
                type:"Correo",
                value:email
            })
        }
        if(tel!==iniTel){
            changes.push({
                type:"Telefono",
                value:tel
            })
        }
        return changes;
    }
    useEffect(()=>{
        if(consult){
            searchData();
        }
    },[consult])
    const value = useMemo(()=>{
        return({
          user,
          history,
          isLoading,
          error,
          fullName,setFullName,iniFullName,
          email,setEmail,
          tel,setTel,
          saveProfileChanges,
          psw,setPsw,
          psw2,setPsw2,
          saveNewPsw
        })
    },[
        user,
        isLoading,
        error,
        fullName,iniFullName,
        email,
        tel,
        psw,
        psw2
        ])
    return <ProfileContext.Provider value={value} {...props}/>
}

export const useProfile = ()=>useContext(ProfileContext);