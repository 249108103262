import React, {Fragment} from "react";
import {Row,Col} from "react-bootstrap";
import { FaHands,FaTruck } from "react-icons/fa";
import { MdError } from "react-icons/md";
import { useModalLocation } from "./Store";

export const InProcess = ()=>{

    return(
        <Fragment>
            <Row>
                <Col xs="12">
                    <div className="d-flex justify-content-center mt-3">
                        <FaHands style={{color:"#a1a1a1",fontSize:50}} />
                    </div>
                    <div className="d-flex justify-content-center mt-1">
                        <span style={{fontSize:15,fontWeight:"bold"}}>
                            Estamos procesando tu pedido.
                        </span>
                    </div>
                    <div className="d-flex justify-content-center mt-1">
                        <span style={{fontSize:20,color:"#000",textAlign:"center"}}>
                            Tu pedido está siendo procesado, por favor se paciente. en los proximos días te avisaremos cuando vaya en camino a tu ubicación
                        </span>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
}

export const Location = ()=>{
    const {location} = useModalLocation();
    
    const getStatus = ()=>{
        if(location){
            switch (location.statusSPA._text) {
                case "EN_TRANSITO":
                    return "Su pedido está de camino a su ubicación."
                    break;
            
                default:
                    return "Desconocimiento de status"
                    break;
            }
        }else{
            return "Error status."
        }
    }
    const getDescription = ()=>{
        if(location){
            switch (location.statusSPA._text) {
                case "EN_TRANSITO":
                    const {originName,pickupDateTime} = location.pickupData;
                    return `Tu pedido llegó a "${originName._text}" a las "${pickupDateTime._text.split(" ")[1]}" el día "${pickupDateTime._text.split(" ")[0]}"`;
            
                default:
                    return "Desconocimiento de descripción"
            }
        }else{
            return "Error Description."
        } 
    }
    return(
        <Fragment>
             <Row>
                <Col xs="12">
                    <div className="d-flex justify-content-center mt-3">
                        <FaTruck style={{color:"#a1a1a1",fontSize:50}} />
                    </div>
                    <div className="d-flex justify-content-center mt-1">
                        <span onClick={()=>console.log(location)} style={{fontSize:15,fontWeight:"bold"}}>
                            {getStatus()}
                        </span>
                    </div>
                    <div className="d-flex justify-content-center mt-1">
                        <span style={{fontSize:20,color:"#000",textAlign:"center"}}>
                            {getDescription()}
                        </span>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
}

export const ErrorLocation = ()=>{
    const {error} = useModalLocation();
    return(
        <Fragment>
            <Row>
                <Col xs="12">
                    <div className="d-flex justify-content-center mt-3">
                        <MdError style={{color:"#a1a1a1",fontSize:50}} />
                    </div>
                    <div className="d-flex justify-content-center mt-1">
                        <span style={{fontSize:15,fontWeight:"bold"}}>
                            Parece que tenemos dificultades.
                        </span>
                    </div>
                    <div className="d-flex justify-content-center mt-1">
                        <span style={{fontSize:20,color:"#000",textAlign:"center"}}>
                           {error}
                        </span>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
}