import React, { Fragment,useState } from "react";
import {Row,Col, Container} from "react-bootstrap";
import { imageDoNotExist } from "../../../Utils/Const";
const MobileProductList = (props)=>{
    const {product,isWeb} = props;
    console.log("isWebEnMobileProductList",isWeb)
    if(isWeb!==false){
        return null
    }else{
        console.log("isWeb es igual a false",isWeb)
        return(
            <Fragment>
                <ElementProductList element={product} {...props}/>
                <MapperProductList {...props} />
            </Fragment>
        )
    }
}
const MapperProductList = (props)=>{
    const {relations} = props;
    const [relacionados,setRelacionados] = useState(relations||[]);
    const changeQuantity = (i,val) => {
        console.log("intentando ejecutar changeQuantity",{i,val})
        let newValues = relacionados.map((item,index)=>{
          if(index===i){
            if(!isNaN(val)){
                console.log("Debio ponerle el valor ")
              item.quantity = parseInt(val);
              return item;
            }else{
              return item;
            }
          }else{
            return item;
          }
        });
        setRelacionados(newValues.concat());
    }
    React.useEffect(()=>{
        if(relations!==relacionados){
            setRelacionados(relations)
        }
    },[relations])
    return(
        <Fragment>
            {
                relations.map((element,index)=><ElementProductList changeQuantity={changeQuantity} index={index} element={element} {...props}/>)
            }
        </Fragment>
    )
}
const ElementProductList = ({element,index,changeQuantity,quantity,onChange,addCart,history})=>{
    const getImgUrl = ()=>{
        let validation = element.images.length>0;
        if(validation){
            return element.images[0].url
        }else{
            return imageDoNotExist;
        }
    }
    const onChangeInput = (e)=>{
        if(isNaN(index)){
            console.log("Entro a onchange",index)
            onChange(e);
        }else{
            console.log("Entro a changeQuantity")
            changeQuantity(index,e.target.value)
        }
    }
    return(
        <div className="mobile-container-element">
            <Row>
                <Col xs="5">
                    <div className="mobile-image-container">
                        <img 
                            src={getImgUrl()}
                            className="mobile-image-list"
                            onClick={()=>history.push(`/products/${element.P_Id}`)}
                        />
                    </div>
                </Col>
                <Col xs="7">
                    <div className="mobile-text-container">
                        <span className="mobile-header-text">
                            {element.P_Codigo}
                        </span>
                        <span className="mobile-body-text">
                            {element.P_Nombre||element.label}
                        </span>
                        <span className="mobile-body-text">
                            Precio a Público: {element.costo}
                        </span>
                        <span className="mobile-body-text">
                            Precio a Profesional: {parseFloat(element.Precio_B-element.ivamb).toFixed(2)}
                        </span>
                        <span className="mobile-body-text">
                            Precio a Mayoreo: {element.Precio_C}
                        </span>
                    </div>
                    <input type="text" className="mobile-input-element" value={isNaN(index)?quantity:element.quantity} onChange={onChangeInput} />
                    <span onClick={()=>addCart(!isNaN(index)?element:null)} style={{color:"#fff",marginLeft:10,marginTop:5,fontSize:13,cursor:"pointer"}}>
                        Agregar
                    </span>
                </Col>
            </Row>

        </div>
    )
}
export default MobileProductList;