import React, {Fragment} from "react";
import { Col, Row } from "react-bootstrap";
import Button from "../All/Button";

const ContentModalSoldOut = ({handleClose,createNotification,loadBtn})=>{

    return(
        <Fragment>
            <div className="d-flex justify-content-center">

                <span style={{color:"#a1a1a1",fontSize:20,fontWeight:"bold",textAlign:"center"}}>
                    No contamos por el momento con la cantidad solicitada, ¿Deseas recibir una notificación cuando tengamos existencia de este producto? 
                </span>

            </div>
            <Row>
                <Col xs="12">
                    <div className="d-flex justify-content-center mt-1">
                        <div>
                            <Button type="info" execute={createNotification} load={loadBtn} style={{backgroundColor:"#171717",marginLeft:10}} >
                                Notificarme
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
}
export default ContentModalSoldOut;