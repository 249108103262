import React,{useState} from "react";
import {withRouter} from "react-router-dom";
import {isMobile,checkMail,Const} from "../../../Utils/Const";
import {Row,Col} from "react-bootstrap";
import InputProfesional from "../All/InputProfesional";
import axios from "axios";
import md5 from "md5";
import ModalPsw from "./ModalPsw";
import Input from "../All/InputV2";
import ModalProfesional from "../All/Modal";
const Login = (props)=>{
  const {idioms,idioma,toast,setReloadData,user,history} = props;
  const [email,setEmail] = useState("");
  const [psw,setPsw] = useState("");
  const [showModalPsw,setShowModalPsw] = useState(false);
  const handleShowModalPsw = ()=>setShowModalPsw(!showModalPsw);
  const sendLogin = async()=>{
    let error = null;
    let response = await axios.post(Const.backend,{type:"login",email:email,psw:md5(psw)}).catch((e)=>error=e);
    if (error) {
      console.log(error);
    }else{
      if(response.data.error){
        toast.error(response.data.error)
      }else{
        localStorage.setItem("conection",response.data.conection);
        toast.info("Bienvenido");
        setReloadData(true);
      }
    }
  }
  if(user){
    history.push("/");
  }
  return (
    <div className="backgroundProfesional">
      {
        showModalPsw ? 
        <ModalProfesional 
          title="Recuperación de contraseña"
          show={showModalPsw}
          handleClose={handleShowModalPsw}
          size="md"
          Content={()=><ModalPsw toast={toast} />}
        />
        : null
      }
        <div style={{width:"100%"}}>
          <div className="d-flex justify-content-center">
            <div className={(isMobile())?"divMarcoLoginMobile":"divMarcoLogin"}>
              <span style={{fontSize:25,color:"#fff",fontWeight:"bold"}}>{idioms[idioma].views.login.text.login}</span>
              <Input value={email} setValue={setEmail} placeholder={idioms[idioma].views.login.inputs.placeholderUser} error={checkMail(email)} />
              <Input type="password" value={psw} setValue={setPsw} placeholder={idioms[idioma].views.login.inputs.placeholderPsw} error={!psw?"Inserte su contraseña":null} />

              {/*}<input type="text" className="inputProfesional" placeholder={idioms[idioma].views.login.inputs.placeholderCodigo} style={{width:"100%"}} />{*/}
              <Row className="mt-4">
                <Col onClick={handleShowModalPsw} xs="8" className="text-center">
                  <span style={{color:"#999999",cursor:"pointer"}}>{idioms[idioma].views.login.text.forgot}</span>
                </Col>
                <Col xs="4" className="d-flex justify-content-end">
                  <button onClick={()=>sendLogin()} style={{backgroundColor:"#686868"}} className="btn btn-dark btn-block">{idioms[idioma].views.login.text.iniciar}</button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
  )
}

export default withRouter(Login);
