import axios from "axios";
import CryptoJS from "crypto-js";
function _arrayBufferToBase64( buffer ) {
  var binary = '';
  var bytes = new Uint8Array( buffer );
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] );
  }
  return window.btoa( binary );
}
function base64toHEX(str) {
  const raw = atob(str);
  let result = '';
  for (let i = 0; i < raw.length; i++) {
    const hex = raw.charCodeAt(i).toString(16);
    result += (hex.length === 2 ? hex : '0' + hex);
  }
  return result.toUpperCase();
}
const testingKeys = {
  id_company:"SNBX",
  id_branch:"01SNBXBRNCH",
  user:"SNBXUSR01",
  pwd:"SECRETO"
}
export const GENERATESTRING = ({products,total,prodKeys,production,reference})=>{
  let keys = !production || !prodKeys ? testingKeys : prodKeys;
  //console.log({production,keys})
  return `<?xml version="1.0" encoding="UTF-8"?>
  <P>
    <business>
      <id_company>${keys.id_company}</id_company>
      <id_branch>${keys.id_branch}</id_branch>
      <user>${keys.user}</user>
      <pwd>${keys.pwd}</pwd>
    </business>
    <url>
      <reference>${reference}</reference>
      <amount>${total.toFixed(2)}</amount>
      <moneda>MXN</moneda>
      <canal>W</canal>
      <omitir_notif_default>1</omitir_notif_default>
      <promociones>C</promociones>
      <st_correo>1</st_correo>
      <mail_cliente>mail@dominio.com</mail_cliente>
      <datos_adicionales>
        ${getProductData(products)}
      </datos_adicionales>
      <version>IntegraWPP</version>
    </url>
  </P>`;
}
export const ENCRYPTDATA = (data,key)=>{
  let buf = new Uint8Array(16);
	window.crypto.getRandomValues(buf); //Obtenermos 16 bytes
  const buffer_b64 = _arrayBufferToBase64(buf);
  const iv = CryptoJS.enc.Hex.parse(base64toHEX(buffer_b64));
  key = CryptoJS.enc.Hex.parse(key);
  const cipherParams = {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  };
  let encrypted = CryptoJS.AES.encrypt(data, key, cipherParams);
  let merge = encrypted.iv.clone();
	merge.concat(encrypted.ciphertext);
	merge = CryptoJS.enc.Base64.stringify(merge);
	return merge;
}
const hex2a = (hex)=>{
  let str = '';
  for (var i = 0; i < hex.length; i += 2) {
      str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  }
  return str;
}
export const descifradoAES = (data, key)=>{

  const rawData = atob(data);
  const iv = btoa(rawData.substring(0,16));
  const crypttext = btoa(rawData.substring(16));

  const plaintextArray = CryptoJS.AES.decrypt(
    {
      ciphertext: CryptoJS.enc.Base64.parse(crypttext),
      salt: ''
    },
    CryptoJS.enc.Hex.parse(key),
    { iv: CryptoJS.enc.Base64.parse(iv) }
  );


  console.log(hex2a(plaintextArray.toString()));
  return hex2a(plaintextArray.toString());
}
export const decifrarAES =(cadena_cifrada, key)=>{
  var key = CryptoJS.enc.Hex.parse(key);
  var first = CryptoJS.enc.Base64.parse(cadena_cifrada); //cadena_cifrada.clone();
  var second = CryptoJS.enc.Base64.parse(cadena_cifrada); //cadena_cifrada;
  first.words = first.words.slice(0, 4);
  second.words = second.words.slice(4, second.length);
  console.log("Cadena cifrada",cadena_cifrada);
  first.sigBytes = 16;
  second.sigBytes = second.sigBytes - 16;
  console.log("second",second);
  second = CryptoJS.enc.Base64.stringify(second);
  console.log("second",second);
  var cipherParams = {
    iv: first,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  }
  var decrypted = CryptoJS.AES.decrypt(second.toString(), key, cipherParams);
  decrypted = decrypted.toString(CryptoJS.enc.Utf8);
  console.log("decripted",decrypted);
  return decrypted;
}

export const GENERATEXML = (data,production)=>`<pgs><data0>${production?"9265655904":"SNDBX123"}</data0><data>${data}</data></pgs>`;

export const REQUEST = ({url,postData,config,handleLoad})=>{
    return new Promise((res,rej)=>{
      if(handleLoad){
        handleLoad(true)
      }
      console.log("Consultando:",url);//
      (async()=>{
        let r = await axios.post(url,postData,config).catch(e=>{
          console.log("entro al catch",e);
          console.log("PostData:",postData)
          res({error:e})
        })
        if(r){
          res(r.data)
        }
        if(handleLoad){
          setTimeout(()=>{
            handleLoad(false)
          },500);
        }
      })()
    })
}

const getProductData = (products)=>{
  let str = "";
  for (let i = 0; i < products.length; i++) {
    const el = products[i];
    console.log("Agregando:",el);
    str += `
        <data id="${el.id}" display="true">
          <label>${el.label}</label>
          <value>${el.quantity}</value>
        </data>
    `
  }
  return str;
}