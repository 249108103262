import React from 'react';
import {Const} from "../../../Utils/Const";
import axios from "axios";
const Catalogo = React.createContext();
export const CatalogoProvider = (props)=>{
    const {user,history} = props;
    const [data,setData] = React.useState(null);
    const [error,setError] = React.useState(null);
    const [load,setLoad] = React.useState(false);
    let url = Const.backend;

    //FORM
    const [fullName,setFullName] = React.useState("");
    const [email,setEmail] = React.useState("");
    const [telefono,setTelefono] = React.useState("");
    const [cp,setCp] = React.useState("");
    const [pais,setPais] = React.useState(null);
    const [estado,setEstado] = React.useState(null);
    const [ciudad,setCiudad] = React.useState(null);
    const [calle,setCalle] = React.useState("");
    const [colonia,setColonia] = React.useState("");
    const [numeroInt,setNumeroInt] = React.useState("");
    const [numeroExt,setNumeroExt] = React.useState("");

    const searchData = async()=>{
        let postData = {
            type:"getCatalogo"
        }
        let r = await axios.post(url,postData).catch(e=>{
            alert("Error al conectar al servidor.")
        })
        if(r&&r.data){
            if(r.data.error){
                setError(r.data.error);
            }else{
                setData(r.data.result);
            }
        }
    }

    React.useEffect(()=>{
        if(!data){
            searchData();
        }
    },[data])
    const value = React.useMemo(()=>{
        return({
            data,
            error,
            load,
            searchData,
            user,
            history,
            form:{
                fullName:{
                    value:fullName,
                    setValue:setFullName,
                    check:()=>{
                        if(!fullName){
                            return "El valor no puede estar vacio."
                        }
                        if(fullName.length>=8){
                            return null;
                        }else{
                            return "Nombre completo es demasiado corto."
                        }
                    }
                },
                email:{
                    value:email,
                    setValue:setEmail,
                    check:()=>{
                        if(!email){
                            return "El valor no puede estar vacio."
                        }
                        let reg = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
                        if (reg.test(email)) {
                            return null;
                        }else{
                            return "Formato de correo electrónico inválido";
                        }
                    }
                },
                telefono:{
                    value:telefono,
                    setValue:setTelefono,
                    check:()=>{
                        if(!telefono){
                            return "El valor no puede estar vacio."
                        }
                        if(!isNaN(telefono)){
                            if(telefono.length===10){
                                return null;
                            }else{
                                return "El número telefónico debe contener 10 dígitos"
                            }
                        }else{
                            return "Formato telefónico inválido."
                        }
                    }
                },
                cp:{
                    value:cp,
                    setValue:setCp,
                    check:()=>{
                        if(!cp){
                            return "El valor no puede estar vacio."
                        }
                        if(cp.length===5){
                            if(!isNaN(cp)){
                                return null;
                            }else{
                                return "Formato de código postal inválido."
                            }
                        }else{
                            return "El código postal debe contener solo 5 números."
                        }
                    }
                },
                pais:{
                    value:pais,
                    setValue:setPais,
                    check:()=>{
                        if(!pais){
                            return "Inserte su codigo postal para autocompletar este valor."
                        }
                    }
                },
                estado:{
                    value:estado,
                    setValue:setEstado,
                    check:()=>{
                        if(!estado){
                            return "Inserte su codigo postal para autocompletar este valor."
                        }
                    }
                },
                ciudad:{
                    value:ciudad,
                    setValue:setCiudad,
                    check:()=>{
                        if(!ciudad){
                            return "Inserte su codigo postal para autocompletar este valor."
                        }
                        return null;
                    }
                },
                calle:{
                    value:calle,
                    setValue:setCalle,
                    check:()=>{
                        if(!calle){
                            return "El valor no puede estar vacio.";
                        }
                        return null;
                    }
                },
                colonia:{
                    value:colonia,
                    setValue:setColonia,
                    check:()=>{
                        if(!colonia){
                            return "El valor no puede estar vacio.";
                        }
                        return null;
                    }
                },
                numeroInt:{
                    value:numeroInt,
                    setValue:setNumeroInt,
                    check:()=>{
                        if(!numeroInt){
                            return "El valor no puede estar vacio.";
                        }
                        if(!isNaN(numeroInt)){
                            return null;
                        }else{
                            return "Formato de número inválido."
                        }
                    }
                },
                numeroExt:{
                    value:numeroExt,
                    setValue:setNumeroExt,
                    check:()=>{
                        if(!numeroExt){
                            return "El valor no puede estar vacio.";
                        }
                        if(!isNaN(numeroExt)){
                            return null;
                        }else{
                            return "Formato de número inválido."
                        }
                    }
                }
            }
        })
    },[data,error,load,fullName,email,telefono,cp,calle,colonia,numeroInt,numeroExt,pais,estado,ciudad])
    return <Catalogo.Provider value={value} {...props}/>
}
export const useCatalogo = ()=> React.useContext(Catalogo);