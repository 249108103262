import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
/*


    ses-smtp-user.20201026-135515
    Nombre de usuario de SMTP:
    AKIAZ3YPVOZSZ5NZGYDR
    Contraseña de SMTP:
    BNRF1dGVbaWnXHMMHEXZ1Vma99Iku4LPc1qIs+6XHw2u

    junta 05-11-2021
    Agregar validación al añadir el producto al pedido
    Agregar validacion en productos relacionados para que no marque NaN
    Verificar por que chrome no pone los estados y municipios en el registro
 */
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
