import React,{Fragment} from "react";
import { withRouter } from "react-router-dom";
import ErrorContainer from "../All/ErrorContainer";
import FadeIn from "react-fade-in";
import { Container } from "react-bootstrap";

const NotFound = (props)=>{
    const {history} = props;
    return(
        <Fragment>
            <FadeIn>
                <div className="backgroundProfesional">
                    <Container style={{paddingTop:20,paddingLeft:"10%",paddingRight:"10%"}}>
                        <ErrorContainer 
                            error="Contenido no encontrado."
                            callback={{
                                execute:()=>history.push("/"),
                                label:"Regresar al inicio"
                            }}
                        />
                    </Container>
                </div>
            </FadeIn>
        </Fragment>
    )
}
export default withRouter(NotFound);