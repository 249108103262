import React, {Fragment} from "react";
import { withRouter } from "react-router-dom";
import { ProfileProvider } from "../Store";
import Content from "./Content";

const MyProfile = (props)=>{
    const {user,history} = props;
    return(
        <Fragment>
            {
                user ? 
                <ProfileProvider user={user} history={history} consult={{type:"getUserInfo",postData:{}}}>
                    <Content />
                </ProfileProvider> : <LoginComponent history={history} />
            }
        </Fragment>
    )
}
const LoginComponent = (props)=>{
    const {history} = props;
    return(
        <Fragment>
            <div className="d-flex justify-content-center">
                <button className="btn btn-dark mt-2" style={{backgroundColor:"#686868",width:200}} onClick={()=>history.push("/login")}>Iniciar Sesión</button>
            </div>
            <div className="d-flex justify-content-center">
                <button className="btn btn-dark mt-2" style={{backgroundColor:"#686868",width:200}} onClick={()=>history.push("/register")}>Registrarme</button>
            </div>
        </Fragment>
    )
}
export default withRouter(MyProfile);