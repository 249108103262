import React from 'react';
import Axios from "axios";
import { Const } from '../../../../Utils/Const';
const RegisterCode = React.createContext();
export const RegisterCodeProvider = (props)=>{
    const {regCode,setRegCode,forcePrice,setForcePrice} = props;
    const [serverMsg,setServerMsg] = React.useState(null);

    const refreshServerMsg = async()=>{
        if(regCode&&regCode.length>4&&regCode.length<=10){
            console.log("Se debio ejecutar")
            let postData = {
                type:"checkForcePrice",
                regCode,
                front_date:getMysqlStringDate().split("T")[0]
            };
            let r = await Axios.post(Const.backend,postData).catch(e=>{
                setServerMsg("Error al conectar al servidor.")
                setForcePrice(null);
            })
            if(r){
                if(r.data){
                    if(r.data.error){
                        setServerMsg(r.data.error);
                        setForcePrice(null);
                    }else{
                        setServerMsg("Felicidades, obtendras precio preferencial.");
                        setForcePrice(true);
                    }
                }
            }
        }else{
            console.log("no se ejecutara",{
                regCode
            })
        }
    }
    const getMysqlStringDate = ()=>{//Obtiene la fecha actual del navegador.
        let d = new Date();
        let f_full_year = d.getFullYear();
        let f_int_month = d.getMonth()+1;
        if(f_int_month<10){
            f_int_month = "0"+f_int_month;
        }
        let f_int_day = d.getDate();
        if(f_int_day<10){
            f_int_day = "0"+f_int_day;
        }
        let f_int_hours = d.getHours();
        if(f_int_hours<10){
            f_int_hours = "0"+f_int_hours;
        }
        let f_int_minutes = d.getMinutes();
        if(f_int_minutes<10){
            f_int_minutes = "0"+f_int_minutes;
        }
        return `${f_full_year}-${f_int_month}-${f_int_day}T${f_int_hours}:${f_int_minutes}:00`;
    }
    const Effect = React.useEffect(()=>{
        if(regCode){
            if(regCode.length>=5){
                if(regCode<=10){
                    setServerMsg("El código es demasiado largo.");
                    setForcePrice(null);
                }else{
                    refreshServerMsg()
                }
            }else{
                setServerMsg("El código debe contener al menos 5 caracteres.");
                setForcePrice(null);
            }
        }else{
            setServerMsg(null);
            setForcePrice(null);
        }
    },[regCode])
    const value = React.useMemo(()=>{
        return({
            regCode,
            setRegCode,
            serverMsg,forcePrice
        })
    },[regCode,serverMsg,forcePrice])
    return <RegisterCode.Provider value={value} {...props}/>
}
export const useRegisterCode = ()=> React.useContext(RegisterCode);