import React, { Fragment } from "react";
import Content from "./Content";
import { useWebPayPlus, WebPayPlusProvider } from "./Context";

const WebPayPlus = (props)=>{
    const {llave,products,total,prodKeys,production,debug,callback,callback_fail} = props;
    return(
        <WebPayPlusProvider callback_fail={callback_fail} callback={callback} llave={llave} products={products} total={total} prodKeys={prodKeys} production={production}>
            <WebPayPlusTunnel production={production} debug={debug} />
        </WebPayPlusProvider>
    )
}
export default WebPayPlus;
const WebPayPlusTunnel = (props)=>{
    const {debug} = props;
    const {url,load,error,texto_datos,texto_cifrado,xmlString} = useWebPayPlus();
    if(error){
        return (
            <Fragment>
                <div style={{width:"100%"}}>
                    <p style={{fontSize:16,fontWeight:"bold",textAlign:"center",color:"#a1a1a1"}}>
                        {error}
                    </p>
                </div>
                {
                    debug ? 
                    <Fragment>
                        <p style={{fontSize:18,fontWeight:"bold",color:"gray"}}>
                            CADENA SIN CIFRAR
                        </p>
                        <div style={{width:"100%",padding:10,height:200,overflowY:"auto"}}>
                            <div style={{width:"100%",backgroundColor:"#c1c1c1",borderRadius:10}}>
                                <p style={{fontSize:18,fontWeight:"bold",color:"gray"}}>
                                    {texto_datos}   
                                </p>
                            </div>
                        </div>
                        <p style={{fontSize:18,fontWeight:"bold",color:"gray"}}>
                            CADENA CIFRADA
                        </p>
                        <div style={{width:"100%",padding:10,height:200,overflowY:"auto"}}>
                            <div style={{width:"100%",backgroundColor:"#c1c1c1",borderRadius:10}}>
                                <p style={{fontSize:18,fontWeight:"bold",color:"gray"}}>
                                    {texto_cifrado}
                                </p>
                            </div>
                        </div>
                        <p style={{fontSize:18,fontWeight:"bold",color:"gray"}}>
                            XML ENVIADO
                        </p>
                        <div style={{width:"100%",padding:10,height:200,overflowY:"auto"}}>
                            <div style={{width:"100%",backgroundColor:"#c1c1c1",borderRadius:10}}>
                                <p style={{fontSize:18,fontWeight:"bold",color:"gray"}}>
                                    {xmlString}
                                </p>
                            </div>
                        </div>
                    </Fragment> : null
                }
            </Fragment>
        )
    }
    if(load){
        return (
            <Fragment>
                <div style={{width:"100%"}}>
                    <p style={{fontSize:16,fontWeight:"bold",textAlign:"center",color:"cyan"}}>
                        ESPERE...
                    </p>
                </div>
            </Fragment>
        )
    }
    if(!url){
        return (
            <div className="d-flex justify-content-center">
                <div style={{width:300}}>
                    <p style={{fontSize:16,fontWeight:"bold",color:"red"}}>
                        ...ERROR...
                    </p>
                    <p style={{fontSize:16,fontWeight:"bold",color:"red"}}>
                        XML:"{xmlString}"
                    </p>
                </div>
            </div>
        )
    }
    return <Content />
}