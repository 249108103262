import React from "react";
import {Modal} from "react-bootstrap";

const ModalProfesional = (props)=>{
  const {title,show,handleClose,Content,centrado,size} = props;

  return(
    <>
    <Modal size={(size)?size:"xl"} show={show} onHide={handleClose} aria-labelledby={"modal"+title} centered={(centrado)?centrado:false}>
      <Modal.Header style={{backgroundColor:"#1a1a1a"}} closeButton>
        <Modal.Title style={{color:"#fff"}}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          (Content)?
          <Content {...props} />
          :
          <div className="d-flex justify-content-center">
            <p style={{fontWeight:"bold",fontSize:20}}>
              El modal no tiene contenido
            </p>
          </div>
        }
      </Modal.Body>
    </Modal>
    </>
  )
}

export default ModalProfesional;
