import React, {Fragment} from "react";
import {Row,Col,Container} from "react-bootstrap";
import { BillingProvider, useBilling } from "./Store";
import Loader from 'react-loader-spinner';
import ErrorContainer from "../../All/ErrorContainer";
import { isMobile } from "../../../../Utils/Const";
import Form from "./Form";
import AdvancedForm from "./AdvancedForm";
import Divider from "../../All/Divider";

const Billing = (props)=>{
    const {toast} = props;
    return(
        <div className="backgroundProfesional">
            <Container>
                <Head />
                <BillingProvider toast={toast}>
                    <Tunnel />
                </BillingProvider>
            </Container>
        </div>
    )
}

const Head = ()=>{

    return(
        <Fragment>
            <span style={{fontSize:20,fontWeight:"bold",color:"#fff"}}>
                FACTURACIÓN
            </span>
            <Divider />
            <div className="d-flex justify-content-center">
                <span style={{fontSize:12,color:"#fff"}}>
                    <b>Nota: </b>Para facturar el periodo no debe ser mayor a 30 días naturales a partir de la fecha de compra señalada en su ticket.
                </span>
            </div>
        </Fragment>
    )
}
const Tunnel = ()=>{
    const {order,pageLoad,pageError,reset} = useBilling();
    if(pageLoad){
        return(
            <div className="d-flex justify-content-center mt-5">
                <Loader
                    type="Oval"
                    color={"#fff"}
                    height={150}
                    width={150}
                    visible={true}
                />
            </div>
        )
    }
    if(pageError){
        return <ErrorContainer error={pageError} callback={{execute:reset,label:"Reintentar"}} />
    }
    if(!order){
        return <Form />
    }
    return <AdvancedForm />

}
export default Billing;