import React,{useState,useEffect} from "react";
import { withRouter } from "react-router";
import axios from "axios";
import {Row,Col,Container} from "react-bootstrap";
import Divider from "../All/Divider";
import {Const} from "../../../Utils/Const";
import FadeIn from "react-fade-in";
import WebRoad, { useWebRoad } from "../WebRoad";
import { Scrollbars } from 'react-custom-scrollbars';
const Categorie = (props)=>{
    const {history} = props;
    const {backend} = Const;
    const {updateRoads,needhref} = useWebRoad();
    const [products,setProducts] = useState([]);
    const [banner,setBanner] = useState(null);
    const [other_products,setOther_products] = useState([])
    const [isloading,setisloading] = useState(false);
    const handleLoading = (param)=>setisloading(param);

    const searchProducts = async()=>{
        let _id = props.location.search.replace("?id=","")||null
        let post = {
            type:"searchProductsBySubcategorie",
            id:_id
        }
        handleLoading(true);
        let r = await axios.post(backend,post).catch(e=>{
            alert("Error al consultar el servidor");
            setProducts([])
        })
        if(r){
            if(r.data.error){
                alert(r.data.error);
                setProducts([])
            }else{
                let element = {
                    name:r.data.elementName,
                    value:props.location.search.replace("?id=","")||null,
                    execute:needhref?()=>window.location.href=`/subcategories?id=${_id}` : ()=>history.push(`/subcategories?id=${_id}`)
                }
                updateRoads({
                    level:4,
                    element
                })
                setProducts(r.data.products);
                setOther_products(r.data.other_products);
                setBanner(r.data.banner);
            }
        }
        handleLoading(false);
    }
    useEffect(()=>{
        searchProducts()
    },[props.location.search])
    const execute = (id)=>{
        history.push({
          pathname: "/products/"+id
        });
      }
      const returnLarge = (val)=>{
        switch (val) {
            case 1:
                return "4"
                break;
                case 2:
                    return "6"
                    break;
        
            default:
                return "4"
                break;
        }
    }
    return(
        <div className="backgroundProfesional">
            <Scrollbars style={{display:"flex",width:"100%",height:"100%"}}>
                <Container>
                    <WebRoad level={4} />
                    <Banner banner={banner} />
                    {
                        isloading?
                        <div className="d-flex justify-content-center p-5">
                            <span className="profesionalText" style={{fontSize:25,color:"#fff",fontWeight:"bold",textAlign:"center"}}>
                                Buscando productos..
                            </span>
                        </div> :
                        products.length>0||other_products.length>0?
                        <FadeIn>
                            <Row className="p-3">
                            {
                                products.map((item,index)=>item.products.length>0?(
                                    <Col xs="12">
                                        <span style={{color:"#fff",fontSize:25,fontWeight:"bold"}}>
                                            {item.nombre}
                                        </span>
                                        <Divider style={{marginTop:15,marginBottom:15}}/>
                                        <Row>
                                            {
                                                item.products.map((prod)=>(
                                                    <Col xs="12" md={returnLarge(prod.type)} key={index} align="center" onClick={()=>execute(prod.value)} style={{cursor:"pointer"}}>
                                                        <ImgComponent url={prod.image} type={null} />
                                                        <span className="profesionalText" style={{fontSize:15,color:"#fff",fontWeight:"bold",textAlign:"center"}}>{prod.name}</span>
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                    </Col>
                                ):null)
                            }
                            {
                                products.length>0?<Divider style={{marginTop:15,marginBottom:15}}/> :null
                            }
                            
                            {
                                other_products.map((item,index)=>(
                                    <Col xs="12" md={returnLarge(item.type)} key={index} align="center" onClick={()=>execute(item.value)} style={{cursor:"pointer"}}>
                                        <img  style={{width:"100%",cursor:"pointer"}} src={item.image} alt="testingImage" />
                                        <span className="profesionalText" style={{fontSize:15,color:"#fff",fontWeight:"bold",textAlign:"center"}}>{item.name}</span>
                                    </Col>
                                ))
                            }
                            </Row>  
                        </FadeIn> :
                        <div className="d-flex justify-content-center p-5">
                            <span className="profesionalText" style={{fontSize:25,color:"#fff",fontWeight:"bold",textAlign:"center"}}>
                                No existen productos en esta categoría..
                            </span>
                        </div>
                    }
                </Container>
            </Scrollbars>
        </div>
    )
}
const ImgComponent = ({url,type})=>{
    const [urlImage,setUrlImage] = useState(null);
    const urlImgWaiting = `${process.env.PUBLIC_URL}/img/img/ProfWaitImg${!type?"Quad":"Rect"}.png`;
    const onLoad = ()=>{
        setUrlImage(url);
    }
    return <img src={urlImage||urlImgWaiting} onLoad={onLoad} style={{width:"100%",cursor:"pointer"}} />
}
const Banner = ({banner})=>{
    if(!banner){
        return null;
    }
    return(
        <div className="p-3">
            <img 
                src={banner}
                style={{
                    width:"100%",
                    objectFit:"contain",
                    borderRadius:10
                }}
            />
        </div>
    )
}
export default withRouter(Categorie);