import React, {useState,useMemo} from "react";

const CartContext = React.createContext();

export const CartProvider = (props)=>{
  const [car,setCar] = useState(JSON.parse(localStorage.getItem("car"))||[]);
  const addCartItem = (item)=>{
    localStorage.setItem("car",JSON.stringify(car.concat(item)));
    console.log({item});
    setCar(car.concat(item));
  }
  const removeCartItem = (index)=>{
    let tempArray = [];
    car.forEach((item, i) => {
      tempArray.push(item);
    });
    tempArray.splice(index,1);
    tempArray.forEach((item, i) => {
      item.promos = null;
    });
    localStorage.setItem("car",JSON.stringify(tempArray.concat()));
    setCar(tempArray.concat());
    //setCarTemp(tempArray.concat())
  }
  const cleanCart = ()=>{
    localStorage.setItem("car",JSON.stringify([]));
    setCar([]);
  }
  const addMultipleItems = (items)=>{
    setCar(car.concat([...items]))
    localStorage.setItem("car",JSON.stringify(car.concat([...items])));
  }
  const changeQuanity = (index,quant)=>{
    let newItems = [];
    if(quant>0){
      for (let i = 0; i < car.length; i++) {
        const item = car[i];
        if(i===index){
          item.quantity = quant;
        }
        newItems.push(item);
      }
      localStorage.setItem("car",JSON.stringify(newItems.concat()));
      setCar(newItems.concat())
    }else{
      removeCartItem(index);
    }
  }
  const value = useMemo(()=>{
    return({
      car,
      addCartItem,
      removeCartItem,
      cleanCart,
      addMultipleItems,
      changeQuanity
    })
  },[car])
  return <CartContext.Provider value={value} {...props}/>
}
export const useCart = ()=>{
  return React.useContext(CartContext);
}
