import React, { Fragment } from "react";
import Selector from "../../All/Select";
import {Col,Row} from "react-bootstrap"
import {number_format} from "locutus/php/strings";
import { useCartScreen } from "../Context";
import {FaFilePdf} from "react-icons/fa";
import { useCart } from "../../../../Context/ContextCart";
import PurchaseComponent from "./PurchaseComponent";
import FadeIn from 'react-fade-in';

const RightSide = ()=>{
    const {car} = useCart();
    const {
        cartScreenData,
        sendCost,
        selectedSendType,
        setSelectedSendType,
        history,
        getTotal,
        constructPdf,
        selectedUbi,
        returnFullOcurre,
        returnFullDirection,
        checkErrors,
        usePay,
        Purchase,
        handleToggleModalPago,
        availCode,
        promCode,setPromCode,
        getPromCodeType,
        getPromCodeCost,
        selectedPaymentMethod,setSelectedPaymentMethod
    } = useCartScreen();
    const {send_types,paymentMethods} = cartScreenData;

    const executePurchase = ()=>{
        if(usePay&&selectedPaymentMethod&&selectedPaymentMethod.value===1){
            handleToggleModalPago(true);
        }else{
            let Reference = "PAGO DE PRUEBA";
            if(selectedPaymentMethod&&selectedPaymentMethod.value===2){
                Reference = null
            }
            Purchase(Reference);
        }
    }
    return(
        <Fragment>
            <div className="d-flex justify-content-end">
                <p style={{color:"#fff"}}>
                    Resumen del pedido
                </p>
            </div>
            <FadeIn>
            <Row>
                <Col xs="4" align="left">
                    <p style={{color:"#fff"}}>
                        Productos:
                    </p>
                    <p style={{color:"#fff"}}>
                        Subtotal:
                    </p>
                    <p style={{color:"#fff"}}>
                        Iva:
                    </p>
                    <p style={{color:"#fff"}}>
                        Envio:
                    </p>
                    <p style={{color:"#fff"}}>
                        Servicio:
                    </p>
                    {
                        availCode&&availCode.tipo_codigo_promo ? 
                        <p style={{color:"#fff"}}>
                            {getPromCodeType()}
                        </p> : null
                    }
                    <p style={{color:"#fff"}}>
                        Total:
                    </p>
                </Col>
                <Col xs="8" align="right">
                    <p style={{color:"#fff"}}>
                        <b>
                            {car.length}
                        </b>
                    </p>
                    <p style={{color:"#fff"}}>
                        <b>
                            $ {number_format(getTotal(false,true),2)}
                        </b>
                    </p>
                    <p style={{color:"#fff"}}>
                        <b>
                            $ {number_format(getTotal(false,false)-getTotal(false,true),2)}
                        </b>
                    </p>
                    <p style={{color:"#fff"}}>
                        {
                            sendCost&&sendCost.costoEstafeta&&sendCost.costoEstafeta.oldPrice&&sendCost.costoEstafeta.oldPrice !== sendCost.costoEstafeta.total ?
                            <span style={{color:"red",textDecoration: 'line-through',marginRight:10}}>
                                $ {number_format(sendCost.costoEstafeta.oldPrice,2)}
                            </span> : null
                        }
                        <b>
                            {sendCost&&sendCost.costoEstafeta?"$"+number_format(sendCost.costoEstafeta.total,2):"$0.00"}
                        </b>

                    </p>
                    <p style={{color:"#fff"}}>
                        <b>
                            {sendCost&&sendCost.total_cost?"$"+number_format(sendCost.total_cost,2):"$0.00"}
                        </b>
                    </p>
                    {
                        availCode ? 
                        <p style={{color:"#fff"}}>
                            <b>
                                $ -{number_format(getPromCodeCost(),2)}
                            </b>
                        </p> : null
                    }
                    <p style={{color:"#fff"}}>
                        <b>
                            $ {number_format(getTotal(true),2)}
                        </b>
                    </p>
                </Col>
            </Row>
            
            <div className="d-flex justify-content-center mt-2" style={{cursor:"pointer"}} onClick={()=>constructPdf()}>
                <FaFilePdf 
                    style={{
                        fontSize:21,
                        color:"#fff",
                        marginTop:5
                    }}
                /> 
                <span style={{color:"#fff",fontSize:20,marginLeft:5}}>
                    Generar cotización
                </span>
            </div>
            
            {
                localStorage.conection ? 
                <div style={{width:"100%",padding:5}}>
                    <input type="text" value={promCode} onChange={(e)=>setPromCode(e.target.value)} className="form-control mt-1" placeholder="Código promocional" />
                    <Selector 
                        items={paymentMethods}
                        val={selectedPaymentMethod}
                        setVal={setSelectedPaymentMethod}
                        label="Tipo de pago"
                        placeholder="Seleccione un tipo de pago"
                    />
                    <Selector 
                        items={send_types}
                        val={selectedSendType}
                        setVal={setSelectedSendType}
                        label="Tipo de envío"
                        placeholder="Seleccione un tipo de envío"
                    />
                    <PurchaseComponent />
                    {
                        selectedUbi ? 
                        <Fragment>
                            <div className="p-3">
                                <div className="card" style={{backgroundColor:"transparent",padding:15,textAlign:"center"}}>
                                    <p style={{fontWeight:"bold",color:"#fff",fontSize:20}}>
                                        Dirección de entrega
                                    </p>
                                    <p style={{fontWeight:"bold",color:"#fff"}}>
                                        {
                                            selectedSendType&&selectedSendType.value>2?
                                            returnFullOcurre() 
                                            :
                                            returnFullDirection()
                                        }
                                    </p>
                                </div>
                            </div>
                        </Fragment> : null
                    }
                    <button 
                        className="btn btn-dark btn-block mt-2" 
                        disabled={checkErrors()} 
                        style={{backgroundColor:"#686868"}} 
                        onClick={executePurchase}>Finalizar Compra</button>
                </div> :
                <Fragment>
                    <button 
                        className="btn btn-dark btn-block mt-2" 
                        style={{
                            backgroundColor:"#686868"
                        }} 
                        onClick={()=>history.push("/login")}>
                        Iniciar Sesión
                    </button>
                    <button 
                        className="btn btn-dark btn-block mt-2" 
                        style={{
                            backgroundColor:"#686868"
                        }} 
                        onClick={()=>history.push("/register")}>
                        Registrarme
                    </button>
                </Fragment>
            }
            </FadeIn>
        </Fragment>
    )
}
export default RightSide;