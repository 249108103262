import React, {Fragment} from "react";
import {Row,Container,Col} from "react-bootstrap";
import { useProfile } from "../Store";
import Input from "../../All/InputV2";
import Button from "../../All/Button";
import Divider from "../../All/Divider";

const Tunnel = ()=>{
    return(
        <div className={"backgroundProfesional"}>
            <Container className="mt-4">
                <Row>
                    <Col xs="12">
                        <Content />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
const Content = ()=>{
    return(
        <Fragment>
            <Header />
            <Body />
            <Footer />
        </Fragment>
    )
}
const Header = ()=>{
    const {user} = useProfile();
    return(
        <Fragment>
            <Row>
                <Col xs="12">
                    <div className="d-flex justify-content-end">
                        <span className="header-text-profile">
                            Restablecer Contraseña
                        </span>
                    </div>
                </Col>
            </Row>
            <Divider />
        </Fragment>
    )
}
const Body = ()=>{
    const {psw,setPsw,psw2,setPsw2} = useProfile();
    return(
        <Fragment>
            <Row className="mt-3">
                <Col xs="12" md="6">
                    <Input 
                        value={psw}
                        setValue={setPsw}
                        type="password"
                        label="Contraseña Actual"
                        placeholder="Actual"
                        useLabelWhite
                    />
                </Col>
                <Col xs="12" md="6">
                    <Input 
                        value={psw2}
                        setValue={setPsw2}
                        type="password"
                        label="Nueva Contraseña"
                        placeholder="Nueva"
                        useLabelWhite
                    />
                </Col>
                <Col xs="12">
                    <div className="d-flex justify-content-center">
                        <span style={{fontSize:10,fontWeight:"bold",color:"#fff"}}>
                            La contraseña debe contener al menos 8 caracteres.
                        </span>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
}
const Footer = ()=>{
    const {saveNewPsw,history,psw,psw2} = useProfile();
    const checkPswErrors = ()=>{
        if(psw2.length>=8&&psw2!==psw){
            return false;
        }else{
            return true;
        }
    }
    return(
        <div className="d-flex justify-content-end mt-3">
            <div className="footer-profile-btnContainer">
                <Button type={"info"} style={{marginRight:10}} execute={()=>history.push("myprofile")}>
                    Cancelar
                </Button>
                <Button type="success" execute={saveNewPsw} disabled={checkPswErrors()}>
                    Guardar Cambios
                </Button>
            </div>
        </div>
    )
}

export default Tunnel;