import React,{Fragment,useState} from "react";
import { Col, Row } from "react-bootstrap";
import Divider from "../../All/Divider";
import Input from "../../All/InputV2";
import { useBilling } from "./Store";
import Button from "../../All/Button";

const Form = ()=>{

    return(
        <Fragment>
            <Body />
        </Fragment>
    )
}
export default Form;
const Body = ()=>{
    const [folio,setFolio] = useState("");
    const {searchOrder} = useBilling();
    return(
        <Fragment>
            <div className="d-flex justify-content-center mt-3">
                <span style={{fontSize:20,fontWeight:"bold",color:"#32c768"}}>
                    CONSULTAR POR FOLIO DE TICKET
                </span>
            </div>
            <Row>
                <Col xs="12" md="8">
                    <Input 
                        value={folio}
                        setValue={setFolio}
                        label="Folio de compra"
                        useLabelWhite
                    />
                </Col>
                <Col xs="12" md="4">
                    <div className="d-flex justify-content-center">
                        <Button execute={()=>searchOrder(folio)} type="success" disabled={folio.length<=4}>
                            Consultar
                        </Button>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
}