import Axios from 'axios';
import React from 'react';
import { useCart } from '../../../Context/ContextCart';
import { Const } from '../../../Utils/Const';
const CartScreen = React.createContext();
export const CartScreenProvider = (props)=>{
    const {user,history,toast} = props;
    const {car,removeCartItem,cleanCart,changeQuanity} = useCart();
    //Estados Principales
    const checkBackend4UsePay = ()=>{
        switch (Const.backend) {
            case "http://localhost:8081/":
                return false;
            default:
                return true;
        }
    }
    const usePay = checkBackend4UsePay();
    const [cartScreenData,setCartScreenData] = React.useState(null);
    const [cartScreenError,setCartScreenError] = React.useState(null);
    const [cartScreenLoader,setCartScreenLoader] = React.useState(false);
    const handleToggleLoader = (arg)=>setCartScreenLoader(arg);
    //Estados Secundarios
    const [showModalPago,setShowModalPago] = React.useState(false);
    const handleToggleModalPago = (arg)=>setShowModalPago(arg);
    const [sendCost,setSendCost] = React.useState(null);
    const [selectedUbi,setSelectedUbi] = React.useState(null);
    const [selectedSendType,setSelectedSendType] = React.useState(null);
    const [selectedOcurre,setSelectedOcurre] = React.useState(null);
    const [promCode,setPromCode] = React.useState("");
    const [availCode,setAvailCode] = React.useState(null);

    const [isConsultingSendType,setIsConsultingSendType] = React.useState(false);

    //Estados para pagos referenciados
    const [selectedPaymentMethod,setSelectedPaymentMethod] = React.useState({value:1,label:"Pago en línea"})


    //Consulta información principal
    const refreshCartScreenData = async(showLoad)=>{
        let postData = {
            type:"promotionsConsult",
            products:car,
            token:localStorage.conection||null,
            front_date:getMysqlStringDate()
        }
        if(showLoad){
            handleToggleLoader(true);
        }
        let r = await Axios.post(Const.backend,postData).catch(e=>{
            setCartScreenError("Error al conectar al servidor.")
        })
        if(r){
            if(r.data){
                if(r.data.error){
                    setCartScreenError(r.data.error);
                }else{
                    let {ubications,send_types} = r.data;
                    //seleccionando la ubicación predeterminada.
                    if(ubications&&ubications.length>0){
                        let defaultUbiClient = ubications.filter(el=>el.predeterminada);
                        if(defaultUbiClient){
                            setSelectedUbi(defaultUbiClient[0]);
                        }else{
                            setSelectedUbi(ubications[0]);
                        }
                    } 
                    //seleccionando por defecto Vía terrestre
                    if(send_types.length>2){
                        setSelectedSendType(send_types[1])
                    }else{
                        setSelectedSendType(send_types[0])
                    }
                    //console.log(r.data);
                    setCartScreenData(r.data);
                    if(selectedSendType&&selectedUbi&&car.length>0){
                        refreshSendCost()
                    }
                }
            }
        }
        if(showLoad){
            handleToggleLoader(false);
        }
    }

    //Ejecuta la compra
    const Purchase = async(paymentRef)=>{
        let postData = {
            type:"createFuv",
            prods:getCarForBackend(),
            dirId:selectedUbi,
            token:localStorage.conection,
            total:getTotal(true),
            delivery:sendCost,
            paymentRef,
            tipo_cliente:parseInt(cartScreenData.client.Clasificacion)||1,
            sendType:selectedSendType.value,
            ocurre:selectedSendType.value>2?parseInt(selectedOcurre.value):null,
            paymentMethod:selectedPaymentMethod.value,
            availCode
        }
        let r = await Axios.post(Const.backend,postData).catch(e=>{
            toast.error("Error al conectar al servidor");
        })
        if(r){
            if(r.data){
                if(r.data.error){
                    toast.error(r.data.error);
                }else{
                    cleanCart();
                    history.push("/thanks?id="+r.data.newId)
                    toast.success("Gracias por su compra.");
                }
            }
        }
    }

    //Genera el PDF
    const constructPdf = async()=>{
        if(localStorage.conection){
            let postData = {
                type:"userDataConsult",
                token:localStorage.conection,
                delivery:sendCost,
                products:getCarForBackend(),
                front_date:getMysqlStringDate()
            }
            handleToggleLoader(true);
            let r = await Axios.post(Const.backend,postData).catch(e=>{
                setCartScreenError("Error al conectar al servidor.")
            })
            if(r){
                if(r.data){
                    if(r.data.error){
                        setCartScreenError(r.data.error);
                    }else{
                        //console.log({sendCost});
                        history.push("quotation",{
                            car:getCarForBackend(),
                            userData:r.data.userData,
                            folio:r.data.newFolio,
                            tipo_cliente:r.data.tipo_cliente,
                            sendCost
                        });
                    }
                }
            }
            handleToggleLoader(false);
        }else{
            toast.error("Por favor inicie sesión o registrese para generar cotizaciones en formato PDF")
        }
    }

    //Actualiza el costo de envio
    const refreshSendCost = async()=>{
        let avail = selectedSendType.value === 3 ? selectedOcurre : selectedUbi;
        if(avail){
            let postData = {
                type:"getSendCost",
                send_type:selectedSendType,
                products:car,
                cp:selectedSendType.value!==3?selectedUbi.cp:null
            };
            setIsConsultingSendType(true)
            let r = await Axios.post(Const.backend,postData).catch(e=>{
                toast.error("Error al conectar al servidor.")
            })
            if(r){
                if(r.data){
                    if(r.data.error){
                        toast.error(r.data.error);
                    }else{
                        setSendCost(r.data.result.deliveryCost);
                    }
                }
            }
            setIsConsultingSendType(false);
        }else{
            toast.error("Por favor seleccione una ubicación de entrega para continuar")
        }
    }

    //Actualiza el codigo promosional
    const refreshPromo = async()=>{
        if(promCode&&promCode.length>4&&promCode.length<=8&&localStorage.conection){
            //console.log("Se debio ejecutar")
            let postData = {
                type:"getPromCode",
                promCode,
                front_date:getMysqlStringDate().split("T")[0]
            };
            let r = await Axios.post(Const.backend,postData).catch(e=>{
                toast.error("Error al conectar al servidor.")
            })
            if(r){
                if(r.data){
                    if(r.data.error){
                        toast.error(r.data.error);
                    }else{
                        setAvailCode(r.data.availCode);
                    }
                }
            }
        }else{
            //console.log("no se ejecutara",{promCode,token:localStorage.conection})
            setAvailCode(null);
        }
    }

    //Helpers
    const getMysqlStringDate = ()=>{//Obtiene la fecha actual del navegador.
        let d = new Date();
        let f_full_year = d.getFullYear();
        let f_int_month = d.getMonth()+1;
        if(f_int_month<10){
            f_int_month = "0"+f_int_month;
        }
        let f_int_day = d.getDate();
        if(f_int_day<10){
            f_int_day = "0"+f_int_day;
        }
        let f_int_hours = d.getHours();
        if(f_int_hours<10){
            f_int_hours = "0"+f_int_hours;
        }
        let f_int_minutes = d.getMinutes();
        if(f_int_minutes<10){
            f_int_minutes = "0"+f_int_minutes;
        }
        return `${f_full_year}-${f_int_month}-${f_int_day}T${f_int_hours}:${f_int_minutes}:00`;

    }

    const getCarForBackend = ()=>{//Genera el formato del carrito para el backend
        let newCart = [];
        car.forEach((el,i)=>{
          newCart.push({
            ...el,
            precio:getPrice(el,{toFix:false,dontIncludeIva:true,moduleName:"product-normal-price"}),
            newIvaProd:ROUND_NUMBER(getPrice(el,{toFix:false,dontIncludeIva:false})-getPrice(el,{toFix:false,dontIncludeIva:true}))
          })
        })
        return newCart;
    }

    const getPrice = (item,options)=>{//Retorna el precio del producto con diferentes opciones.
        let {pba,pbb,pbc,ivama,ivamb,ivamc} = item.prices;
        let iva;
        let price;
        let tipo_cliente = parseInt(cartScreenData?cartScreenData.client&&cartScreenData.client.Clasificacion:null)
        let dontIncludeIva = false;
        if(options&&options.dontIncludeIva){
            dontIncludeIva = true;
        }
        let moduleName = "Sin definir";
        if(options&&options.moduleName){
            moduleName = options.moduleName;
        }
        if(moduleName!=="getTotal"){
            //console.log("Executing getPrice",options)
        }
        switch (tipo_cliente) {
          case 2:
              //console.log("El tipo de cliente es precio B")
              iva = parseFloat(ivamb)
              if(dontIncludeIva){
                price = ROUND_NUMBER( pbb );
              }else{
                price = ROUND_NUMBER( ( ROUND_NUMBER(pbb)+ROUND_NUMBER(ivamb) ) );
              }
            break;
          case 3:
              //console.log("El tipo de cliente es precio C")
              iva = parseFloat(ivamc)
              if(dontIncludeIva){
                price = ROUND_NUMBER( pbc );
              }else{
                price = ROUND_NUMBER( ( ROUND_NUMBER(pbc)+ROUND_NUMBER(ivamc) ) );
              }
            break;
          default:
              //console.log("El tipo de cliente es precio A")
              if(availCode&&availCode.tipo_codigo_promo){//si se inserto un codigo promosional valido
                switch (availCode.tipo_codigo_promo) {//verificando si es tipo forzar precio X
                    case 3://forza Precio B
                        //console.log("Se forzo precio B por codigo.");
                        iva = parseFloat(ivamb)
                        if(dontIncludeIva){
                            price = ROUND_NUMBER( pbb );
                        }else{
                            price = ROUND_NUMBER( ( ROUND_NUMBER(pbb)+ROUND_NUMBER(ivamb) ) );
                        }
                        break;
                    case 4://forza Precio C
                        //console.log("Se forzo precio C por codigo.")
                        iva = parseFloat(ivamc)
                        if(dontIncludeIva){
                            price = ROUND_NUMBER( pbc );
                        }else{
                            price = ROUND_NUMBER( ( ROUND_NUMBER(pbc)+ROUND_NUMBER(ivamc) ) );
                        }
                        price = ROUND_NUMBER( ( ROUND_NUMBER(pbc)+ROUND_NUMBER(ivamc) ) );
                        break;
                    default://si es otro tipo de codigo
                    iva = parseFloat(ivama)
                        if(dontIncludeIva){
                            price = ROUND_NUMBER( pba );
                        }else{
                            price = ROUND_NUMBER( ( ROUND_NUMBER(pba)+ROUND_NUMBER(ivama) ) );
                        }
                        break;
                }
              }else{
                iva = parseFloat(ivama)
                if(dontIncludeIva){
                    price = ROUND_NUMBER( pba );
                }else{
                    price = ROUND_NUMBER( ( ROUND_NUMBER(pba)+ROUND_NUMBER(ivama) ) );
                }
              }
            break;
        }
        //console.log("Precio antes de ofertas",{price,iva})
        let promos = getPromo(item);
        if(promos&&promos.length>0){
            if(moduleName!=="getTotal"){
                //console.log("Entro a promos",promos)
            }
            for (let i = 0; i < promos.length; i++) {
                const promo = promos[i];
                let {idType,parametros_json,productParams} = promo;
                if(idType===1){
                    let params_event = JSON.parse(parametros_json);
                    let {porcent} = params_event;
                    let one_porcent;
                    //console.log("oldPrice:",price)
                    if(dontIncludeIva){
                        one_porcent = ((price+iva)/100);
                    }else{
                        one_porcent = (price/100);
                    }
                    if((options&&!options.realPrice&&!options.showOldPrice)){
                        let descuento = (one_porcent*porcent);
                        let precio_mas_iva;
                        if(dontIncludeIva){
                            precio_mas_iva = (price+iva);
                        }else{
                            precio_mas_iva = (price);
                        }
                        let costo_sin_iva = Math.round(((precio_mas_iva - descuento) / 1.16)*1000)/1000;
                        let iva_producto = Math.round((costo_sin_iva*.16)*1000)/1000;
                        //console.log({price,iva,precio_mas_iva,descuento,one_porcent,porcent,costo_sin_iva,iva_producto})
                        if(dontIncludeIva){
                            price = ROUND_NUMBER(costo_sin_iva)
                        }else{
                            price = ROUND_NUMBER(costo_sin_iva+iva_producto);
                        }
                    }else{
                        if(options&&options.showOldPrice){
                            //console.log("Entro aca")
                            if(dontIncludeIva){
                                price = ROUND_NUMBER(price)
                            }else{
                                price = ROUND_NUMBER(price+iva);
                            }
                        }
                    }
                }else{
                    if(idType===3){
                        let params_product = JSON.parse(productParams);
                        let {prodCost} = params_product;
                        if((options&&!options.realPrice)){
                            if(moduleName!=="getTotal"){
                                /*console.log("Entro a tipo 3",{
                                    parametros_json,
                                    productParams,
                                    price,iva
                                })*/
                            }
                            let costo_sin_iva = Math.round((prodCost / 1.16)*1000)/1000;
                            let iva_producto = Math.round((costo_sin_iva*.16)*1000)/1000;
                            iva = iva_producto;
                            //console.log("oldPrice:",price)
                            if(dontIncludeIva){
                                price = ROUND_NUMBER((prodCost-iva_producto))
                            }else{
                                price = ROUND_NUMBER(prodCost)
                            }
                        }
                    }
                }
            }
        }else{
            //console.log("No entro a promos")
        }
        
        if(options&&options.quant){
          price = price*item.quantity;
        }
        if(options&&options.toFix){
          price = price.toFixed(2);
        }
        //console.log("newPrice:",price);
        return price;
        
    }
    const getTotal = (withSendCost,DontIncludeIva)=>{//Retorna el total
        let suma = 0;
        for (let i = 0; i < car.length; i++) {
            const item = car[i];
            //console.log("Suma antes de sumar:",suma);
            let price = getPrice(item,{quant:true,realPrice:withSendCost&&withSendCost.realPrice?true:false,moduleName:"getTotal"});
            if(DontIncludeIva===true){ 
                price = getPrice(item,{quant:true,realPrice:withSendCost&&withSendCost.realPrice?true:false,dontIncludeIva:true,moduleName:"getTotal"});
            }
            //console.log("price en suma getTotal:",price)
            suma += (ROUND_NUMBER(price));
        }
        //console.log("Suma despues de productos:",suma);
        if(withSendCost&&sendCost&&sendCost.total_cost){
          suma = suma + sendCost.total_cost;
        }
        if(withSendCost&&sendCost&&sendCost.costoEstafeta){
          suma = suma + sendCost.costoEstafeta.total;
        }
        if(withSendCost&&availCode){
            suma = suma - getPromCodeCost();
        }
        //console.log("suma despues de validaciones:",suma)
        return ROUND_NUMBER(suma);
    }

    const getYourDeal = ()=>{
        let total = getTotal({
            realPrice:false
        })
        let totalReal = getTotal({
            realPrice:true
        })
        let diff = total - totalReal;
        //console.log({totalReal,total})
        return diff;
    }

    const ROUND_NUMBER = (number)=>Math.round(number*100)/100;//Redondea a dos dígitos

    const returnFullOcurre = ()=>{//Devuelve la direccion de recolección
        if(selectedOcurre){
          let {calle,numero,codigo_postal,colonia,municipio,estado} = selectedOcurre;
          return `${calle} ${numero}, ${colonia}, ${municipio}, ${estado}, ${codigo_postal}`;
        }else{
          return "Seleccione una dirección de recolección para continuar."
        }
    }
    const returnFullDirection = ()=>{//Devuelve la dirección de entrega
        let {calle,numero,cp,colonia,municipio,estado} = selectedUbi;
        return `${calle} ${numero}, ${colonia}, ${municipio}, ${estado}, ${cp}`;
    }

    const checkErrors = ()=>{//Verificador de errores
        if(car.length>0){
          if(selectedSendType){
            let isOcurre = selectedSendType.value>2;
            if(isOcurre){
              if(selectedOcurre){
                return false;
              }else{
                return true;
              }
            }else{
              if(selectedUbi){
                if(sendCost&&sendCost.costoEstafeta&&sendCost.total_cost){
                    return false;
                }else{
                    return true;
                }
              }else{
                return true;
              }
            }
          }else{
            return true;
          }
        }else{
          return true;
        }
    }

    const getPromCodeType = ()=>{
        let name = availCode.nombre_tipo_codigo;
        //let label = availCode.tipo_codigo_promo === 1 ? (ROUND_NUMBER( (getTotal()/100) * (params.porcent))) : params.quant;
        return `${name}:`;
    }

    const getPromCodeCost = ()=>{
        let tot = 0;
        if(availCode&&availCode.tipo_codigo_promo<3){
            let type = availCode.tipo_codigo_promo;
            let params = JSON.parse(availCode.parametros_json);
            switch (type) {
                case 1:
                    let {porcent,maximo} = params;
                    let total = getTotal();
                    let one_porcent = ROUND_NUMBER(total / 100);
                    if(total<=maximo){
                        tot = ROUND_NUMBER(one_porcent * porcent);
                    }
                    break;
            
                default:
                    let {quant} = params;
                    tot = quant;
                    break;
            }
        }
        return tot;
    }
    const getPromo = (item)=>{
        let {id} = item;
        let promos = []
        if(cartScreenData&&cartScreenData.promotions.length>0){
            let {promotions} = cartScreenData;
            /*promotions.filter( 
                el => ( (el.productId===id&&el.idType!==3) || ( (el.productId===id&&el.idType===3&&JSON.parse(el.productParams).minCant>=item.quantity) ) )
            );*/
            for (let i = 0; i < promotions.length; i++) {
                const promotion = promotions[i];
                //console.log("index-promotion",i)
                //console.log("promotion-one",promotion);
                if(promotion.productId===id){
                    switch (promotion.idType) {
                        case 3:
                            let params = JSON.parse(promotion.productParams);
                            if(params){
                                let {minCant} = params;
                                if(item.quantity>=minCant){
                                    let check = promos.filter(el=>el.idType===3);
                                    if(check.length===0){
                                        promos.push({...promotion,label:`${promotion.typeName} (${minCant}+)`,labelOferta:`${promotion.eventName} (${minCant}+)`});
                                    }
                                }
                            }
                            break;
                    
                        default:
                            promos.push({...promotion,label:`${promotion.typeName}`,labelOferta:`${promotion.eventName}`})
                            break;
                    }

                }
            }
            //console.log("promos",promos)
            return promos
        }
        //console.log("Termino ejecución")
        return promos;
    }
    //Ejecuta la funcion de consulta principal cuando el usuario cambia de valor.
    React.useEffect(()=>{
        if(!cartScreenData){
            refreshCartScreenData(true)
        }
    },[user])
    //Ejecuta la funcion que actualiza el costo de envío cuando cambia su valor.
    React.useEffect(()=>{
        if(selectedSendType&&selectedUbi&&!isConsultingSendType&&car.length>0){
            if(selectedSendType.value>=3&&cartScreenData){
                setSelectedOcurre(cartScreenData.ocurre[0]);
            }
            refreshSendCost()
        }else{
            if(car.length===0){
                setSendCost(null)
            }
        }
    },[selectedSendType,selectedUbi,selectedOcurre,cartScreenData,car])
    //Ejecuta la funcion que actualiza los codigos promosionales
    React.useEffect(()=>{
        refreshPromo();
    },[promCode])

    const value = React.useMemo(()=>{
        return({//Estados compartidos
            //Estados heredados
            user,
            history,
            toast,
            //Estados principales
            usePay,
            cartScreenData,
            cartScreenError,
            cartScreenLoader,
            //Helpers
            Purchase,
            handleToggleModalPago,
            getTotal,
            getPrice,
            constructPdf,
            returnFullOcurre,
            returnFullDirection,
            checkErrors,
            getPromCodeType,
            getPromCodeCost,
            getPromo,
            getYourDeal,
            //Estados secundarios
            showModalPago,
            selectedUbi,setSelectedUbi,
            sendCost,setSendCost,
            selectedSendType,setSelectedSendType,
            selectedOcurre,setSelectedOcurre,
            promCode,setPromCode,
            availCode,
            selectedPaymentMethod,setSelectedPaymentMethod
        })
    },[//Cuando cambian los siguientes valores se actualizan los componentes
        user,
        cartScreenData,
        cartScreenError,
        cartScreenLoader,
        showModalPago,
        sendCost,
        selectedUbi,
        selectedSendType,
        selectedOcurre,
        promCode,availCode,selectedPaymentMethod,car,isConsultingSendType
    ])
    return <CartScreen.Provider value={value} {...props}/>
}
export const useCartScreen = ()=> React.useContext(CartScreen);