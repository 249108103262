import React from 'react';
import { Const } from '../../../Utils/Const';
import axios from "axios";
const MyFavourite = React.createContext();
export const MyFavouriteProvider = (props)=>{
    const {history,idiom} = props;
    const [screenData,setScreenData] = React.useState(null);
    const [screenError,setScreenError] = React.useState(null);
    const [screenLoad,setScreenLoad] = React.useState(false);
    const handleLoad = (arg)=>setScreenLoad(arg);

    const refreshData = async(useLoad)=>{
        if(useLoad){
            handleLoad(true);
        }
        let post = {
            type:"GetMyFavourites",
            token:localStorage.conection
        }
        let r = await axios.post(Const.backend,post).catch(e=>{
            setScreenError("Error al conectar al servidor");
        })
        if(r){
            if(r.data.error){
                setScreenError(r.data.error);
            }else{
                setScreenData(r.data.result);
            }
            if(useLoad){
                handleLoad(false);
            }
        }
    }

    React.useEffect(()=>{
        if(localStorage.conection&&!screenData){
            refreshData(true);
        }
    },[])

    const value = React.useMemo(()=>{
        return({
            idiom,
            history,
            screenData,
            screenError,
            screenLoad
        })
    },[screenData,screenError,screenLoad])
    return <MyFavourite.Provider value={value} {...props}/>
}
export const useMyFavourite = ()=> React.useContext(MyFavourite);