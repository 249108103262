import React,{useState,useEffect} from "react";
import {withRouter,useParams,useHistory} from "react-router-dom";
import Axios from "axios";
import ProductView from "./ProductView";
import Loader from 'react-loader-spinner';
import axios from "axios";
import {Const} from "../../../Utils/Const";
import {useCart} from "../../../Context/ContextCart";
const Product = (props)=>{
  const {toast} = props;
  const {history} = useHistory();
  const {id} = useParams();
  const product = id;
  const {car,addCartItem} = useCart();
  const [quantity,setQuantity] = useState(0);
  const [isLoading,setIsLoading] = useState(false);
  const [clienteTag,setClienteTag] = useState("");
  const [telefono,setTelefono] = useState("");
  const [producto,setProducto] = useState(null);
  const [relations,setRelations] = useState([]);
  const [tipo_cliente,setTipo_cliente] = useState(null);
  const [texts,setTexts] = useState([]);
  const [modalSoldOut,setModalSoldOut] = useState(false);
  const [btnSoldOut,setBtnSoldOut] = useState(false);
  const handleBtnSoldOut = (param)=>setBtnSoldOut(param);
  const handleToggleSoldOut = (param)=>setModalSoldOut(param);

  const createNotification = async()=>{
    if(product){
      let post = {
          type:"create-product-notification",
          token:localStorage.conection||null,
          product,
          quantity
        };
        handleBtnSoldOut(true);
        let r = await Axios.post(Const.backend,post).catch(e=>{
            toast.error("Error al conectar al servidor");
        })
        
        setTimeout(()=>{
          if(r){
            if(r.data.error){
                toast.error(r.data.error);
            }else{
                toast.info("Le notificaremos vía correo electrónico cuando contemos con la cantidad solicitada.");
                handleToggleSoldOut(false)
            }
          }
          handleBtnSoldOut(false);
        },500)
    }else{
      toast.error("Este producto no está disponible.");
    }
  }

  const [caracteres,setCaracteres] = useState([
    "","","","","","","","","","","","","","","","","","","","","","","","","","","",
    "","","","","","","","","","","","","","","","","","","","","","","","","","","",
  ])
  const setNewValue = ({index,value})=>{
    let newValues = caracteres.filter(el=>true);
    newValues[index] = value;
    setCaracteres(newValues.concat());
  }
  const getCaracteresByLinea = (line)=>{
    let full_string = "";
    if(line===1){
      for (let i = 0; i < 27; i++) {
        const caracter = caracteres[i];
        if(caracter){
          full_string += caracter;
        }else{
          full_string += " ";
        }
      }
    }else{
      for (let i = 27; i < caracteres.length; i++) {
        const caracter = caracteres[i];
        if(caracter){
          full_string += caracter;
        }else{
          full_string += " ";
        }
      }
    }
    return `'${full_string}'` ;
  }
  //const product = props.location.search.id;
  //const product = {label:"Marcarilla N95",id:"0000001",costo:"45.00",P_Imagen:"https://mascarillasn95.eu/wp-content/uploads/2020/03/mascarilla-n95-trans-square.png"}
  function onChange(e){
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
       setQuantity(e.target.value)
    }
  }
  const addCart=(prod)=>{
    let element = producto;
    let quant = prod? prod.quantity : quantity;
    if(prod){
      element = prod;
    }
    if (parseInt(quant)>0) {
      if(getPriceProduct(element)===0){
        toast.error("Este producto no está disponible en su catálogo de precios.");
      }else{
        if( element.esGrabado ){
          let checkCaracteres = caracteres.filter(el=>el!==""&&el!==" ");
          if(checkCaracteres.length>0){
            if(car.length===0){
              addCartItem({
                id:element.P_Id,
                imagen:element.P_Imagen,
                quantity:parseInt(quant),
                promos:null,
                dimensions:{
                  largo:element.largo,
                  ancho:element.ancho,
                  alto:element.alto,
                  peso:element.peso
                },
                prices:{
                  pba:element.pba,
                  ivama:element.ivama,
                  pbb:element.pbb,
                  ivamb:element.ivamb,
                  pbc:element.pbc,
                  ivamc:element.ivamc
                },
                props:{
                  linea1: element.esGrabado ? getCaracteresByLinea(1):null,
                  linea2: element.esGrabado ? getCaracteresByLinea(2):null
                },
                ...element
              })
              toast.success(producto.label + " se ha añadido al carrito exitosamente!");
            }else{
              toast.error("Lo sentimos, este producto debe generarse en un pedido independiente, limpie el carrito para continuar.")
            }
          }else{
            toast.error("Por favor llene los caracteres especiales que contiene el producto para continuar.");
          }
        }else{
          if( parseInt( element.existencias ) >= parseInt( quant ) ){
            addCartItem({
              id:element.P_Id,
              imagen:element.P_Imagen,
              quantity:parseInt(quant),
              promos:null,
              dimensions:{
                largo:element.largo,
                ancho:element.ancho,
                alto:element.alto,
                peso:element.peso
              },
              prices:{
                pba:element.pba,
                ivama:element.ivama,
                pbb:element.pbb,
                ivamb:element.ivamb,
                pbc:element.pbc,
                ivamc:element.ivamc
              },
              props:{
                linea1: element.esGrabado ? getCaracteresByLinea(1):null,
                linea2: element.esGrabado ? getCaracteresByLinea(2):null
              },
              ...element
            })
            toast.success(producto.label + " se ha añadido al carrito exitosamente!");
          }else{
            handleToggleSoldOut(true)
          }

        }
      }
    }else{
      toast.error("Por favor especifique una cantidad");
    }
  }
  const ROUND_NUMBER = (number)=>Math.round(number*100)/100;
  const getPriceProduct = (element)=>{
    let {pba,pbb,pbc,ivama,ivamb,ivamc} = element;
    let price;
    //console.log({tipo_cliente,pba,pbb,pbc,ivama,ivamb,ivamc})
    switch (parseInt(tipo_cliente)) {
      case 2:
          if(pbb){
            price = ROUND_NUMBER( ( ROUND_NUMBER(pbb)+ROUND_NUMBER(ivamb) ) );
          }else{
            price = 0;
          }
        break;
      case 3:
          if(pbc){
            price = ROUND_NUMBER( ( ROUND_NUMBER(pbc)+ROUND_NUMBER(ivamc) ) );
          }else{
            price = 0;
          }
        break;
      default:
          if(pba){
            price = ROUND_NUMBER( ( ROUND_NUMBER(pba)+ROUND_NUMBER(ivama) ) );
          }else{
            price = 0;
          }
        break;
    }
    return price;
  }
  const consultProductInfo = async()=>{
    if(product){
      setIsLoading(true);
      let r = await axios.post(Const.backend,{type:"productInfo",product:product,token:localStorage.conection||null}).catch(e=>{
        toast.error("Error al conectar al servidor");
        console.log(e);
        setIsLoading(false);
      })
      if(r){
        if(r.data.error){
          toast.error(r.data.error);
          console.log(r.data.datos);
        }else{
          setProducto(r.data.producto);
          setRelations(r.data.relations);
          setTexts(r.data.texts);
          if(r.data.client){
            setTipo_cliente(r.data.client.Clasificacion);
          }
        }
        setTimeout(()=>setIsLoading(false),500);
      }
    }
  }
  useEffect(()=>{

    consultProductInfo()
  },[product])
  if(isLoading){
    return(
      <div className="backgroundProfesional">
        <div style={{width:"100%",marginTop:10}} className="d-flex justify-content-center">
              <Loader
                type="Oval"
                color={"#fff"}
                height={150}
                width={150}
                visible={true}
              />
        </div>
      </div>
    )
  }
  if(producto){
    return <ProductView
    product={producto}
    quantity={quantity}
    onChange={onChange}
    addCart={addCart}
    clienteTag={clienteTag}
    setClienteTag={setClienteTag}
    telefono={telefono}
    setTelefono={setTelefono}
    relations={relations}
    texts={texts}
    history={history}
    refresh={consultProductInfo}
    getPrice={getPriceProduct}
    caracteres={caracteres}
    setNewValue={setNewValue}
    modalSoldOut={modalSoldOut}
    handleToggleSoldOut={handleToggleSoldOut}
    loadBtn={btnSoldOut}
    createNotification={createNotification}
    />
  }
  return(
    <div className="d-flex justify-content-center p-5">
      <p style={{fontSize:25,fontWeight:"bold"}}>
        Producto no identificado
      </p>
    </div>
  )
}
//TO DO funcion isMobile pendiente para el marginTop
export default withRouter(Product);
/*
(1*5)+(1*5)+(1*5) = 15
(3)+(3)+(3) = 9

*/
