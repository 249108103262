import React,{useState} from 'react';
import { Const } from '../../../../Utils/Const';
import Axios from "axios";
const ModalLocation = React.createContext();
export const ModalLocationProvider = (props)=>{
    const {guia,visible,handleVisible} = props;
    const [location,setLocation] = useState(null);
    const [error,setError] = useState(null);
    const [load,setLoad] = useState(false);
    const handleLoadLocation = (arg)=>setLoad(arg);

    const getLocationByGuia = async()=>{
        handleLoadLocation(true);
        let data = {
          type:"getLocationByGuia",
          guia
        }
        let r = await Axios.post(Const.backend,data).catch(e=>{
          alert("Error al conectar al servidor");
        })
        if(r){
          if(r.data.error){
            setError(r.data.error);
          }else{
            console.log("Se seteo Location con:",r.data.result);
            setLocation(r.data.result)
          }
        }
        handleLoadLocation(false);
    }
    React.useEffect(()=>{
        if(guia){
            getLocationByGuia()
        }
    },[guia])

    const value = React.useMemo(()=>{
        return({
            guia,
            location,
            load,
            visible,
            handleVisible,
            error
        })
    },[guia,visible,load,error])
    return <ModalLocation.Provider value={value} {...props}/>
}
export const useModalLocation = ()=> React.useContext(ModalLocation);