import React from "react";
import { Row,Col,Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { ValidateProvider, useValidate } from "./Store";
import Loader from 'react-loader-spinner';
import ErrorContainer from "../../All/ErrorContainer";
import Content from "./Content";
import Divider from "../../All/Divider";
const FastBuy = (props)=>{
    const {toast,history} = props;
    return(
        <div className="backgroundProfesional">
            <Container>
                <Row>
                    <Col xs="12">
                        <p className="title-profile">
                            Validación
                        </p>
                        <Divider />
                        <ValidateProvider history={history} toast={toast}>
                            <ValidateTunnel />
                        </ValidateProvider>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
const ValidateTunnel = ()=>{
    const {history,screenError,screenLoad,screenData} = useValidate();
    if(screenError){
        return <ErrorContainer error={screenError} callback={{execute:()=>history.goBack(),label:"Regresar"}} />
    }
    if(screenData){
        return <Content />
    }
    if(screenLoad||(!screenData&&!screenError)){
        return(
            <div className="d-flex justify-content-center mt-5">
                <Loader
                    type="Oval"
                    color={"#fff"}
                    height={150}
                    width={150}
                    visible={true}
                />
            </div>
        )

    }
    return null
}
export default withRouter(FastBuy);