import React,{Fragment} from "react";
import { Col, Row, Container } from "react-bootstrap";
import PropsViewer from "./PropsViewer";
import SimpleImageSlider from "react-simple-image-slider";
import { useCart } from "../../../../Context/ContextCart";
import { useCartScreen } from "../Context";
import {imageDoNotExist} from "../../../../Utils/Const";
import {number_format} from "locutus/php/strings";
import {FaRegWindowClose} from "react-icons/fa";
import { AiOutlinePlusSquare, AiOutlineMinusSquare } from "react-icons/ai";
import FadeIn from 'react-fade-in';

const LeftSide = ()=>{
    const {car,removeCartItem,changeQuanity} = useCart();
    const {getPrice,getPromo} = useCartScreen();
    return(
        <Fragment>
            <div className="d-flex justify-content-start">
                <p style={{color:"#fff"}}>Mis productos</p>
            </div>
            <div className="productContainer" style={{maxHeight:"80vh",width:"100%",paddingRight:10}}>
                {
                    car.map((item,index)=>(
                        <FadeIn  key={index}>
                        <div className="mt-1">
                            <div className="d-flex justify-content-end">
                                <div style={{display:"flex",flexDirection:"row"}}>
                                    <FaRegWindowClose 
                                        style={{
                                            color:"#fff",marginRight:10,fontSize:17
                                        }} 
                                        onClick={()=>removeCartItem(index)} 
                                    />
                                    <AiOutlinePlusSquare 
                                        onClick={()=>changeQuanity(index,item.quantity+1)} 
                                        style={{
                                            color:"#fff",marginRight:10,fontSize:20,marginTop:-1
                                        }} 
                                    />
                                    <AiOutlineMinusSquare 
                                        onClick={()=>changeQuanity(index,item.quantity-1)} 
                                        style={{
                                            color:"#fff",marginRight:10,fontSize:20,marginTop:-1
                                        }} 
                                    />
                                </div>
                            </div>
                            <Container>
                                <Row>
                                    <Col xs="12" md="3" lg="3">
                                        <div className="p-1 d-flex justify-content-center">
                                            {
                                                item.images.length > 0 ? 
                                                <SimpleImageSlider
                                                    width={150}
                                                    height={150}
                                                    showNavs={true}
                                                    images={item.images}
                                                /> : 
                                                <img 
                                                    src={imageDoNotExist} 
                                                    style={{
                                                        width:"auto",borderRadius:15,height:150
                                                    }} 
                                                    alt="Product"
                                                />
                                            }
                                        </div>
                                    </Col>
                                    <Col xs="12" md="9" lg="9">
                                        <Row>
                                            <Col xs="12" align="left">
                                                <p style={{color:"#fff"}}>
                                                    <b>{item.label}</b>
                                                </p>
                                            </Col>
                                            <Col xs="4" align="left" className={!getPromo(item)&&!item.props.linea1?"mt-4":""}>
                                                {
                                                    getPromo(item).length>0 ? 
                                                        <Fragment>
                                                            <span onClick={()=>getPromo(item)} style={{color:"#fff"}}>
                                                                Tipo de oferta:
                                                            </span><br />
                                                        </Fragment> : null
                                                }
                                                <span onClick={()=>console.log(item)} style={{color:"#fff"}}>
                                                    Cantidad:
                                                </span><br />
                                                <span style={{color:"#fff"}}>
                                                    Precio:
                                                </span><br />
                                                <span style={{color:"#fff"}}>
                                                    Iva:
                                                </span><br />
                                                <span style={{color:"#fff"}}>
                                                    Subtotal:
                                                </span><br />
                                            </Col>
                                            <Col xs="8" align="right" className={!getPromo(item)&&!item.props.linea1?"mt-4":""}>
                                                {
                                                    getPromo(item).length>0 ? 
                                                        <Fragment>
                                                            <span style={{color:"#fff",fontSize:8}}>
                                                                {getPromo(item).map(((prom,index)=>`${prom.label}${getPromo(item)[index+1]?", ":""}`))}
                                                            </span><br />
                                                        </Fragment> : null
                                                }
                                                <span style={{color:"#fff"}}>
                                                    <b>{item.quantity}</b>
                                                </span><br />
                                                {
                                                    getPromo(item)&&getPromo(item).filter(el=>el.idType!==2).length>0  ? 
                                                    <Fragment>
                                                        {
                                                            getPromo(item).filter(el=>el.idType!==2).map((el,indexEl)=>(
                                                                <span key={indexEl} onClick={()=>console.log(getPromo(item))} style={{color:"red",textDecoration: 'line-through',marginLeft:10}}>
                                                                    $ {number_format(getPrice(item,indexEl===0?{realPrice:true,dontIncludeIva:true,moduleName:"product-old-price-red"}:{showOldPrice:true,dontIncludeIva:true,moduleName:"product-old-price-red"}),2)}
                                                                </span>
                                                            ))
                                                        }
                                                        <span style={{color:"#fff",marginLeft:10}}>
                                                            $ {number_format(getPrice(item,{toFix:false,dontIncludeIva:true,moduleName:"product-new-price"}),2)}
                                                        </span>
                                                    </Fragment> : 
                                                    <span style={{color:"#fff"}}>
                                                        <b>
                                                            $ {number_format(getPrice(item,{toFix:false,dontIncludeIva:true,moduleName:"product-normal-price"}),2)}
                                                        </b>
                                                    </span>
                                                }
                                                <br />
                                                <span style={{color:"#fff"}}>
                                                    <b>$ {number_format(getPrice(item,{toFix:false,dontIncludeIva:false,moduleName:"product-iva-izq"})-getPrice(item,{toFix:false,dontIncludeIva:true,moduleName:"product-iva-derecha"}),2)} </b>
                                                </span>
                                                <br />
                                                <span style={{color:"#fff"}}>
                                                    <b>$ {number_format(getPrice(item,{quant:true,toFix:true,moduleName:"product-subtotal"}),2)} </b>
                                                </span>
                                            </Col>
                                            <Col xs="12" className="mt-2">
                                            {
                                                (item.props.linea1||item.props.linea2) ?
                                                <PropsViewer 
                                                    linea1={item.props.linea1}
                                                    linea2={item.props.linea2}
                                                /> : null
                                            }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        </FadeIn>
                    ))
                }
            </div>
        </Fragment>
    )
}
export default LeftSide;