import React, { Fragment,useState,useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {withRouter} from "react-router-dom";
import {Const} from "../../../Utils/Const";
import axios from "axios";
import Divider from "../All/Divider";
import Load from "../All/Loader";
import FadeIn from "react-fade-in";
import {number_format} from "locutus/php/strings";

const MyShopping = (props)=>{
  const {history,location,toast,user} = props;
  const [isLoading,setIsLoading] = useState(false);
  const [orders,setOrders] = useState("");
  const [errorMsg,setErrorMsg] = useState("");

  const searchOrders = async(props)=>{
    if(localStorage.conection){
      setIsLoading(true);
      let r = await axios.post(Const.backend,{type:"MyOrders",token:localStorage.conection}).catch(e=>{
        setErrorMsg("Error al conectar al servidor");
        console.log(e);
        setIsLoading(false);
      })
      if(r){
        if(r.data.error){
          setOrders(null)
          setErrorMsg(r.data.error);
          console.log(r.data.datos);
        }else{
          setOrders(r.data.orders);
        }
        setIsLoading(false);
      }
    }else{
      setErrorMsg("Inicie sesión para continuar.")
      setOrders(null);
    }
  }
  const returnFecha = (f)=>{
    if(f){
      let arrayFecha = f.split("T");
      let arrayFecha2 =  arrayFecha[0].split("-");
      return arrayFecha2[0]+"/"+arrayFecha2[1]+"/"+arrayFecha2[2];
    }else{
      return "Error al consultar la fecha"
    }
  }
  const returnColorStatus = (v)=>{
    let status = parseInt(v);
    switch (status) {
      case 10:
        return "green";
        break;
      case 7:
        return "red";
        break;
      default:
        return "cyan";
    }
  }
  useEffect(()=>{
    searchOrders();
  },[])
  return(
    <Fragment>
      <Load isVisible={isLoading} />
      <div className="backgroundProfesional">
        <Container style={{paddingTop:20}}>
          {
            orders===""?
            <Fragment>
                <div style={{width:"100%",alignItems:"center"}}>
                  <p style={{fontSize:20,fontWeight:"bold",color:"#fff",textAlign:"center"}}>
                    ...
                  </p>
                </div>
            </Fragment> : orders===null ?
              <Fragment>
                <div style={{width:"100%",alignItems:"center"}}>
                  <p style={{fontSize:20,color:"#fff",textAlign:"center"}}>
                    {errorMsg}
                  </p>
                </div>
              </Fragment> :
              <FadeIn>
                <div className="table-responsive" style={{width:"100%",maxHeight:600,overflow:"auto"}}>
                    <table style={{width:"100%"}}>
                      <thead style={{borderBottom:"3px solid white"}}>
                        <tr>
                          <th style={{width:"20%",color:"#fff"}}>
                            Pedido
                          </th>
                          <th style={{width:"20%",color:"#fff"}}>
                            Fecha de compra
                          </th>
                          <th style={{width:"20%",color:"#fff"}}>
                            Estatus
                          </th>
                          <th style={{width:"20%",color:"#fff"}}>
                            Costo total
                          </th>
                          <th style={{width:"20%",color:"#fff"}}></th>
                        </tr>
                      </thead>
                      <tbody style={{paddingTop:15,paddingBottom:15}}>
                        {
                          orders.length>0?
                            orders.map((item,index)=>(
                              <tr key={index} style={{marginTop:index>0?0:15,marginBottom:((index+1)===orders.length)?15:0}}>
                                <td style={{color:"#fff",width:"20%"}}>
                                  {item.folio}
                                </td>
                                <td style={{color:"#fff",width:"20%"}}>
                                  {returnFecha(item.fecha_pedido)}
                                </td>
                                <td style={{color:returnColorStatus(item.estatus_pedido),width:"20%"}}>
                                  {item.estatus}
                                </td>
                                <td style={{color:"#fff",width:"20%"}}>
                                  {"$ "+number_format(item.total,2)}
                                </td>
                                <td style={{color:"#fff",width:"20%",textAlign:"center"}}>
                                  <p onClick={()=>history.push({
                                    pathname: "/myorder",
                                    search: '?folio='+item.folio
                                  })} style={{textDecorationLine: 'underline',cursor:"pointer"}}>
                                    Ver detalles del pedido
                                  </p>
                                </td>
                              </tr>
                            )) :
                            <tr align="center" style={{color:"#fff"}}>
                              <td colSpan="5">"Aun no tienes compras.."</td>
                            </tr>
                        }
                      </tbody>
                    </table>
                </div>
                <Divider />
              </FadeIn>
          }
        </Container>
      </div>
    </Fragment>
  )
}
/*
<Row>
  <Col sm="">
  </Col>
  <Divider />
    <p onClick={()=>console.log(orders)} style={{fontSize:20,color:"#fff",textAlign:"center"}}>
      {"Todo bien"}
    </p>
</Row>

 */
export default withRouter(MyShopping);
