import React, { useState, Fragment } from "react";
import {useOrder} from "./Context";
import {Row,Col,Container} from "react-bootstrap";
import Divider from "../All/Divider";
import {Const, imageDoNotExist} from "../../../Utils/Const";
import FadeIn from 'react-fade-in';
import SimpleImageSlider from "react-simple-image-slider";
import {number_format} from "locutus/php/strings";
import Axios from "axios";
import ModalLocation from "./ModalLocation";


const Content = (props)=>{
  const {order,searchProducts,isSearching,banner} = useOrder();
  const [modalLocation,setModalLocation] = useState(false);
  const handleClose = ()=>setModalLocation(false);
  const handleOpen = ()=>setModalLocation(true);
  const handleLoadLocation = (param)=>setModalLocation(param);
  const {products} = order;
  const returnFecha = (f)=>{
    let arrayFecha = f.split("T");
    let arrayFecha2 =  arrayFecha[0].split("-");
    return arrayFecha2[0]+"/"+arrayFecha2[1]+"/"+arrayFecha2[2];
  }
  
  return(
    <Fragment>
      <div className="backgroundProfesional">
        <Container>
          <FadeIn>
            <Row style={{marginTop:20}}>
              <Col sm="12">
                <span style={{fontWeight:"bold",fontSize:20,color:"#fff"}}>DETALLES DEL PEDIDO</span>
              </Col>
              <Col sm="12" className="p-3">
                <span style={{fontSize:15,color:"#fff"}}>Orden de compra: <span style={{fontWeight:"bold"}}>{order.folio}</span></span><br></br>
                <span style={{fontSize:15,color:"#fff"}}>Fecha de compra: <span style={{fontWeight:"bold"}}>{returnFecha(order.fecha)}</span></span><br></br>
                <span style={{fontSize:15,color:"#fff"}}>Total de productos: <span style={{fontWeight:"bold"}}>{"$ "+number_format(order.total-order.costoEntrega,2)}</span></span><br></br>
                <span style={{fontSize:15,color:"#fff"}}>Total de compra: <span style={{fontWeight:"bold"}}>{"$ "+number_format(order.total,2)}</span></span>
              </Col>
              <Col sm="12">
                <span style={{fontWeight:"bold",fontSize:20,color:"#fff"}}>INFORMACIÓN DE ENVIO</span>
              </Col>
              <Col sm="12" className="p-3">
                <span style={{fontSize:15,color:"#fff"}}>Destino: <span style={{fontWeight:"bold"}}>{order.destino}</span></span><br></br>
                <span style={{fontSize:15,color:"#fff"}}>Costo de envío: <span style={{fontWeight:"bold"}}>$ {number_format(order.costoEntrega,2)}</span></span><br></br>
              </Col>
              {
                parseInt(order.estatus_pedido)===7 ? 
                <Col sm="12" className="p-3">
                  <p style={{fontSize:25,fontWeight:"bold",color:"#fff",marginTop:15}}>
                    ¡Pendiente de pago!
                  </p>
                  <p style={{fontSize:15,fontWeight:"bold",color:"#fff",marginTop:15}}>
                    Por favor concluye el pago con el siguiente número de referencia: " 
                      <span style={{color:"#00cd60"}}>
                        {order.tienda_referencia_pago}
                      </span>
                    "
                  </p>
                  <Banner banner={banner} />
                </Col> : null
              }
              
              <div style={{width:"100%",marginTop:20}}>
                <Divider />
              </div>
              <Col sm="12" style={{marginTop:20}}>
                <span style={{fontWeight:"bold",fontSize:20,color:"#fff"}}>INFORMACIÓN DE PRODUCTOS</span>
              </Col>
              <Col sm="12" style={{padding:30}}>
                {
                  products.map((item,index)=>(
                      item.id !== 379 ? 
                    <Row key={index} style={{marginTop:10}}>
                      <Col sm="3">
                        {
                          item.images.length>0?
                          <SimpleImageSlider
                            width={250}
                            height={250}
                            showNavs={true}
                            images={item.images}
                          /> : 
                          <img src={imageDoNotExist} style={{width:"auto",borderRadius:15,width:"100%",background:"#fff"}} alt="Product"/>
                        }
                      </Col>
                      <Col sm="9">
                        <Divider />
                        <span style={{fontSize:25,color:"#fff"}}><span style={{fontWeight:"bold"}}>{item.des_prod}</span></span><br></br>
                        <span style={{fontSize:20,color:"#fff"}}>Cantidad: <span style={{fontWeight:"bold"}}>{parseInt(item.can_prod)}</span></span><br></br>
                        <span style={{fontSize:20,color:"#fff"}}>Precio: <span style={{fontWeight:"bold"}}>{"$ "+number_format(item.pre_prod,2)}</span></span><br></br>
                        <span style={{fontSize:20,color:"#fff"}}>Total: <span style={{fontWeight:"bold"}}>{"$ "+number_format(item.tot_prod,2)}</span></span><br></br>
                      </Col>
                    </Row> : null
                  ))
                }
              </Col>
              <Divider />
              <Col sm="12" className="p-3" style={{display:"flex",flexDirection:"row"}}>
                <button onClick={searchProducts} className="btn btn-dark" disabled={isSearching} style={{borderRadius:20,borderStyle:"solid",borderColor:"#fff",backgroundColor:"#1a1a1a",borderWidth:2,fontWeight:"bold"}}>
                  {isSearching?"Un momento..":"Pedir de nuevo"}
                </button>
                <button onClick={handleOpen} className="btn btn-dark" style={{borderRadius:20,marginLeft:15,borderStyle:"solid",borderColor:"#fff",backgroundColor:"#1a1a1a",borderWidth:2,fontWeight:"bold"}}>
                  {"Seguir pedido"}
                </button>
              </Col>
            </Row>
          </FadeIn>
        </Container>
      </div>
      <ModalLocation handleVisible={handleClose} visible={modalLocation} guia={order.guia}/>
    </Fragment>
  )
}
const Banner = ({banner})=>{
  if(!banner){
      return null;
  }
  return(
      <div className="p-3">
          <img 
              src={banner}
              style={{
                  width:"100%",
                  objectFit:"contain",
                  borderRadius:10
              }}
          />
      </div>
  )
}
export default Content;
