import React, { useRef } from 'react';
const CepGrabContext = React.createContext();
export const CepGrabContextProvider = (props)=>{
    const {caracteres,setNewValue} = props;
    const ref1 = useRef();const ref2 = useRef();const ref3 = useRef();const ref4 = useRef();const ref5 = useRef();
    const ref6 = useRef();const ref7 = useRef();const ref8 = useRef();const ref9 = useRef();const ref10 = useRef();
    const ref11 = useRef();const ref12 = useRef();const ref13 = useRef();const ref14 = useRef();const ref15 = useRef();
    const ref16 = useRef();const ref17 = useRef();const ref18 = useRef();const ref19 = useRef();const ref20 = useRef();
    const ref21 = useRef();const ref22 = useRef();const ref23 = useRef();const ref24 = useRef();const ref25 = useRef();
    const ref26 = useRef();const ref27 = useRef();
    const ref28 = useRef();const ref29 = useRef();const ref30 = useRef();const ref31 = useRef();const ref32 = useRef();
    const ref33 = useRef();const ref34 = useRef();const ref35 = useRef();const ref36 = useRef();const ref37 = useRef();
    const ref38 = useRef();const ref39 = useRef();const ref40 = useRef();const ref41 = useRef();const ref42 = useRef();
    const ref43 = useRef();const ref44 = useRef();const ref45 = useRef();const ref46 = useRef();const ref47 = useRef();
    const ref48 = useRef();const ref49 = useRef();const ref50 = useRef();const ref51 = useRef();const ref52 = useRef();
    const ref53 = useRef();const ref54 = useRef();
    const changeToNext = (index)=>{
        switch (index) {
            case 0: ref2.current.focus(); break;
            case 1: ref3.current.focus(); break;
            case 2: ref4.current.focus(); break;
            case 3: ref5.current.focus(); break;
            case 4: ref6.current.focus(); break;
            case 5: ref7.current.focus(); break;
            case 6: ref8.current.focus(); break;
            case 7: ref9.current.focus(); break;
            case 8: ref10.current.focus(); break;
            case 9: ref11.current.focus(); break;
            case 10: ref12.current.focus(); break;
            case 11: ref13.current.focus(); break;
            case 12: ref14.current.focus(); break;
            case 13: ref15.current.focus(); break;
            case 14: ref16.current.focus(); break;
            case 15: ref17.current.focus(); break;
            case 16: ref18.current.focus(); break;
            case 17: ref19.current.focus(); break;
            case 18: ref20.current.focus(); break;
            case 19: ref21.current.focus(); break;
            case 20: ref22.current.focus(); break;
            case 21: ref23.current.focus(); break;
            case 22: ref24.current.focus(); break;
            case 23: ref25.current.focus(); break;
            case 24: ref26.current.focus(); break;
            case 25: ref27.current.focus(); break;
            case 26: ref28.current.focus(); break;
            case 27: ref29.current.focus(); break;
            case 28: ref30.current.focus(); break;
            case 29: ref31.current.focus(); break;
            case 30: ref32.current.focus(); break;
            case 31: ref33.current.focus(); break;
            case 32: ref34.current.focus(); break;
            case 33: ref35.current.focus(); break;
            case 34: ref36.current.focus(); break;
            case 35: ref37.current.focus(); break;
            case 36: ref38.current.focus(); break;
            case 37: ref39.current.focus(); break;
            case 38: ref40.current.focus(); break;
            case 39: ref41.current.focus(); break;
            case 40: ref42.current.focus(); break;
            case 41: ref43.current.focus(); break;
            case 42: ref44.current.focus(); break;
            case 43: ref45.current.focus(); break;
            case 44: ref46.current.focus(); break;
            case 45: ref47.current.focus(); break;
            case 46: ref48.current.focus(); break;
            case 47: ref49.current.focus(); break;
            case 48: ref50.current.focus(); break;
            case 49: ref51.current.focus(); break;
            case 50: ref52.current.focus(); break;
            case 51: ref53.current.focus(); break;
            default: ref54.current.focus(); break;
        }
    }
    const changeToPrev = (index)=>{
        switch (index) {
            case 0: ref1.current.focus(); break;
            case 1: ref1.current.focus(); break;
            case 2: ref2.current.focus(); break;
            case 3: ref3.current.focus(); break;
            case 4: ref4.current.focus(); break;
            case 5: ref5.current.focus(); break;
            case 6: ref6.current.focus(); break;
            case 7: ref7.current.focus(); break;
            case 8: ref8.current.focus(); break;
            case 9: ref9.current.focus(); break;
            case 10: ref10.current.focus(); break;
            case 11: ref11.current.focus(); break;
            case 12: ref12.current.focus(); break;
            case 13: ref13.current.focus(); break;
            case 14: ref14.current.focus(); break;
            case 15: ref15.current.focus(); break;
            case 16: ref16.current.focus(); break;
            case 17: ref17.current.focus(); break;
            case 18: ref18.current.focus(); break;
            case 19: ref19.current.focus(); break;
            case 20: ref20.current.focus(); break;
            case 21: ref21.current.focus(); break;
            case 22: ref22.current.focus(); break;
            case 23: ref23.current.focus(); break;
            case 24: ref24.current.focus(); break;
            case 25: ref25.current.focus(); break;
            case 26: ref26.current.focus(); break;
            case 27: ref27.current.focus(); break;
            case 28: ref28.current.focus(); break;
            case 29: ref29.current.focus(); break;
            case 30: ref30.current.focus(); break;
            case 31: ref31.current.focus(); break;
            case 32: ref32.current.focus(); break;
            case 33: ref33.current.focus(); break;
            case 34: ref34.current.focus(); break;
            case 35: ref35.current.focus(); break;
            case 36: ref36.current.focus(); break;
            case 37: ref37.current.focus(); break;
            case 38: ref38.current.focus(); break;
            case 39: ref39.current.focus(); break;
            case 40: ref40.current.focus(); break;
            case 41: ref41.current.focus(); break;
            case 42: ref42.current.focus(); break;
            case 43: ref43.current.focus(); break;
            case 44: ref44.current.focus(); break;
            case 45: ref45.current.focus(); break;
            case 46: ref46.current.focus(); break;
            case 47: ref47.current.focus(); break;
            case 48: ref48.current.focus(); break;
            case 49: ref49.current.focus(); break;
            case 50: ref50.current.focus(); break;
            case 51: ref51.current.focus(); break;
            case 52: ref52.current.focus(); break;
            default: ref53.current.focus(); break;
        }
    }
    const setValue = ({value,index})=>{
        setNewValue({
            index,
            value
        })
    }
    const value = React.useMemo(()=>{
        return({
            caracteres,
            setValue,
            changeToPrev,
            changeToNext,
            ref1,ref2,ref3,ref4,ref5,ref6,ref7,ref8,ref9,ref10,ref11,ref12,ref13,ref14,ref15,ref16,ref17,ref18,ref19,ref20,ref21,ref22,ref23,ref24,ref25,ref26,ref27,
            ref28,ref29,ref30,ref31,ref32,ref33,ref34,ref35,ref36,ref37,ref38,ref39,ref40,ref41,ref42,ref43,ref44,ref45,ref46,ref47,ref48,ref49,ref50,ref51,ref52,ref53,ref54
        })
    },[
        caracteres,
        ref1,ref2,ref3,ref4,ref5,ref6,ref7,ref8,ref9,ref10,ref11,ref12,ref13,ref14,ref15,ref16,ref17,ref18,ref19,ref20,ref21,ref22,ref23,ref24,ref25,ref26,ref27,
        ref28,ref29,ref30,ref31,ref32,ref33,ref34,ref35,ref36,ref37,ref38,ref39,ref40,ref41,ref42,ref43,ref44,ref45,ref46,ref47,ref48,ref49,ref50,ref51,ref52,ref53,ref54
    ])
    return <CepGrabContext.Provider value={value} {...props}/>
}
export const useCepGrabContext = ()=> React.useContext(CepGrabContext);