import React,{useEffect,useState} from "react";
import { withRouter } from 'react-router-dom';
import axios from "axios";
import {Const} from "../../../Utils/Const";
import Load from "../All/Loader";
//import queryString from 'query-string';

const ActivateAccount = (props)=>{
  const {history} = props;
  const [validado,setValidado] = useState(false);
  useEffect(() => {
    (async()=>{
      let error = null;
      let getParams = history.location.search;
      let arrayTkn = getParams.split("=");
      let response = await axios.post(Const.backend,{type:"activateAccount",tkn:arrayTkn[1]}).catch((e)=>error=e);
      if (error) {
        console.log(error);
      }else{
        setValidado(true);
      }
    })();
  },[]);
  return(
    <div className="alineado">
      {(validado) ? <><h3>Su correo electrónico ha sido verificado correctamente..</h3><button className="btn btn-dark" onClick={()=>history.push("/")} style={{width:200}}>Regresar</button></> : <h3>Validando..</h3>}
    </div>
  )
}
export default withRouter(ActivateAccount);
