import React,{Component} from "react";
import "../watchVerify.css";
import ReactInterval from 'react-interval';
import {OverlayTrigger,Tooltip} from "react-bootstrap";
import {idioms} from "../../Utils/Idioms";
import axios from "axios";
import {isMobile,Const,mysqlTimeStampToDate} from "../../Utils/Const";
class TimerOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      timeout:1000,
      enabled:true,
      minutes:null,
      seconds:null
    };
  }
  sendEmail = async()=>{
    let enviaCorreo = await axios.post(Const.backend,{type:"sendEmailVerification",dest:this.props.usr.user.email,token:this.props.id});
    if (enviaCorreo.data.error) {
      this.props.toast.error("Error al enviar el correo electrónico");
    }else{
      this.props.toast.info("Correo de Verificación enviado a: "+this.props.usr.user.email);
    }
  }
  consultVerified = async(time_out)=>{
    let isVerified = await axios.post(Const.backend,{type:"consultVerified",token:this.props.id});
    if (isVerified.data.error) {
      this.props.toast.error(isVerified.error);
    }else{
      if (isVerified.data.user.verified===1) {
        this.props.usr.setUser(isVerified.user);
        this.props.toast.success("Correo electrónico verificado correctamente");
      }else{
        if (time_out===true) {
          localStorage.clear();
          this.props.usr.setUser(null);
          this.props.toast.error("Por favor verifique su correo electrónico para continuar navegando");
        }
      }
    }
  }
  relog = ()=>{
    let fecha = new Date(mysqlTimeStampToDate(this.props.register));
    let maxDiff = this.props.maxTimeMin*60*1000;
    let diff = Math.abs(fecha - new Date());
    if (diff>maxDiff) {
      if (this.state.count<1) {
        this.setState({seconds:"00",minutes:"00",enabled:false})
        this.consultVerified(true);
      }
      this.setState({count:this.state.count+1})
    }else{
      if (this.state.minutes&&this.state.seconds) {
        let segundos = parseInt(this.state.seconds);
        let minutos = parseInt(this.state.minutes);
        segundos = segundos-1;
        if (segundos<1) {
          minutos--;
          segundos = 59;
        }
        if (minutos<0&&segundos===59) {
          this.setState({seconds:"00",minutes:"00"})
        }else{
          if (segundos===0||segundos%10===0) {
            this.consultVerified(false);
          }
          if(segundos<10){segundos = "0"+segundos}
          if(minutos<10){minutos = "0"+minutos}
          this.setState({seconds:segundos,minutes:minutos});
        }
      }else{
        let diffInMinutes= this.props.maxTimeMin-(diff/1000/60);
        var array = diffInMinutes.toString().split(".");
        let restoInMinutes = parseFloat(parseFloat("."+array[1]).toFixed(2));
        let diffInSeconds = restoInMinutes*60;
        let primeros_minutos = parseInt(diffInMinutes);
        let primeros_segundos = parseInt(diffInSeconds);
        if(primeros_minutos<10){primeros_minutos="0"+primeros_minutos}
        if(primeros_segundos<10){primeros_segundos="0"+primeros_segundos}
        this.setState({minutes:primeros_minutos,seconds:primeros_segundos})
      }
    }
  }
  render(){
    const {timeout,enabled} = this.state;
    return(
      <div>
        <OverlayTrigger placement="right" overlay={<Tooltip id={`tooltip-${"down"}`}><strong>{this.props.tooltip}</strong></Tooltip>}>
          <div className="watch" onClick={()=>this.sendEmail()}>
            <span>{this.props.msg}</span>
            <span>{this.state.minutes}:{this.state.seconds}</span>
            <ReactInterval {...{timeout, enabled}} callback={() => this.relog()} />
          </div>
        </OverlayTrigger>
      </div>
    )
  }
}
export default TimerOut;
