import React,{useState,Fragment,useEffect} from "react";
import {Navbar,Nav,NavDropdown,Form,FormControl,Button,Container,Row,Col,Dropdown,DropdownButton} from "react-bootstrap"
import {Link,withRouter,useHistory,useLocation} from "react-router-dom";
import {FaSearch,FaBuilding,FaUserCircle} from "react-icons/fa";
import { LinkContainer } from 'react-router-bootstrap';
import Cart from "./Cart/OldCartScreen/Cart";
import {useCart} from "../../Context/ContextCart";
import NavigationBar from "./NavigationBar";

function NavbarMenu(props){
  const location = useLocation();
  const {user,setUser,idioms,idioma,locate,toast,MenuItem,_categories,history} = props;
  const [categories,setCategories] = useState(_categories);
  const [needhref,setneedhref] = useState(false)
  const { car, removeCartItem } = useCart();
  const [isVisible,setIsVisible] = useState(false);
  const logoStyle = {
    width:"80%",
    cursor:"pointer",
    minHeight:"100%"
  }

  useEffect(()=>{
    let quant = location.pathname.split("/").length;
    if(quant>2){
      setneedhref(true)
    }else{
      setneedhref(false)
    }
  },[location.pathname])
  return(
    <Navbar expand="lg" style={{padding:0}}>
      <div style={{width:"100%",padding:0,paddingLeft:"10%",paddingRight:"10%"}}>
        <Navbar.Toggle aria-controls="basic-navbar-nav bg-dark" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Row style={{width:"100%"}}>
              <Col sm="12">
                <Row>
                  <Col sm="4" style={{display:"flex",flexDirection:"row",paddingTop:13}}>
                    <div style={{width:"30%"}} className="dropdown-profesional">
                      <div>
                          <Nav.Link style={{fontSize:15,color:"#000",textAlign:"center"}}>
                            {
                              needhref ? 
                              <span onClick={()=>window.location.href=`/findbycetegorie`}>
                                PRODUCTOS
                              </span> : 
                              <LinkContainer to="findbycetegorie" >
                                <span>
                                  PRODUCTOS
                                </span>
                              </LinkContainer>
                            }
                          </Nav.Link>
                        
                      </div>
                      <NavigationBar categories={categories} needhref={needhref} />
                    </div>
                    
                    <Nav.Link style={{fontSize:15,color:"#000",width:"1%",textAlign:"center"}}>
                          {
                            needhref ? 
                            <span onClick={()=>window.location.href=`/findbycetegorie`} >
                              |
                            </span> : 
                            <LinkContainer to="findbycetegorie" >
                              <span>
                              |
                              </span>
                            </LinkContainer>
                          }
                    </Nav.Link>
                    <Nav.Link style={{fontSize:15,color:"#000",width:"40%",textAlign:"center"}}>
                      {
                        needhref ? 
                        <span onClick={()=>window.location.href=`/categorie?id=17`} >
                          PRODUCTOS 7-12
                        </span> : 
                        <LinkContainer to="/categorie?id=17 " >
                          <span>
                          PRODUCTOS 7-12
                          </span>
                        </LinkContainer>
                      }
                    </Nav.Link>
                    <Nav.Link style={{fontSize:15,color:"#000",width:"1%",textAlign:"center"}}>
                      {
                        needhref ? 
                        <span onClick={()=>window.location.href=`/findbycetegorie`} >
                          |
                        </span> : 
                        <LinkContainer to="findbycetegorie" >
                          <span>
                          |
                          </span>
                        </LinkContainer>
                      }
                    </Nav.Link>
                    <Nav.Link style={{fontSize:15,color:"#000",width:"26%",textAlign:"center"}}>
                      {
                        needhref ? 
                        <span onClick={()=>window.location.href=`/`} >
                          AYUDA
                        </span> : 
                        <LinkContainer to="/" >
                          <span>
                          AYUDA
                          </span>
                        </LinkContainer>
                      }
                    </Nav.Link>
                  </Col>
                  <Col sm="4" className="d-flex justify-content-center">
                    <img src={process.env.PUBLIC_URL + '/img/logo_black.png'} onClick={()=>history.push("/")} style={logoStyle} alt="logo"/>
                  </Col>
                  <Col sm="4">
                    <Row>
                      <Col xs="12" md="10" style={{paddingTop:13}}>
                        <div style={{display:"flex",flexDirection:"row"}}>
                          <Nav.Link>
                            {
                              needhref ? 
                              <span style={{fontSize:13,color:"#000"}} onClick={()=>window.location.href=`/catalogue`} >
                                CATÁLOGO
                              </span> : 
                              <LinkContainer style={{color:"#000",fontSize:15}} to="catalogue">
                                <span style={{color:"#000"}}>
                                  CATÁLOGO
                                </span>
                              </LinkContainer>
                            }
                            
                          </Nav.Link>
                          <Nav.Link>
                            <span style={{color:"#000"}}>
                              |
                            </span>
                          </Nav.Link>
                          <Nav.Link>
                            {
                              needhref ? 
                              <span style={{fontSize:13,color:"#000"}} onClick={()=>window.location.href=`/contact`} >
                                CONTACTO
                              </span> : 
                              <LinkContainer style={{color:"#000",fontSize:15}} to="contact">
                                <span style={{color:"#000"}}>CONTACTO</span>
                              </LinkContainer>
                            }
                            
                          </Nav.Link>
                          <Nav.Link>
                            <span style={{color:"#000"}}>
                              |
                            </span>
                          </Nav.Link>
                          <NavDropdown style={{backgroundColor:"#fff"}} id="dropdown-basic-button1" title={<span style={{color:"#000",fontSize:15}}>{"MI CUENTA"}</span>}>
                            {
                              (user!==null)?
                              <Fragment>
                                {
                                  needhref ? 
                                    <NavDropdown.Item onClick={()=>window.location.href=`/profile`} eventKey="1">
                                      <span>
                                        Mi perfíl
                                      </span>
                                    </NavDropdown.Item> : 
                                    <LinkContainer to="profile">
                                      <NavDropdown.Item eventKey="1">
                                        <span>
                                          Mi perfíl
                                        </span>
                                      </NavDropdown.Item>
                                    </LinkContainer>
                                }
                                {
                                  needhref ? 
                                    <NavDropdown.Item onClick={()=>window.location.href=`/ubications`} eventKey="2">
                                      <span>
                                        Mis direcciones
                                      </span>
                                    </NavDropdown.Item> : 
                                    <LinkContainer to="ubications">
                                      <NavDropdown.Item eventKey="2">
                                          <span>
                                            Mis direcciones
                                          </span>
                                      </NavDropdown.Item>
                                    </LinkContainer>
                                }
                                {
                                  needhref ? 
                                    <NavDropdown.Item onClick={()=>window.location.href=`/myshopping`} eventKey="4">
                                      <span>
                                        Mis compras
                                      </span>
                                    </NavDropdown.Item> : 
                                    <LinkContainer to="myshopping">
                                      <NavDropdown.Item eventKey="4">
                                          <span>
                                            Mis compras
                                          </span>
                                      </NavDropdown.Item>
                                    </LinkContainer>
                                }
                                
                                {/*onClick={()=>{setUser(null);localStorage.clear();toast.info("Hasta pronto");history.push("/")}} */}
                                <NavDropdown.Item onClick={()=>{setUser(null);localStorage.clear();toast.info("Hasta pronto");history.push("/")}} eventKey="5">
                                  <span>
                                    Cerrar Sesión
                                  </span>
                                </NavDropdown.Item>
                              </Fragment>
                              :
                              <Fragment>
                                {
                                  needhref ? 
                                  <NavDropdown.Item onClick={()=>window.location.href=`/login`} eventKey="1">
                                      {"Iniciar Sesión"}
                                  </NavDropdown.Item> : 
                                    <LinkContainer to="login">
                                      <NavDropdown.Item eventKey="1">
                                          {"Iniciar Sesión"}
                                      </NavDropdown.Item>
                                    </LinkContainer>
                                }
                                {
                                  needhref ? 
                                    <NavDropdown.Item onClick={()=>window.location.href=`/register`} eventKey="1">
                                        {"Registrarme"}
                                    </NavDropdown.Item> : 
                                    <LinkContainer to="register">
                                      <NavDropdown.Item eventKey="2">
                                          {"Registrarme"}
                                      </NavDropdown.Item>
                                    </LinkContainer>
                                }
                              </Fragment>
                            }
                          </NavDropdown>
                        </div>
                      </Col>
                      <Col xs="12" md="2" style={{display:"flex",flexDirection:"row",paddingTop:10}}>
                        <Nav.Link>
                          {
                            needhref ? 
                              <FaSearch onClick={()=>window.location.href=`/search`} style={{fontSize:18,color:"#000"}}/> : 
                              <LinkContainer to="search" style={{fontSize:18,color:"#000"}}>
                                <FaSearch/>
                              </LinkContainer>
                          }
                          
                        </Nav.Link>
                        <Nav.Link>
                          <Cart car={car} toast={toast} removeCartItem={removeCartItem} needhref={needhref}/>
                        </Nav.Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}
export default withRouter(NavbarMenu);
/*
<input type="text" className="inputProfesionalNavbar" placeholder={idioms[idioma].views.Navbar.inputs.searchPlaceholder}/>

<Flag style={{width:30,marginRight:15}} code={locate} fallback={ <span>Unknown</span> }/>

                    <Row>
                      <Col sm="4">
                        <NavDropdown style={{backgroundColor:"#fff"}} id="dropdown-basic-button1" title={<span style={{color:"#000"}}>{"Campos"}</span>}>
                          <LinkContainer to="/">
                            <NavDropdown.Item eventKey="1">
                              <span>Odontología</span>
                            </NavDropdown.Item>
                          </LinkContainer>
                        </NavDropdown>
                      </Col>
                      <Col sm="4">
                        <Nav.Link>
                          <LinkContainer style={{color:"#000"}} to="weare">
                            <span style={{color:"#000"}}>Nosotros</span>
                          </LinkContainer>
                        </Nav.Link>
                      </Col>
                    </Row>
*/
