import React,{useState,useEffect} from "react";
import {Container,Row,Col} from "react-bootstrap";
import {isMobile} from "../../../Utils/Const";
import axios from "axios";
import {Const} from "../../../Utils/Const";
import Loader from 'react-loader-spinner';
import InputProfesional from "../All/InputProfesional";
import FadeIn from 'react-fade-in';


const Page = (props)=>{
 // const {idioms,idioma,user} = props;
  const [reload,setReload] = useState(true);
  const [ubications,setUbications] = useState(null);
  const [isLoading,setIsLoading] = useState(false);
  const [calle,setCalle] = useState("");
  const [numero,setNumero] = useState("");
  const [nombre,setNombre] = useState("");
  const [cp,setCp] = useState("");
  const [colonia,setColonia] = useState("");
  const [telefono,setTelefono] = useState("");
  const [isVisibleAdd,setIsVisibleAdd] = useState(false);
  const [objCp,setObjCp] = useState({ciudad:"",estado:"",pais:""})
  const [editingId,setEditingId] = useState(null);
  const consultUbications = async()=>{
    setIsLoading(true)
    let r = await axios.post(Const.backend,{type:"searchUbications",token:localStorage.conection}).catch(e=>{
      alert("Error al conectar al servidor");
      console.log(e);
      setIsLoading(false)
    })
    if(r){
      if(r.data.error){
        console.log(r.data.error);
      }else{
        setUbications(r.data.results);
      }
      setIsLoading(false)
    }
  }
  const makePre = async(id)=>{
    let r = await axios.post(Const.backend,{type:"makePredeter",token:localStorage.conection,id:id}).catch(e=>{
      alert("Error al conectar al servidor")
      console.log(e)
    })
    if(r){
      if(r.data.error){
        console.log(r.data.error);
      }else{
        setUbications(null);
        setIsVisibleAdd(false)
        setReload(true)
      }
    }
  }
  const deleteUbi = async(id)=>{
    let r = await axios.post(Const.backend,{type:"deleteUbication",token:localStorage.conection,id:id}).catch(e=>{
      alert("Error al conectar al servidor")
      console.log(e)
    })
    if(r){
      if(r.data.error){
        console.log(r.data.error);
      }else{
        setUbications(null);
        setEditingId(null);
        setIsVisibleAdd(false)
        setReload(true);
      }
    }
  }
  useEffect(()=>{
    if(reload){
      setReload(false);
      consultUbications();
    }
  },[reload])
  const onChangeNum = async (value)=>{
    const re = /^[0-9\b]+$/;
    if (value === '' || (re.test(value)&&value.length<6)) {
       setCp(value);
    }
  }
  useEffect(() => {
    (async()=>{
      if (cp.length===5) {
        checkPostalCode();
      }else{
        setObjCp({estado:"",ciudad:"",pais:""})
      }
    })();
  }, [cp]);
  const checkPostalCode = async()=>{
    let error = null;
    const response = await axios.post(Const.backend,{type:"searchCpostal",cp:cp}).catch((e)=>error=e);
    if(error){
      console.log(error)
    }else{
      if (response.data.error) {
        setObjCp({pais:"",estado:"",ciudad:""});
      }else{
        setObjCp(response.data);
      }
    }
  }
  const checkErrors = ()=>{
    if(calle===""||numero===""||(cp===""||cp.length!==5||isNaN(cp))||nombre===""||colonia===""||objCp.ciudad===""||objCp.estado===""||objCp.pais===""){
      return true;
    }else{
      return false;
    }
  }
  const setFormValues = (item)=>{
      setObjCp({ciudad:"",estado:"",pais:""})
      setCalle(!item?"":item.calle);
      setNumero(!item?"":item.numero);
      setNombre(!item?"":item.nombre);
      setCp(!item?"":item.cp);
      setColonia(!item?"":item.colonia);
      setTelefono(!item?"":item.telefono);
      setEditingId(!item?null:item.id);
      if(item){
        setIsVisibleAdd(true);
        checkPostalCode()
      }
  }
  const saveNewUbication = async()=>{
    let type = "saveNewUbication";
    if(editingId){
      type = "editUbication";
    }
    let data = {
      calle,numero,colonia,cp,nombre,telefono,ciudad:objCp.ciudad,estado:objCp.estado,pais:objCp.pais
    }
    let r = await axios.post(Const.backend,{type,token:localStorage.conection,data,editingId}).catch(e=>{
      console.log(e);
    })
    if(r){
      if(r.data.error){
        alert(r.data.error)
        console.log(r.data.error);
      }else{
        setFormValues();
        setIsVisibleAdd(false)
        setUbications(null);
        setReload(true);
      }
    }
  }
  return(
    <>
      <div className={(isMobile())?"backgroundProfesionalNoAlignMobile":"backgroundProfesionalNoAlign"}>
        <Container>
          <FadeIn>
          <div style={{width:"100%",paddingLeft:"10%",paddingRight:"10%"}}>
          <Row className="mt-2">
            <Col xs="12">
              {
                !ubications||isLoading?
                <>
                  <div className="d-flex justify-content-center">
                    <Loader
                       type="ThreeDots"
                       color={"#fff"}
                       height={20}
                       width={40}
                       visible={true}
                    />
                  </div>
                </> :
                <div style={{width:"100%",paddingLeft:"15%",paddingRight:"15%"}}>
                  <p style={{color:"#fff",fontWeight:"bold",fontSize:25}}>
                    MIS DIRECCIONES
                  </p>
                  {
                    ubications.map((item,index)=>(
                      <div key={index} style={{flexDirection:"column",marginTop:index!==0?10:0}}>
                        <span style={{fontSize:15,color:"#656565",fontWeight:"bold"}}>{item.nombre}</span><br></br>
                        <span style={{fontSize:20,color:"#fff",marginTop:20}}>{item.calle} {item.numero}</span><br></br>
                        <span style={{fontSize:20,color:"#fff"}}>Col. {item.colonia}</span><br></br>
                        <span style={{fontSize:20,color:"#fff"}}>C.P. {item.cp}</span><br></br>
                        <span style={{fontSize:20,color:"#fff"}}>Cd. {item.municipio}, {item.estado}</span><br></br>
                        <span style={{fontSize:20,color:"#fff"}}>Tel. {item.telefono}</span><br></br>
                        <div style={{flexDirection:"row",marginTop:20}}>
                          <span onClick={()=>setFormValues(item)} style={{fontSize:12,color:"#fff",cursor:"pointer"}}>
                            Editar
                          </span>
                          <span style={{fontSize:12,color:"#fff",marginLeft:15}}>
                            |
                          </span>
                          <span onClick={(ubications.length===1||item.predeterminada)?null:()=>deleteUbi(item.id)} style={{fontSize:12,color:"#fff",marginLeft:15,cursor:(ubications.length===1||item.predeterminada)?"no-drop":"pointer"}}>
                            Descartar
                          </span>
                          <span style={{fontSize:12,color:"#fff",marginLeft:15}}>
                            |
                          </span>
                          <span onClick={(!item.predeterminada)?()=>makePre(item.id):null} style={{fontSize:12,color:"#fff",marginLeft:15,cursor:(item.predeterminada)?"no-drop":"pointer"}}>
                            {item.predeterminada?"PREDETERMINADA":"Establecer como Predeterminada"}
                          </span>
                        </div>
                      </div>
                    ))
                  }
                  <div style={{marginTop:20}}>
                    <Row>
                      <Col xs="12" md="8" lg="8" xl="8">
                        <p onClick={()=>setIsVisibleAdd(!isVisibleAdd)} style={{color:"#b1b1b1",fontWeight:"bold",fontSize:18,cursor:"pointer"}}>
                          [{isVisibleAdd?"-":"+"}] AGREGAR O EDITAR DIRECCION
                        </p>
                      </Col>
                      <Col xs="12" md="4" lg="4" xl="4">
                        <div className="d-flex justify-content-end">
                          <p onClick={()=>setFormValues()} style={{color:"#b1b1b1",fontWeight:"bold",fontSize:12,cursor:"pointer"}}>
                              {isVisibleAdd?"Limpiar Formulario":""}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    {
                      (isVisibleAdd)?
                      <>
                      <Row>
                        <Col xs="12" md="8" lg="8" xl="8">
                          <InputProfesional value={nombre} onChange={setNombre} placeholder={"Nombre de dirección"} />
                        </Col>
                        <Col xs="12" md="4" lg="4" xl="4">
                          <InputProfesional value={cp} onChange={onChangeNum} placeholder={"Código postal"} />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" md="8" lg="8" xl="8">
                          <InputProfesional value={calle} onChange={setCalle} placeholder={"Calle"} />
                        </Col>
                        <Col xs="12" md="4" lg="4" xl="4">
                          <InputProfesional value={numero} onChange={setNumero} placeholder={"Número"} />
                        </Col>
                        <Col xs="12" md="8" lg="8" xl="8">
                          <InputProfesional value={colonia} onChange={setColonia} placeholder={"Colonia"} />
                        </Col>
                        <Col xs="12" md="4" lg="4" xl="4">
                          <InputProfesional value={telefono} onChange={setTelefono} placeholder={"Teléfono contacto"} />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" md="4" lg="4" xl="4">
                          <InputProfesional value={objCp.ciudad} placeholder={"Ciudad"} disabled/>
                        </Col>
                        <Col xs="12" md="4" lg="4" xl="4">
                          <InputProfesional value={objCp.estado} placeholder={"Estado"} disabled/>
                        </Col>
                        <Col xs="12" md="4" lg="4" xl="4">
                          <InputProfesional value={objCp.pais} placeholder={"Pais"} disabled/>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" md="8" lg="8" cl="8">

                        </Col>
                        <Col xs="12" md="4" lg="4" cl="4">
                          <button className="btn btn-light btn-block" style={{fontWeight:"bold",cursor:(checkErrors())?"no-drop":"pointer"}} disabled={checkErrors()} onClick={saveNewUbication}>{editingId?"Guardar Cambios":"Agregar Dirección"}</button>
                        </Col>
                      </Row>
                      </> : null
                    }
                  </div>
                </div>
              }
            </Col>
          </Row>
          </div>
          </FadeIn>
        </Container>
      </div>
    </>
  )
}

export default Page;
