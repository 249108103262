import React,{useState,Fragment} from "react";
import {Container,Row,Col} from "react-bootstrap";
import {isMobile,checkMail,Const,encryptText} from "../../../Utils/Const";
import Input from "../All/InputProfesional";
import axios from "axios";
import FadeIn from "react-fade-in";


const Page = (props)=>{
  const {user} = props;
  const [name,setName]= useState(user?user.name:"");
  const [email,setEmail] = useState(user?user.email:"");
  const [tel,setTel] = useState("");
  const [msg,setMsg] = useState("");
  const [load,setLoad] = useState(false);
  const [response,setResponse] = useState(null);

  const sendMsg = async()=>{
    setLoad(true);
    let r = await axios.post(Const.backend,{type:"contactUs",email:email,name:name,tel:tel,msg:msg}).catch(e=>{
      setResponse({msg:"Error al conectar al servidor",status:false});
      console.log(e);
      setLoad(false)
    })
    if(r){
      if(r.data.error){
        setResponse({msg:r.data.error,status:false});
      }else{
        setResponse({msg:"Mensaje enviado correctamente",status:true});
      }
      setLoad(false)
    }
  }
  const letFormErrors = ()=>{
    if(name===""||(email===""||checkMail(email))||(tel===""||isNaN(tel)||tel.length<10)||msg===""){
      return true
    }else{
      return false;
    }
  }
  const generateCode = (id_event,length)=>{
    let itemsArray = [];
    for (var i = 1; i <= length; i++) {
      let limit = length.toString().length;
      let diff = limit - i.toString().length;
      let code = "";
      for (var k = 0; k < diff; k++) {
        code += " ";
      }
      code += i;
      itemsArray.push(encryptText(id_event)+"-"+encryptText(code))
    }
    return itemsArray;
  }
  return(
    <>
      <div className={"backgroundProfesional"}>
        <Container>
          <FadeIn>
            <Row className="mt-2">
              <Col xs="12" className="d-flex justify-content-center">
                <div className="mt-5" style={{width:isMobile()?"100%":500}}>
                  <p className="profesionalText" style={{fontWeight:"bold",color:"#fff",fontSize:25}}>
                    CONTACTO
                  </p>
                  <Input value={name} onChange={setName} placeholder="Nombre" />
                  <Input value={email} onChange={setEmail} placeholder="Email" />
                  <Input value={tel} onChange={setTel} placeholder="Teléfono" />
                  <textarea value={msg} placeholder="Escribe aquí tu mensaje" className="inputProfesional" onChange={e=>setMsg(e.target.value)} style={{width:"100%"}}/>
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-light profesionalText" style={{cursor:(letFormErrors())?"no-drop":"pointer"}} disabled={letFormErrors()} onClick={sendMsg}>{load?"Enviando..":"Enviar"}</button>
                  </div>
                  {
                    response?
                    <Fragment>
                      <div className="jumbotron d-flex justify-content-center mt-2" style={{borderStyle:"solid",borderColor:response.status?"green":"red"}}>
                        <p style={{color:response.status?"green":"red",fontWeight:"bold",fontSize:20}}>
                        {response.msg}
                        </p>
                      </div>
                    </Fragment> : null
                  }
                </div>
              </Col>
              <Col xs="12" className="d-flex justify-content-center">
                <div className="mt-5" style={{width:isMobile()?"100%":500}}>
                  <span className="profesionalText" style={{fontSize:20,fontWeight:"bold",color:"#fff"}}>
                    OFICINAS CENTRALES
                  </span><br></br>
                  <span className="profesionalText" style={{fontSize:15,color:"#fff"}}>
                    Av. Paseo Las Américas <strong>2423</strong> Col. Contry la Silla
                  </span><br></br>
                  <span className="profesionalText" style={{fontSize:15,color:"#fff"}}>
                    Cd. Guadalupe, Nuevo León C.P. 67173
                  </span><br></br>
                  <span className="profesionalText" style={{fontSize:15,color:"#fff"}}>
                    Lada sin costo <strong>800 1712 712</strong>
                  </span><br></br>
                  <span className="profesionalText" style={{fontSize:15,color:"#fff"}}>
                    atencionacliente@profesional712.com.mx
                  </span><br></br>
                  <br></br>
                  <span className="profesionalText" style={{fontSize:20,fontWeight:"bold",color:"#fff"}}>
                    ¿PROBLEMAS CON TU FACTURA?
                  </span><br></br>
                  <span className="profesionalText" style={{fontSize:15,color:"#fff"}}>
                    facturacion@profesional712.com.mx
                  </span><br></br>
                </div>
              </Col>
            </Row>
          </FadeIn>
        </Container>
      </div>
    </>
  )
}

export default Page;
