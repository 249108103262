import React, { Fragment } from "react";
import {Navbar,Nav,NavDropdown,Row,Col} from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';
import { FaSearch, FaUser, FaUserCheck } from "react-icons/fa";
import {MdMenu} from "react-icons/md"
import { useNavBar } from "./store";
import NavigationBar from "../NavigationBar";
import Cart from "../Cart/OldCartScreen/Cart";
import "./styles.css";
import FadeIn from 'react-fade-in';
const Model = ()=>{
    const {isMobile,showOptionsMobile} = useNavBar();
    return(
        <div className="navbar-profesional">
            <FadeIn>
                <Row>
                    <Col xs="2" sm="12" md="4">
                        {
                            !isMobile ? <NavLeft /> : <HandleShowMobileRoutes />
                        }
                    </Col>
                    <Col xs="10" sm="12" md="3" lg="4">
                        <div className="navbar-logo-container">
                            <NavLogo />
                        </div>
                    </Col>
                    <Col xs="12" sm="12" md="5" lg="4">
                        {
                            !isMobile ?
                            <NavRight />
                            :
                            <RightMobileIcons />
                        }
                    </Col>
                </Row>

            </FadeIn>
        </div>
    )
}
const RightMobileIcons = ()=>{
    const {needhref} = useNavBar();
    return(
        <Fragment>
            <Col sm="12" className="d-flex justify-content-center">
                    <div className="navbar-align-center">
                        <FadeIn>
                                <div className="navbar-row">
                                    <Nav.Link>
                                        {
                                            needhref ? 
                                            <FaSearch onClick={()=>window.location.href=`/search`} style={{fontSize:18,color:"#000"}}/> 
                                            : 
                                            <LinkContainer to="search" style={{fontSize:18,color:"#000"}}>
                                                <FaSearch/>
                                            </LinkContainer>
                                        }
                                    </Nav.Link>
                                    <Nav.Link>
                                        <Cart needhref={needhref}/>
                                    </Nav.Link>
                                    <UserDropdownIcon />
                                </div>
                        </FadeIn>
                    </div>
            </Col>
        </Fragment>
    )
}
const HandleShowMobileRoutes = ()=>{
    const {isMobile,handleOptions} = useNavBar();
    if(!isMobile){
        return <NavRight />
    }
    return(
        <div className="navbar-align-center" style={{cursor:"pointer"}}>
            <MenuDropdownIcon />
        </div>
    )

}
const NavLeft = ()=>{
    const {catList,needhref,showOptionsMobile,isMobile} = useNavBar();
    if(!showOptionsMobile&&isMobile){
        return null
    }
    return(
        <div className="navbar-align-center">
            <div className="navbar-row">
                <div className="dropdown-profesional">
                    <NavLink
                        label="PRODUCTOS"
                        to="findbycetegorie"
                    />
                    <NavigationBar categories={catList} needhref={needhref} />
                </div>
                <NavLink
                    label="|"
                    to="findbycetegorie"
                    style={{marginLeft:20}}
                />
                <NavLink
                    label="PRODUCTOS 7-12"
                    to="categorie?id=17"
                    style={{marginLeft:20}}
                />
                <NavLink
                    label={"|"}
                    to="categorie?id=17"
                    style={{marginLeft:20}}
                />
                <NavLink
                    label="AYUDA"
                    to=""
                    style={{marginLeft:20}}
                />
            </div>
        </div>
    )
}
const NavLogo = ()=>{
    const logoUrl = process.env.PUBLIC_URL + '/img/logo_black.png';
    const goTo = ()=>{
        window.location.href = "/"
    }
    return(
        <img 
            src={logoUrl} 
            onClick={goTo} 
            className="navbar-logo-profesional"
        />
    )
}
const MenuDropdownIcon = ()=>{

    return(
        <NavDropdown 
            id="dropdown-prof-user"
            title={<MdMenu style={{fontSize:30,color:"#000"}}/>}
            className="navbar-align-center"
        >
            <DropdownLink 
                label="Productos"
                to={"findbycetegorie"}
                key={1}
            />
            <DropdownLink 
                label="Productos 7-12"
                to={"categorie?id=17"}
                key={2}
                style={{marginLeft:20}}
            />
            <DropdownLink 
                label="Catalogo"
                to={"catalogue"}
                key={3}
                style={{marginLeft:20}}
            />
            <DropdownLink 
                label="Contacto"
                to={"contact"}
                key={4}
                style={{marginLeft:20}}
            />
        </NavDropdown>
    )
}
const UserDropdownIcon = ()=>{
    const {handleOptions,user} = useNavBar();
    return(
        <NavDropdown 
            id="dropdown-prof-user"
            title={user?<FaUserCheck style={{fontSize:18,color:"#000"}}/>:<FaUser style={{fontSize:18,color:"#000"}}/>}
            className="navbar-align-center"
        >
            <ProfileDropdownContent />
        </NavDropdown>
    )
}
const NavRight = ()=>{
    const {needhref} = useNavBar();
    return(
    <div className="navbar-align-center">
        <FadeIn>
                <Row>
                    <Col lg="12" xl="8" className="d-flex justify-content-center">
                        <div style={{display:"flex",flexDirection:"row",height:"100%"}}>
                            <div className="navbar-align-center">
                                <div className="navbar-row">
                                    <NavLink
                                        label="CATÁLOGO"
                                        to="catalogue"
                                        style={{marginLeft:20}}
                                    />
                                    <NavLink
                                        label="|"
                                        to="catalogue"
                                        style={{marginLeft:20}}
                                    />
                                    <NavLink
                                        label="CONTACTO"
                                        to="contact"
                                        style={{marginLeft:20}}
                                    />
                                    <NavLink
                                        label="|"
                                        to="contact"
                                        style={{marginLeft:20}}
                                    />
                                </div>
                            </div>
                            <NavDropdown 
                                id="dropdown-prof-user"
                                title={<span style={{color:"#000",marginLeft:20}}>MI CUENTA</span>}
                                className="navbar-align-center"
                            >
                                <ProfileDropdownContent />
                            </NavDropdown>
                        </div>
                    </Col>
                    <Col lg="12" xl="4">
                        <div className="navbar-align-center">
                            <div className="navbar-row">
                                <Nav.Link>
                                    {
                                        needhref ? 
                                        <FaSearch onClick={()=>window.location.href=`/search`} style={{fontSize:18,color:"#000"}}/> 
                                        : 
                                        <LinkContainer to="search" style={{fontSize:18,color:"#000"}}>
                                            <FaSearch/>
                                        </LinkContainer>
                                    }
                                </Nav.Link>
                                <Nav.Link>
                                    <Cart needhref={needhref}/>
                                </Nav.Link>
                            </div>
                        </div>
                    </Col>
                </Row>

        </FadeIn>
    </div>
    )
}
const ProfileDropdownContent = ()=>{
    const {user,logout} = useNavBar();
    if(user){
        return(
            <Fragment>
                <DropdownLink 
                    label="Mi Perfíl"
                    to={"profile"}
                />
                <DropdownLink 
                    label="Mis Direcciones"
                    to={"ubications"}
                />
                <DropdownLink 
                    label="Mis Compras"
                    to={"myshopping"}
                />
                <NavDropdown.Item onClick={logout} eventKey="4">
                    <span>
                    Cerrar Sesión
                    </span>
                </NavDropdown.Item>
            </Fragment>
        )
    }else{
        return(
            <Fragment>
                <DropdownLink 
                    label="Iniciar Sesión"
                    to={"login"}
                />
                <DropdownLink 
                    label="Registrarme"
                    to={"register"}
                />
            </Fragment>
        )
    }
}
const NavLink = ({label,to,style})=>{
    const {needhref} = useNavBar();
    if(!needhref){
        return(
            <LinkContainer to={to} className="navbar-text-profesional" style={{...style}}>
                <span>
                    {label}
                </span>
            </LinkContainer>
        )
    }else{
        return(
            <span onClick={()=>window.location.href=`/${to}`} className="navbar-text-profesional" style={{...style}}>
                {label}
            </span>
        )
    }
}
const DropdownLink = ({label,to,key,style})=>{
    const {needhref} = useNavBar();
    if(!needhref){
        return(
            <LinkContainer to={to}>
                <NavDropdown.Item eventKey={key}>
                    <span className="navbar-text-profesional">
                        {label}
                    </span>
                </NavDropdown.Item>
            </LinkContainer>
        )
    }else{
        return(
            <NavDropdown.Item onClick={()=>window.location.href=`/profile`} eventKey="1" style={{...style}}>
                <span className="navbar-text-profesional">
                    {label}
                </span>
            </NavDropdown.Item>
        )
    }
}
export default Model;
