import React from "react";
import { CatalogoProvider, useCatalogo } from "./store";
import {withRouter} from "react-router-dom";
import Loader from 'react-loader-spinner';
import Content from "./Content";

const Catalogo = ({user,history})=>{

    return(
        <div className="backgroundProfesional">
            <div className="container">
                <CatalogoProvider user={user} history={history}>
                    <CatalogoTunnel />
                </CatalogoProvider>
            </div>
        </div>
    )
}

export default withRouter(Catalogo);

const CatalogoTunnel = ()=>{
    let {error,data,load} = useCatalogo();
    if( load || ( !data && !error ) ){
        return(
                <div className="d-flex justify-content-center mt-4">
                    <Loader
                        type="Oval"
                        color={"#fff"}
                        height={150}
                        width={150}
                        visible={true}
                    />
                </div>
        )
    }
    if(error){
        return(
            <div className="d-flex justify-content-center mt-4">
                <div style={{width:300,maxHeight:100,borderRadius:10,backgroundColor:"#171717",padding:20,borderStyle:"solid",borderWidth:2,borderColor:"#fff"}}>
                    <div className="d-flex justify-content-center">
                        <span style={{textAlign:"center",fontWeight:"bold",fontSize:18,color:"#fff"}}>
                            {error}
                        </span>
                    </div>
                </div>
            </div>
        )
    }
    return <Content />
}