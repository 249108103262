import React from "react";
import {StoreProvider,useStore} from "./Store";
import { FaCaretRight } from "react-icons/fa";
import FamContainer from "./FamContainer";
//
import "./styles.css"
import { useNavBar } from "../Navbar/store";
import { Scrollbars } from 'react-custom-scrollbars';
const NavigationBar = (props)=>{
    const {categories,needhref} = props;
    return(
            <StoreProvider categories={categories} needhref={needhref}>
                <NavigationBarContainer />
            </StoreProvider>
    )
}
export default NavigationBar;
const NavigationBarContainer = ()=>{
    const {categories,selectedCat,setSelectedCat} = useStore();
    const {isMobile} = useNavBar()
    return(
        <div className="nav-principal">
          <div className={`nav-contenedor${isMobile?'-mobile':''}`}>
            <div className="nav-contenedor-izq">
              <Scrollbars style={{display:"flex",width:"100%",height:"100%"}}>
                {
                  categories&&categories.length>0 ? 
                  categories.map((cat,catIndex)=>(
                    <div onClick={()=>setSelectedCat(cat)} className="nav-contenedor-cat" key={catIndex}>
                      <div className="nav-contenedor-cat-text">
                        <span className="nav-text-cat" style={{color:selectedCat&&selectedCat.value===cat.value?"#c1c1c1":null}}>
                          {cat.name}
                        </span> 
                      </div>
                      <div className="nav-contenedor-cat-icon">
                        <FaCaretRight className="nav-text-cat nav-text-cat-icon" color={selectedCat&&selectedCat.value===cat.value?"#c1c1c1":""}/>
                      </div>
                    </div>
                  )) : null
                }
              </Scrollbars>
            </div>
            {
              selectedCat ? 
              <div className="nav-contenedor-drc">
                <Scrollbars style={{display:"flex",width:"100%",height:"100%"}}>
                  <FamContainer />
                </Scrollbars>
              </div> : null
            }
          </div>
        </div>
    )
}