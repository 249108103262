import React from "react";
import {Container,Row,Col} from "react-bootstrap";
import {isMobile} from "../../../Utils/Const";
import FadeIn from "react-fade-in";
const Weare = (props)=>{
  const {idioms,idioma} = props;


  return(
    <>
      <div className={(isMobile())?"backgroundProfesionalNoAlignMobile":"backgroundProfesional"}>
        <Container style={{paddingLeft:"15%",paddingRight:"15%",textAlign:"justify"}}>
          <FadeIn>
            <Row className="mt-2">
              <Col xs="12">
                <span className="profesionalText" style={{fontSize:40,color:"#fff",fontWeight:"bold"}}>{"¿Quienes Somos?"}</span>
                <p style={{fontSize:25,color:"#fff",fontWeight:"bold"}}>{"7 Dias de la semana 12 meses al año, al cuidado de la salud dental de tus pacientes"}</p>
                <p style={{fontSize:17,color:"#fff"}}>{"Profesional 7-12 es una empresa registrada que nace en Monterrey, Nuevo Leon al comprender la gran oportunidad de desarrollo, creación y comercialización de todos los productos de la marca SUNSTAR, GUM y BUTTLER. "}</p>
                <p style={{fontSize:17,color:"#fff"}}>{"Con un pleno y amplio conocimiento que se ajusta a las necesidades de cada profesionista del ramo dental."}</p>
              </Col>
              <Col className="mt-4" xs="12">
                <span style={{fontSize:25,color:"#fff",fontWeight:"bold"}}>{"Nuestra Misión"}</span>
                <p style={{fontSize:17,color:"#fff"}}>{"Ser la mejor opción en crear y distribuir productos de calidad que ayuden a suplir las necesidades de los profesionistas, brindando un servicio eficaz con atención personalizada."}</p>
              </Col>
              <Col className="mt-4" xs="12">
                <span style={{fontSize:25,color:"#fff",fontWeight:"bold"}}>{"Nuestra Visión"}</span>
                <p style={{fontSize:17,color:"#fff"}}>{"Crear lazos comerciales de beneficio mutuo y duradero con las principales marcas del ramo dental, asi como la creación de productos que le de beneficios tangibles al profesional dental en toda America latina. "}</p>
                <p style={{fontSize:17,color:"#fff"}}>{"Contribuir lo mas posible al cuidado de nuestro planeta y generar una estabilidad en desarrollo para nuestra empresa y sus asociados."}</p>
              </Col>
              <Col className="mt-4" xs="12">
                <span style={{fontSize:25,color:"#fff",fontWeight:"bold"}}>{"Valores que nos definen"}</span>
                <p style={{fontSize:17,color:"#fff"}}>{"Honestidad y Fe de servicio"}</p>
                <p style={{fontSize:17,color:"#fff"}}>{"Profesionalismo"}</p>
                <p style={{fontSize:17,color:"#fff"}}>{"Lealtad"}</p>
                <p style={{fontSize:17,color:"#fff"}}>{"Innovación y trabajo en equipo"}</p>
              </Col>
            </Row>
          </FadeIn>
        </Container>
      </div>
    </>
  )
}

export default Weare;
