import React from "react";
import {FaShoppingCart} from "react-icons/fa";
import {withRouter} from "react-router-dom";
import { useCart } from "../../../../Context/ContextCart";

const Cart = (props)=>{
  const {car} = useCart();
  const {needhref,history} = props;
  const nav = ()=>{
    if(!needhref){
      history.push("cart")
    }else{
      window.location.href="/cart"
    }
  }
  return(
    <div style={{display:"flex",flexDirection:"row",marginTop:5}}>
      <FaShoppingCart onClick={nav} style={{color:"#000",fontSize:20,cursor:"pointer"}} />
      {
        (car.length>0) ? <span style={{color:"red",fontSize:10}}>{car.length}</span> : null
      }
    </div>
  )
}

export default withRouter(Cart);
