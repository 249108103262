import React, { Fragment } from "react";
import "./styles.scss";
const Input = (props)=>{
    const {value,setValue,label,placeholder,type,error,containerStyle,disabled,useLabelWhite,useGreenError} = props;
    const identificador = `input__${Math.random()}`;
    return(
        <Fragment>
            <div className="form__group" style={{marginTop:-15,...containerStyle}}>
                <input 
                    type={type||"text"} 
                    className="form__field"
                    value={value}
                    onChange={(e)=>setValue(e.target.value)}
                    placeholder={placeholder||""} 
                    name={identificador} 
                    id={identificador} 
                    disabled={disabled||false}
                    style={{cursor:disabled?"no-drop":"auto"}}
                />

                {
                    error?
                    <p className={useGreenError?"form__error__green":"form__error"}>
                        {error}
                    </p> : null
                }
                <label htmlFor={identificador} className={useLabelWhite?"form__label_white":"form__label"}>{label}</label>
            </div>
        </Fragment>
    )
}
export default Input;