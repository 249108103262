import React,{Fragment,useState,useEffect} from "react";
import { useBilling } from "./Store";
import Divider from "../../All/Divider";
import {Row,Col} from "react-bootstrap";
import ErrorContainer from "../../All/ErrorContainer";
import Input from "../../All/InputV2";
import Loader from 'react-loader-spinner';
import Axios from "axios";
import { Const } from "../../../../Utils/Const";
import Button from "../../All/Button";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import Selector from "../../All/Select";
const AdvancedForm = ()=>{
    return(
        <Fragment>
            <Head />
            <Body />
        </Fragment>
    )
}
const Head = ()=>{
    const {order,cutDate} = useBilling();
    
    return(
        <Fragment>
            <div className="d-flex justify-content-end mt-4a">
                <span style={{fontSize:20,fontWeight:"bold",color:"#fff"}}>
                    Generar factura "{order.folio}"
                </span>
            </div>
            <Divider />
            <Row className="mt-4">
                <Col xs="6">
                    <div className="d-flex justify-content-start">
                        <p style={{color:"#fff"}}>
                            Total:
                        </p>
                    </div>
                    <div className="d-flex justify-content-start">
                        <p style={{color:"#fff"}}>
                            Productos:
                        </p>
                    </div>
                    <div className="d-flex justify-content-start">
                        <p style={{color:"#fff"}}>
                            Fecha de compra:
                        </p>
                    </div>
                </Col>
                <Col xs="6">
                    <div className="d-flex justify-content-end">
                        <p style={{color:"#fff"}}>
                            ${order.total}
                        </p>
                    </div>
                    <div className="d-flex justify-content-end">
                        <p style={{color:"#fff"}}>
                            {order.products.length-1}
                        </p>
                    </div>
                    <div className="d-flex justify-content-end">
                        <p style={{color:"#fff"}}>
                            {cutDate(order.fecha)}
                        </p>
                    </div>
                </Col>
            </Row>
            <Divider />
        </Fragment>
    )
}

const Body = ()=>{
    const {cutDate,order,validOrder,reset} = useBilling();
    let orderDate = new Date(cutDate(order.fecha));

    if(!validOrder(orderDate)){
        return <ErrorContainer error={`La compra "${order.folio}" no puede ser facturada.`} callback={{execute:reset,label:"Reintentar"}} />
    }
    return(
        <Fragment>
            <div className="d-flex justify-content-end mt-3">
                <span style={{fontSize:20,fontWeight:"bold",color:"#fff"}}>
                    DATOS DEL CLIENTE
                </span>
            </div>
            <Form />
        </Fragment>
    )
}
const Form = ()=>{
    const {checkCP,checkEMPTY,checkRFC,order,toast,advancedError,advancedLoad,reset,advancedData} = useBilling();
    const [rfc,setRfc] = useState("123456789101");
    const [razonSocial,setRazonSocial] = useState('Miguel Aguayo');
    const [calle,setCalle] = useState('Esteban Leal Villarreal');
    const [numeroInt,setNumeroInt] = useState('303');
    const [numeroExt,setNumeroExt,] = useState('303');
    const [estado,setEstado] = useState('');
    const [ciudad,setCiudad] = useState('');
    const [colonia,setColonia] = useState('Lolyta');
    const [codigoPostal,setCodigoPostal] = useState('');
    const [cfdi,setCfdi] = useState(null);
    const [regFiscal,setRegFiscal] = useState(null);
    const [formaPago,setFormaPago] = useState(null);

    const [billingError,setBillingError] = useState(null);
    const [billingLoader,setBillingLoader] = useState(false);
    const handleBillLoader = (param)=>setBillingLoader(param);

    //consultar y actualizar estado y ciudad
    const updateEstados = async()=>{
        let data = {
            type:"searchCpostal",
            cp:codigoPostal
        }
        let r = await Axios.post(Const.backend,data).catch(e=>{
            console.log("Error al consultar el servidor.")
        })
        if(r){
            if(r.data.error){
                console.log(r.data.error);
                setEstado("");
                setCiudad("");
            }else{
                setEstado(r.data.estado);
                setCiudad(r.data.ciudad);
            }
        }
    }
    //validación de errores
    const checkFormErrors = ()=>{
        if(!checkRFC(rfc)){
            if(!checkCP(codigoPostal)){
                if(!checkEMPTY(razonSocial)){
                    if(!checkEMPTY(calle)){
                        if(!checkEMPTY(colonia)){
                            if(!checkEMPTY(numeroInt)){
                                if(!checkEMPTY(numeroExt)){
                                    if(!checkEMPTY(estado)){
                                        if(!checkEMPTY(ciudad)){
                                            if(regFiscal){
                                                if(cfdi){
                                                    if(formaPago){
                                                        return false;   
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return true;
    }
    //confirmación
    const confirm = ()=>{
        confirmAlert({
            title: 'Confirmación',
            message: '¿Están correctos todos tus Datos?',
            buttons: [
              {
                label: 'Si, Generar factura',
                onClick: generate
              },
              {
                label: 'Prefiero confirmar',
                onClick: null
              }
            ]
        });
    }
    //generar factura
    const generate = async()=>{
        setBillingError(null);
        handleBillLoader(true)
        let data = {
            type:"generateBilling",
            form:{
                rfc,razonSocial,calle,colonia,numeroInt,numeroExt,codigoPostal,estado,ciudad,
                regFiscal:regFiscal.value,
                cfdi:cfdi.value,
                formaPago:formaPago.value
            },
            token:localStorage.conection,
            folio:order.folio
        }
        let r = await Axios.post(`${Const.backend}Billing`,data,{responseType: 'blob'}).catch(e=>{
            toast.error("Error al conectar al servidor")
        })
        if(r){
            let responseLength = await r.data.text();
            console.log({responseLength:responseLength.length})
            if(responseLength.length>=1000){
                toast.info(`Factura generada exitosamente.`);
                const FileDownload = require('js-file-download');
                FileDownload(r.data,"factura_4.0.zip");
            }else{
                let response = JSON.parse(await r.data.text());
                setBillingError(response.error);

            }
            setTimeout(()=>handleBillLoader(false),500)
            
        }
    }
    //cuando el estado de codigo postal cambie
    useEffect(()=>{
        if(codigoPostal.trim().length===5&&!isNaN(codigoPostal)){
            updateEstados();
        }else{
            setEstado("");
            setCiudad("");
        }
    },[codigoPostal])

    if(advancedError){//si existe un error en la consulta de información necesaria para el formulario
        return <ErrorContainer error={advancedError} callback={{execute:reset,label:"Reintentar"}} />
    }
    if(advancedLoad||!advancedData){//si el estado load es verdedero o la data no se llena aun
        return(
            <div className="d-flex justify-content-center mt-5">
                <Loader
                    type="Oval"
                    color={"#fff"}
                    height={150}
                    width={150}
                    visible={true}
                />
            </div>
        )
    }
    const {reg_fiscales,cfdiList,formaPagoList} = advancedData;
    return(
        <Fragment>
            <Row className="mt-4">
                <Col xs="12">
                    <Selector 
                        label="Regimen Fiscal"
                        items={reg_fiscales}
                        value={regFiscal}
                        setVal={setRegFiscal}
                    />
                </Col>
                <Col xs="12" md="6">
                    <Selector 
                        label="Metodo de pago"
                        items={formaPagoList}
                        value={formaPago}
                        setVal={setFormaPago}
                    />
                </Col>
                <Col xs="12" md="6">
                    <Selector 
                        label="Uso CFDI"
                        items={cfdiList}
                        value={cfdi}
                        setVal={setCfdi}
                    />
                </Col>
                <Col className="mt-3" xs="12" md="6">
                    <Input 
                        label="RFC"
                        value={rfc}
                        setValue={setRfc}
                        useLabelWhite
                        error={checkRFC(rfc)}
                    />
                </Col>
                <Col className="mt-3" xs="12" md="6">
                    <Input 
                        label="Razón Social"
                        value={razonSocial}
                        setValue={setRazonSocial}
                        useLabelWhite
                        error={checkEMPTY(razonSocial,"Razón Social")}
                    />
                </Col>
                <Col className="mt-3" xs="12" md="6">
                    <Input 
                        label="Calle"
                        value={calle}
                        setValue={setCalle}
                        useLabelWhite
                        error={checkEMPTY(calle,"Calle")}
                    />
                </Col>
                <Col className="mt-3" xs="12" md="6">
                    <Input 
                        label="Colonia"
                        value={colonia}
                        setValue={setColonia}
                        useLabelWhite
                        error={checkEMPTY(colonia,"Colonia")}
                    />
                </Col>
                <Col className="mt-3" xs="12" md="6">
                    <Input 
                        label="Número Interior"
                        value={numeroInt}
                        setValue={setNumeroInt}
                        useLabelWhite
                        error={checkEMPTY(numeroInt,"Número Interior")}
                    />
                </Col>
                <Col className="mt-3" xs="12" md="6">
                    <Input 
                        label="Número Exterior"
                        value={numeroExt}
                        setValue={setNumeroExt}
                        useLabelWhite
                        error={checkEMPTY(numeroExt,"Número Exterior")}
                    />
                </Col>
                <Col className="mt-3" xs="12" md="12">
                    <Input 
                        label="Código Postal"
                        value={codigoPostal}
                        setValue={setCodigoPostal}
                        useLabelWhite
                        error={checkCP(codigoPostal)}
                    />
                </Col>
                <Col className="mt-3" xs="12" md="6">
                    <Input 
                        label="Estado"
                        value={estado}
                        setValue={setEstado}
                        useLabelWhite
                        disabled
                        error={!estado?"Inserte su codigo postal para autocompletar este valor.":null}
                    />
                </Col>
                <Col className="mt-3" xs="12" md="6">
                    <Input 
                        label="Municipio/Delegación"
                        value={ciudad}
                        setValue={setCiudad}
                        useLabelWhite
                        disabled
                        error={!estado?"Inserte su codigo postal para autocompletar este valor.":null}
                    />
                </Col>
                {
                    billingLoader ?
                    <Col>
                        <div className="d-flex justify-content-center mt-5">
                            <Loader
                                type="Oval"
                                color={"#fff"}
                                height={150}
                                width={150}
                                visible={true}
                            />
                        </div>
                    </Col>
                    :
                    null
                }
                {
                    billingError ? 
                    <Col xs="12">
                        <div className="jumbotron">
                            <p style={{textAlign:"center"}}>
                                {billingError}
                            </p>
                        </div>
                    </Col> : null
                }
                <Col xs="12">
                    <div className="d-flex justify-content-center mt-5 mb-3">
                        <Button type="success" execute={confirm} disabled={checkFormErrors()||billingLoader}>
                            Generar Factura
                        </Button>
                    </div>
                </Col>
                
            </Row>
        </Fragment>
    )
}
export default AdvancedForm;