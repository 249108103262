import React,{useState,useEffect} from "react";
import { withRouter } from "react-router";
import axios from "axios";
import {Row,Col,Container} from "react-bootstrap";
import {Const} from "../../../Utils/Const";
import FadeIn from "react-fade-in";
import WebRoad, { useWebRoad } from "../WebRoad";
import { Scrollbars } from 'react-custom-scrollbars';
const Categorie = (props)=>{
    const {history} = props;
    const {backend} = Const;
    const {needhref,updateRoads} = useWebRoad();
    const [subCategories,setSubcategories] = useState(null);
    const searchProducts = async()=>{
        let _id = props.location.search.replace("?id=","")||null
        let post = {
            type:"searchFamiliesByCat",
            id:_id
        }
        let r = await axios.post(backend,post).catch(e=>{
            alert("Error al consultar el servidor");
            setSubcategories([])
        })
        if(r){
            if(r.data.error){
                alert(r.data.error);
                setSubcategories([])
            }else{
                let element = {
                    name:r.data.elementName,
                    value:_id,
                    execute:needhref?()=>window.location.href=`/categorie?id=${_id}` : ()=>history.push(`/categorie?id=${_id}`)                    
                }
                updateRoads({
                    level:2,
                    element
                })
                setSubcategories(r.data.elements.concat());
            }
        }
    }
    useEffect(()=>{
        searchProducts()
    },[props.location.search])
    const execute = (item)=>{
        if(item.tipo_elemento){
            history.push({
                pathname: "/products/"+item.id_elemento
            });
        }else{
            history.push({
              pathname: "/family",
              search: '?id='+item.id_elemento
            });
        }
      }
      const returnLarge = (val)=>{
        switch (val) {
            case 1:
                return "3"
                break;
                case 2:
                    return "6"
                    break;
        
            default:
                return "3"
                break;
        }
    }
    return(
        <div className="backgroundProfesional">
            <Scrollbars style={{display:"flex",width:"100%",height:"100%"}}>
                <Container>
                    <WebRoad level={2} history={history} />
                    {
                        !subCategories?
                        <div className="d-flex justify-content-center p-5">
                            <span className="profesionalText" style={{fontSize:25,color:"#fff",fontWeight:"bold",textAlign:"center"}}>
                                Consultando..
                            </span>
                        </div> :
                        subCategories.length>0?
                        <FadeIn>
                            <Row className="p-3">
                            {
                                subCategories.map((item,index)=>(
                                    <Col xs="12" md={returnLarge(item.tipo_imagen_id)} key={index} align="center" onClick={()=>execute(item)} style={{cursor:"pointer",marginTop:20}}>
                                        <ImgComponent url={item.nombre_imagen} type={item.tipo_elemento} />
                                        {
                                        item.tipo_elemento ? <span style={{color:"#fff"}}>{item.nombre_elemento}</span> : null
                                        }
                                    </Col>
                                ))
                            }
                            </Row>
                        </FadeIn> : 
                        <div className="d-flex justify-content-center p-5">
                            <span className="profesionalText" style={{fontSize:25,color:"#fff",fontWeight:"bold",textAlign:"center"}}>
                                No existen familias en esta categoría..
                            </span>
                        </div>
                    }
                </Container>
            </Scrollbars>
        </div>
    )
}
const ImgComponent = ({url,type})=>{
    const [urlImage,setUrlImage] = useState(null);
    const urlImgWaiting = `${process.env.PUBLIC_URL}/img/img/ProfWaitImg${type?"Quad":"Rect"}.png`;
    const onLoad = ()=>{
        setUrlImage(url);
    }
    return <img src={urlImage||urlImgWaiting} onLoad={onLoad} style={{width:"100%",cursor:"pointer"}} />
}
export default withRouter(Categorie);