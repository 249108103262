import React, {Fragment} from "react";
import {isMobile} from "../../../../Utils/Const";
import {Row,Container,Col} from "react-bootstrap";
import { useProfile } from "../Store";
import Loader from 'react-loader-spinner';
import Input from "../../All/InputV2";
import Button from "../../All/Button";
import Divider from "../../All/Divider";
import "./styles.css"

const Load = ()=>{
    return(
        <div className="d-flex justify-content-center">
            <div>
                <Loader
                    type="Oval"
                    color={"#fff"}
                    height={75}
                    width={75}
                    visible={true}
                />
            </div>
        </div>
    )
}

const Tunnel = ()=>{
    const {isLoading,fullName} = useProfile();
    return(
        <div className={(isMobile())?"backgroundProfesionalNoAlignMobile":"backgroundProfesionalNoAlign"}>
            <Container>
                <Row>
                    <Col xs="12">
                        {
                            isLoading ? <Load /> : fullName? <Content /> : <ErrorContiner />
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
const Content = ()=>{
    return(
        <Fragment>
            <Header />
            <Body />
            <Footer />
        </Fragment>
    )
}
const Header = ()=>{
    const {iniFullName} = useProfile();
    return(
        <Fragment>
            <Row>
                <Col xs="6">
                    <div className="d-flex justify-content-center">
                        <img 
                            src={"https://aguayodevs.com/user_profile.png"} 
                            className="profile-header-img" 
                            style={{width:150,height:150}} 
                            alt=""
                        />
                    </div>
                    <div className="d-flex justify-content-center">
                        <span style={{color:"#fff",fontSize:18}}>
                            SR(A) {iniFullName}
                        </span>
                    </div>
                </Col>
                <Col xs="6">
                    <div className="d-flex justify-content-end">
                        <span className="header-text-profile">
                            Mi Perfíl
                        </span>
                    </div>
                </Col>
            </Row>
            <Divider style={{marginTop:10}}/>
        </Fragment>
    )
}
const Body = ()=>{
    const {fullName,setFullName,email,setEmail,tel,setTel} = useProfile();
    return(
        <Fragment>
            <Row className="mt-3">
                <Col xs="12" md="6">
                    <Input 
                        value={fullName}
                        setValue={setFullName}
                        label="Nombre Completo"
                        placeholder="Nombre Completo"
                        useLabelWhite
                    />
                </Col>
                <Col xs="12" md="6">
                    <Input 
                        value={email}
                        setValue={setEmail}
                        label="Correo Electrónico"
                        placeholder="Correo Electrónico"
                        useLabelWhite
                    />
                </Col>
                <Col xs="12" md="6">
                    <Input 
                        value={tel}
                        setValue={setTel}
                        label="Teléfono"
                        placeholder="Teléfono"
                        containerStyle={{marginTop:10}}
                        useLabelWhite
                    />
                </Col>
            </Row>
        </Fragment>
    )
}

const Footer = ()=>{
    const {saveProfileChanges,history} = useProfile();
    return(
        <div className="d-flex justify-content-end mt-3">
            <div className="footer-profile-btnContainer">
                <Button type={"info"} style={{marginRight:10}} execute={()=>history.push("renewpassword")}>
                    Regenerar Contraseña
                </Button>
                <Button type="success" execute={saveProfileChanges}>
                    Guardar Cambios
                </Button>
            </div>
        </div>
    )
}
const ErrorContiner = ()=>{
    const {error} = useProfile();
    return(
        <div className="d-flex justify-content-center">
            <div className="nav-error-container">
                {error}
            </div>
        </div>
    )
}

export default Tunnel;