import React,{useState,useEffect} from "react";
import {Row,Col,Form, Container} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import {isMobile,checkMail,Const} from "../../../Utils/Const";
import InputProfesional from "../All/InputProfesional";
import Input from "../All/InputV2";
import Load from "../All/Loader";
import axios from "axios";
import {Typeahead} from 'react-bootstrap-typeahead';
import md5 from "md5";
import RegisterCode from "./RegisterCode";

const Register = (props)=>{
  const {idioms,idioma,toast,setUser,setReloadData,history} = props;
  const [nombres,setNombres] = useState("");
  const [cp,setCp] = useState("");
  const [psw,setPsw] = useState("");
  const [psw2,setPsw2] = useState("");
  const [email,setEmail] = useState("");
  const [regCode,setRegCode] = useState("");
  const [telefono,setTelefono] = useState("");
  const [especialidades,setEspecialidades] = useState([]);
  const [refreshEspecialidades,setRefreshEspecialidades] = useState(false);
  const [idEspecialidad,setIdEspecialidad] = useState("");
  const [privacidad,setPrivacidad] = useState(false);
  const [calle,setCalle] = useState("");
  const [numero,setNumero] = useState("");
  const [numeroInt,setNumeroInt] = useState("");
  const [colonia,setColonia] = useState("");
  const [objCp,setObjCp] = useState({pais:"",estado:"",ciudad:""});
  const [isVisible,setIsVisible] = useState(false);
  const [publicity,setPublicity] = useState(true);
  const [forcePrice,setForcePrice] = useState(null);

  const [banner,setBanner] = useState(null);

  const checkPostalCode = async()=>{
    setIsVisible(true);
    let error = null;
    const response = await axios.post(Const.backend,{type:"searchCpostal",cp:cp}).catch((e)=>error=e);
    if(error){
      console.log(error)
    }else{
      if (response.data.error) {
        setObjCp({pais:"",estado:"",ciudad:""});
      }else{
        setObjCp(response.data);
      }
    }
    setIsVisible(false);
  }
  const checkCpErrors = ()=>{
    if (cp.length===5) {
      if (objCp.pais==="") {
        return "Código postal no válido"
      }else{
        return null;
      }
    }else{
      return "El código postal debe contener 5 números";
    }
  }
  const letFormErrors = ()=>{
    let newObj = {
      nombre: nombres,
      email: email,
      cpostal: cp,
      pais: objCp.pais,
      estado:objCp.estado,
      ciudad:objCp.ciudad,
      telefono: telefono,
      calle: calle,
      colonia:colonia,
      numero:numero,
      especialidad: idEspecialidad
    }
    let errors = [];
    for (var prop in newObj) {
      if (!newObj[prop]) {
        errors.push(prop+" esta vacio");
      }
    }
    if (errors.length>0||privacidad===false) {
      return true;
    }else{
      return false;
    }
  }
  const checkEmail = (val)=>{
    let reg = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    if (reg.test(val)) {
      return null;
    }else{
      if(val){
        return "Formato de correo electrónico inválido";
      }else{
        return "Inserte su correo electrónicoa"
      }
    }
  }
  const checkEmpty = (value,tag)=>{
    if(!value){
      return tag
    }else{
      return null;
    }
  }
  const checkTel = (value)=>{
    if(!value||isNaN(value)){
      return true;
    }
    return null
  }
  const checkErrors = ()=>{
    if(privacidad===false){
      console.log("Privacidad esta entrando")
      return true;
    }
    if(checkEmpty(nombres,"tag")||checkEmpty(calle,"tag")||checkEmpty(colonia,"tag")||checkEmpty(numero,"tag")){
      console.log("Check Empty esta entrando")
      return true;
    }
    if(checkEmail(email)){
      console.log("Check Email esta entrando")
      return true
    }
    if(checkTel(telefono)){
      console.log("Check Tel esta entrando")
      return true;
    }
    return false;
  }
  const registerNewUser = async()=>{
    if (checkErrors()) {
      toast.error("Por favor asegurese de completar los campos correctamente")
    }else{
      setIsVisible(true);
      let fecha = new Date();
      let fecha_datetime = fecha.toISOString().slice(0, 19).replace('T', ' ');
      let post = {
        type: "createNewClient",
        publicity:publicity?1:0,
        nombre: nombres,psw: md5(psw),
        dateTime:fecha_datetime,
        email: email,
        cpostal: cp,
        telefono: telefono,
        pais: objCp.pais,
        estado:objCp.estado,
        ciudad:objCp.ciudad,
        calle: calle,
        colonia:colonia,
        numero:numero,
        numeroInt: numeroInt,
        especialidad: idEspecialidad, 
        regCode,
        forcePrice
      };
      let r = await axios.post(Const.backend,post).catch((e)=>{
        toast.error("Error al conectar al servidor.")
      });
      if(r&&r.data){
        if(r.data.error){
          toast.error(r.data.error);
        }else{
          toast.success("Bienvenido: "+r.data.name);
          localStorage.setItem("conection",r.data.conection);
          setReloadData(true);
          history.push("/")
        }
        setIsVisible(false);
      }
      /*
      if (error) {
        console.log(error);
      }else{

        if (response.data.error) {
          toast.error(response.data.error)
        }else{
          toast.success("Bienvenido: "+response.data.name);
          toast.info("Por favor verifica tu correo electrónico enviado a: "+response.data.email);
          localStorage.setItem("conection",response.data.conection);
          setUser(response.data);
          history.push("/");
        }
      }
      */
    }
  }
  const onChangeNum2 = async (value)=>{
    const re = /^[0-9\b]+$/;
    if (value === '' || (re.test(value))) {
       setTelefono(value);
    }
  }
  const checkPsw = ()=>{
    if (!psw) {
      return "Inserte su contraseña.";
    }else{
      if (containMayus(psw)) {
        if (containNumbers(psw)) {
          if(psw.length>=8){
            return null;
          }else{
            return "La contraseña debe contener al menos 8 caracteres";
          }
        }else{
          return "La contraseña debe contener al menos un número";
        }
      }else{
        return "La contraseña debe contener una letra mayuscula";
      }
    }
  }
  const checkPsw2 = ()=>{
    if (psw===psw2) {
      return null;
    }else{
      return "Las contraseñas no coinciden";
    }
  }
  const containNumbers = (text)=>{
     var numbers="0123456789";
     for(var i=0; i<text.length; i++){
        if (numbers.indexOf(text.charAt(i),0)!==-1){
           return true;
        }
     }
     return false;
  }
  const containMayus = (text) => {
     var mayus="ABCDEFGHIYJKLMNÑOPQRSTUVWXYZ";
     for(var i=0; i<text.length; i++){
        if (mayus.indexOf(text.charAt(i),0)!==-1){
           return true;
        }
     }
     return false;
  }
  const onChangeNum = async (value)=>{
    const re = /^[0-9\b]+$/;
    if (value === '' || (re.test(value)&&value.length<6)) {
       setCp(value);
    }
  }
  useEffect(() => {
    (async()=>{
      if (cp.length===5) {
        checkPostalCode();
      }
    })();
  }, [cp]);
  useEffect(() => {
    (async()=>{
      setIsVisible(true);
      let error = null;
      const response = await axios.post(Const.backend,{type:"searchEspecialidades"}).catch((e)=>error=e);
      if(error){
        console.log(error)
      }else{
        if (response.data.error) {
          setEspecialidades([]);
          console.log(response.data.error);
        }else{
          setEspecialidades(response.data.rows);
          setBanner(response.data.banner);
        }
      }
      setIsVisible(false);
    })();
  }, [refreshEspecialidades]);
  return(
    <>
      <div className="backgroundProfesional">
        <div style={{width:"100%"}}>
          
          <Container>
              <Banner banner={banner} />
          </Container>
          <div className="d-flex justify-content-center">
            <div className={(isMobile())?"divMarcoLoginMobile":"divMarcoRegister"}>
              <span style={{fontSize:25,color:"#fff",fontWeight:"bold"}}>{idioms[idioma].views.register.text.register2}</span>
              <Input 
                value={nombres} 
                setValue={setNombres} 
                placeholder={idioms[idioma].views.register.inputs.phUser} 
                error={checkEmpty(nombres,"Escriba su nombre completo para continuar")} 
                useGreenError={checkEmpty(nombres,"nombres")?false:true}
              />
              <Input 
                value={email} 
                setValue={setEmail} 
                placeholder={idioms[idioma].views.register.inputs.phEmail} 
                error={checkEmail(email)}
                useGreenError={checkEmpty(email,"email")?false:true}
              />
                <Row>
                  <Col xs="6">
                    <Input 
                      value={psw} 
                      type={"password"} 
                      setValue={setPsw} 
                      placeholder={"Contraseña"} 
                      error={checkPsw()}
                      useGreenError={checkEmpty(psw,"psw")?false:true}
                    />
                  </Col>
                  <Col xs="6">
                    <Input 
                      value={psw2} 
                      type={"password"} 
                      setValue={setPsw2} 
                      placeholder={"Repetir Contraseña"} 
                      error={checkPsw2()} 
                      useGreenError={checkEmpty(psw2,"password2")?false:true}
                    />
                  </Col>
                </Row>
              <Input 
                value={telefono}
                setValue={onChangeNum2} 
                placeholder={idioms[idioma].views.register.inputs.phTel} 
                error={checkTel(telefono)}
                useGreenError={checkEmpty(telefono,"telefono")?false:true}
              />
              <Input 
                value={cp} 
                error={checkCpErrors()} 
                setValue={onChangeNum} 
                placeholder={idioms[idioma].views.register.inputs.phPostal}
                useGreenError={checkEmpty(cp,"cp")?false:true} 
              />
                <Row>
                  <Col xs="12">
                      <Input value={objCp.pais}  placeholder={"Pais"} disabled  />
                  </Col>
                  <Col xs="6">
                      <Input value={objCp.estado} placeholder={"Estado"} disabled />
                  </Col>
                  <Col xs="6">
                    <Input value={objCp.ciudad} placeholder={"Ciudad"} disabled  />
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <Input value={calle} setValue={setCalle} placeholder={"Calle"} error={checkEmpty(calle,"La calle no puede estar vacia.")} />
                  </Col>
                  <Col xs="6">
                    <Input value={colonia} setValue={setColonia} placeholder={"Colonia"} error={checkEmpty(colonia,"La colonia no puede estar vacia.")} />
                  </Col>
                </Row>
              <Row>
                <Col xs="6">
                  <Input value={numero} setValue={setNumero} placeholder={"Número exterior"} error={checkEmpty(colonia,"Ingrese su número exterior.")} />
                </Col>
                <Col xs="6">
                  <Input value={numeroInt} setValue={setNumeroInt} placeholder={"Número interior"} />
                </Col>
              </Row>

                <Typeahead id="searchInputEspecialidad"
                  inputProps={{placeholder:"Especialidad"}}
                  filterBy={['label']}
                  className="mt-2"
                  onChange={(selected) => {
                    if(selected[0]){
                      setIdEspecialidad(selected[0].value);
                    }else{
                      setIdEspecialidad("");
                    }
                  }}
                  style={{width:"100%"}}
                  options={especialidades}
                />
              <RegisterCode 
                regCode={regCode}
                setRegCode={setRegCode}
                forcePrice={forcePrice}
                setForcePrice={setForcePrice}
              />
              <Row className="mt-4">
                <Col xs="12" className="text-center">
                  <Form.Check
                    type="switch"
                    id={"switch-aviso"}
                    label={<><span style={{color:"#fff"}}>Acepto el </span><a href={`${Const.backend}getPolicies?file=privacyPolicies.pdf`} target="_blank">aviso se privacidad</a></>}
                    defaultChecked={privacidad}
                    onChange={(e)=>setPrivacidad(e.target.checked)}
                  />
                  <Form.Check
                    type="switch"
                    id={"newsletter"}
                    label={<span style={{color:"#fff"}}>Deseo recibir información y promociones a mi correo electrónico</span>}
                    defaultChecked={publicity}
                    onChange={(e)=>setPublicity(e.target.checked)}
                  />
                </Col>
                <Col xs="8" className="text-center mt-2">
                  <p style={{color:"#999999",fontSize:7}}>{idioms[idioma].views.register.text.privacy}</p>
                </Col>
                <Col xs="4" className="d-flex justify-content-end mt-2">
                  <button className="btn btn-light btn-block" style={{cursor:(checkErrors())?"no-drop":"pointer"}} disabled={checkErrors()} onClick={()=>registerNewUser()}>{idioms[idioma].views.register.text.register}</button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <Load isVisible={isVisible} color={"#000"}/>
    </>
  )
}
export default withRouter(Register);
const Banner = ({banner})=>{
  if(!banner){
      return null;
  }
  return(
      <div className="p-3">
          <img 
              src={banner}
              style={{
                  width:"100%",
                  objectFit:"contain",
                  borderRadius:10
              }}
          />
      </div>
  )
}
