import React from "react";
import {Row,Col} from "react-bootstrap";
import Input from "../../All/InputV2";
import { RegisterCodeProvider, useRegisterCode } from "./store";

const RegisterCode = (props)=>{

    return(
        <RegisterCodeProvider {...props}>
            <RegisterCodeTunnel />
        </RegisterCodeProvider>
    )
}

const RegisterCodeTunnel = ()=>{
    const {serverMsg,forcePrice,regCode,setRegCode} = useRegisterCode();

    return(
        <Row>
            <Col xs="12">
                <Input value={regCode} setValue={setRegCode} placeholder={"Código promocional"} />
                {
                    serverMsg ? 
                    <div className="d-flex justify-content-center">
                        <span
                            style={{
                                fonrSize:10,
                                color:forcePrice?"green":"#fff",
                                textAlign:"center"
                            }}
                        >
                            {serverMsg}
                        </span>
                    </div> : null
                }
            </Col>
        </Row>
    )
}

export default RegisterCode;