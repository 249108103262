import React,{Fragment} from "react";
import {Container,Row,Col} from "react-bootstrap";
import {isMobile} from "../../../Utils/Const";
import Content from "./Content";
import {withRouter} from "react-router-dom";
import {ProfileProvider} from "./Store";
const Profile = (props)=>{
    const {idioms,idioma,user,history} = props;
    return(
        <>
            <div className="backgroundProfesional">
                {
                    user ? 
                    <ProfileProvider user={user} history={history}>
                        <Content />
                    </ProfileProvider> : <LoginComponent history={history} />
                }
            </div>
        </>
    )
}

const LoginComponent = (props)=>{
    const {history} = props;
    return(
        <Fragment>
            <div style={{width:"100%"}}>
                <div className="d-flex justify-content-center">
                    <button className="btn btn-dark mt-2" style={{backgroundColor:"#686868",width:200,maxHeight:100}} onClick={()=>history.push("/login")}>Iniciar Sesión</button>
                </div>
                <div className="d-flex justify-content-center">
                    <button className="btn btn-dark mt-2" style={{backgroundColor:"#686868",width:200,maxHeight:100}} onClick={()=>history.push("/register")}>Registrarme</button>
                </div>
            </div>
        </Fragment>
    )
}
export default withRouter(Profile);