import React, {useMemo,useState,useContext,createContext,useEffect} from "react";
import { Const } from "../../../Utils/Const";
import axios from "axios";
import { useHistory } from "react-router-dom";
const Store = createContext();

export const StoreProvider = (props)=>{
    const {categories,needhref} = props;
    let history = useHistory();
    const {backend} = Const;
    const [families,setFamilies] = useState([]);
    const [error,setError] = useState(null);
    const [selectedCat,setSelectedCat] = useState(null);
    const [isLoading,setIsLoading] = useState(false);
    const handleLoad = (param)=>setIsLoading(param);

    const refreshData = async(load)=>{
        setError(null);
        handleLoad(true);
        let postData = {
            type:"searchFamiliesByID",
            value:selectedCat.value
        }
        let r = await axios.post(`${backend}`,postData).catch(e=>{
            console.log(e);
        })
        if(r){
            if(r.data.error){
                setError(r.data.error);
            }else{
                if(r.data.families.length>0){
                    setFamilies(r.data.families);
                }else{
                    setError("Lo sentimos, no existen familias asignadas a esta categoría.")
                }
            }
        }
        handleLoad(false);
    }

    useEffect(()=>{
        if(selectedCat){
            refreshData(true);
        }
    },[selectedCat])
    const value = useMemo(()=>{
        return({
            categories,
            families,
            selectedCat,setSelectedCat,
            isLoading,
            error,
            needhref,
            history
        })
      },[selectedCat,families,isLoading,error,needhref])
      return <Store.Provider value={value} {...props}/>
}

export const useStore = ()=>useContext(Store);