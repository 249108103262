import React from 'react';
import {useLocation} from "react-router-dom";
const NavBar = React.createContext();
export const NavBarProvider = (props)=>{
    const {catList,user,toast,setUser} = props;
    const location = useLocation();
    const availWidth = window.screen.availWidth;
    const [needhref,setneedhref] = React.useState(null);
    const [isMobile,setIsMobile] = React.useState(false);
    const [showOptionsMobile,setShowOptionsMobile] = React.useState(false);
    const handleOptions = () => {
        setShowOptionsMobile(!showOptionsMobile)
        console.log("Se ejecuto handleOptions con el valor:",!showOptionsMobile)
    };

    const logout = ()=>{
        localStorage.clear();
        setUser(null);
        toast.info("Hasta pronto.");
        setTimeout(()=>window.location.href="/",1500);
    }
    React.useEffect(()=>{
        let quant = location.pathname.split("/").length;
        if(quant>2){
          setneedhref(true)
        }else{
          setneedhref(false)
        }
    },[location.pathname])

    React.useEffect(()=>{
        if(availWidth<=600){
            console.log("Se seteo como verdadero isMobile")
            setIsMobile(true)
        }
    },[availWidth])

    const value = React.useMemo(()=>{
        return({
            needhref,
            catList,
            isMobile,
            user,
            toast,
            availWidth,
            showOptionsMobile,handleOptions,
            logout
        })
    },[needhref,showOptionsMobile,user])
    return <NavBar.Provider value={value} {...props}/>
}
export const useNavBar = ()=> React.useContext(NavBar);