import React,{Fragment} from "react";
import { ModalPswProvider, useModalPsw } from "./store";
import Loader from 'react-loader-spinner';
import Content from "./Content";

const ModalPsw = (props)=>{

    return(
        <ModalPswProvider {...props}>
            <ModalPswTunnel />
        </ModalPswProvider>
    )
}
export default ModalPsw;

const ModalPswTunnel = ()=>{
    const {isLoading} = useModalPsw();
    if(isLoading){
        return(
            <div style={{width:"100%",marginTop:10}} className="d-flex justify-content-center mt-2">
                <Loader
                    type="Oval"
                    color={"#171717"}
                    height={150}
                    width={150}
                    visible={true}
                />
            </div>
        )
    }
    return <Content />
}