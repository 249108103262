import React, { Fragment,useState,useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {withRouter} from "react-router-dom";
import {Const} from "../../../Utils/Const";
import axios from "axios";
import Divider from "../All/Divider";
import FadeIn from "react-fade-in";

const Thanks = (props)=>{
  const {history,location} = props;
  const orderId = location.search.replace("?id=","") || null;
  const [isLoading,setIsLoading] = useState(false);
  const [order,setOrder] = useState("");
  const [banner,setBanner] = useState(null);
  const [errorMsg,setErrorMsg] = useState("");

  const searchOrder = async()=>{
    if(!isLoading){
      setIsLoading(true);
    }
    let r = await axios.post(Const.backend,{type:"orderInfo",orderId,token:localStorage.conection}).catch(e=>{
      setErrorMsg("Error al conectar al servidor");
      console.log(e);
      setIsLoading(false);
    })
    if(r){
      if(r.data.error){
        setOrder(null)
        setErrorMsg(r.data.error);
        console.log(r.data.datos);
      }else{
        setBanner(r.data.banner);
        setOrder(r.data.order);
      }
      setIsLoading(false);
    }
  }
  const returnFecha = (f)=>{
    let arrayFecha = f.split("T");
    let arrayFecha2 =  arrayFecha[0].split("-");
    return arrayFecha2[0]+"/"+arrayFecha2[1]+"/"+arrayFecha2[2];
  }
  useEffect(()=>{
    if(!orderId||isNaN(orderId)){
      history.push("/")
    }else{
      searchOrder();
    }
  },[])
  return(
    <div className="backgroundProfesional">
        <Container style={{paddingTop:20,paddingLeft:"10%",paddingRight:"10%"}}>
          {
            order===""?
            <Fragment>
                <div style={{width:"100%",alignItems:"center"}}>
                  <p style={{fontSize:20,fontWeight:"bold",color:"#fff",textAlign:"center"}}>
                    ...
                  </p>
                </div>
            </Fragment> : order===null ?
              <Fragment>
                <div style={{width:"100%",alignItems:"center"}}>
                  <p style={{fontSize:20,color:"#fff",textAlign:"center"}}>
                    {errorMsg}
                  </p>
                </div>
              </Fragment> :
              <Row>
                <Divider />
                <Col sm="8">
                  <p style={{fontSize:25,fontWeight:"bold",color:"#fff",marginTop:15}}>
                    {
                      parseInt(order.estatus_pedido)===7?
                      "¡Pendiente de pago!":
                      "¡Gracias!"
                    }
                  </p>
                  <p style={{fontSize:15,color:"#fff"}}>
                      {
                        parseInt(order.estatus_pedido)===7?
                        <Fragment>
                          Por favor concluye el pago con el siguiente número de referencia en el apartado 'concepto': " 
                          <span style={{color:"#00cd60"}}>
                            {order.tienda_referencia_pago}
                          </span>
                          "
                        </Fragment>
                        :
                        "Hemos recibido tu pedido y está listo para ser procesado."
                      }
                  </p>
                </Col>
                <Col sm="4" style={{textAlign:"center"}}>
                  <button onClick={()=>history.push({pathname: "/myorder",search: '?folio='+order.folio})} className="btn btn-dark" style={{borderRadius:20,borderStyle:"solid",borderColor:"#fff",backgroundColor:"#1a1a1a",borderWidth:2,fontWeight:"bold",marginTop:30}}>
                    Ver pedido 
                  </button>
                </Col>
                {
                  parseInt(order.estatus_pedido)===7?
                  <Col sm="12">
                    <Banner banner={banner} />
                  </Col> : null
                }
                <Col sm="12" className="d-flex justify-content-center">
                  <p style={{fontSize:15,color:"#fff"}}>Pedido: {order.folio}</p>
                </Col>
                <Divider />
                <Col sm="12" className="d-flex justify-content-end">
                  <p style={{fontSize:25,fontWeight:"bold",color:"#fff",marginTop:15}}>RESUMEN DEL PEDIDO</p>
                </Col>
                <Col sm="12">
                  <p style={{fontSize:15,color:"#fff"}}>Pedido: {" "+order.folio}</p>
                  <p style={{fontSize:15,color:"#fff"}}>Fecha de pedido: {returnFecha(order.fecha_pedido)}</p>
                  <p style={{fontSize:15,color:"#fff"}}>Costo Total: {"$"+order.total.toFixed(2)}</p>
                </Col>
                <Divider />
                  <Col sm="12" className="d-flex justify-content-end">
                    <p style={{fontSize:25,fontWeight:"bold",color:"#fff",marginTop:15}}>DIRECCIÓN DE ENTREGA</p>
                  </Col>
                  <Col sm="12">
                    <p style={{fontSize:18,color:"#fff",textAlign:"center",fontWeight:"bold"}}>{order.destino||"No se encontro destino de pedido"}</p>
                  </Col>
              </Row>
          }
        </Container>
      </div>
  )
}
const Banner = ({banner})=>{
  if(!banner){
      return null;
  }
  return(
      <div className="p-3">
          <img 
              src={banner}
              style={{
                  width:"100%",
                  objectFit:"contain",
                  borderRadius:10
              }}
          />
      </div>
  )
}
export default withRouter(Thanks);
