import React, {Fragment} from "react";
import {Modal} from "react-bootstrap";
import { ModalLocationProvider, useModalLocation } from "./Store";
import Loader from 'react-loader-spinner';
import { ErrorLocation, InProcess, Location } from "./Components";

const ModalLocation = ({guia,visible,handleVisible})=>{

    return(
        <ModalLocationProvider guia={guia} visible={visible} handleVisible={handleVisible}>
            <ModalContent>
                <LocationTunnel />
            </ModalContent>
        </ModalLocationProvider>
    )
}
export default ModalLocation;
const LocationTunnel = ()=>{
    const {guia,load,location,error} = useModalLocation();
    if(load||( guia && !location && !error )){

        return(
            <div className="d-flex justify-content-center mt-5">
                <Loader
                    type="Oval"
                    color={"#000"}
                    height={150}
                    width={150}
                    visible={true}
                />
            </div>
        )
    }
    if(error){
        return <ErrorLocation />
    }
    if(!guia){
        return <InProcess />
    }
    return <Location />
}

const ModalContent = (props)=>{
    const {visible,handleVisible} = useModalLocation();
    return(
        <Fragment>
            <Modal size={"md"} show={visible} onHide={handleVisible} aria-labelledby={"modal-locate-tracking"} centered={true}>
                <Modal.Header style={{backgroundColor:"#1a1a1a"}} closeButton>
                    <Modal.Title style={{color:"#fff"}}>Localización de pedido.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.children}
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}