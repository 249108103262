import React,{Fragment} from "react";
import ModalPago from "./ModalPago";
import LeftSide from "./LeftSide";
import RightSide from "./RightSide";
import {Row,Col} from "react-bootstrap";
const Content = ()=>{
    return(
        <Fragment>
            <ModalPago />
            <Row>
                <Col className="p-1" xs="12" md="8" lg="8">
                    <LeftSide />
                </Col>
                <Col className="p-1" xs="12" md="4" lg="4">
                    <RightSide />
                </Col>
            </Row>
        </Fragment>
    )
}
export default Content;