import React, {Fragment} from "react";
import { useModalPsw } from "./store";
import Input from "../../All/InputV2";
import {checkMail} from "../../../../Utils/Const";

const Content = ()=>{
    const {idUser,pswChanged} = useModalPsw();
    if(idUser){
        if(pswChanged){
            return <Congrats />
        }
        return <CodeForm />
    }
    return <EmailForm />
}
export default Content;
const EmailForm = ()=>{
    const {email,setEmail,sendCode} = useModalPsw();
    return(
        <Fragment>
            <p style={{textAlign:"center"}}>
                Enviaremos un código que tendras que confirmar a tu correo electrónico.
            </p>
            <Input 
                label="Correo electrónico"
                value={email} 
                setValue={setEmail} 
                placeholder={"Correo electrónico"} 
                error={checkMail(email)} 
            />
            <div className="d-flex justify-content-center">
                <button onClick={sendCode} style={{backgroundColor:"#686868"}} className="btn btn-dark btn-block" disabled={!checkMail(email)?false:true}>
                    Enviar código
                </button>
            </div>
        </Fragment>
    )
}
const CodeForm = ()=>{
    const {code,setCode,checkCodeError,psw1,setPsw1,psw2,setPsw2,checkPsw,checkPsw2,checkCode} = useModalPsw();
    const checkErrors = ()=>{
        if(!checkCodeError()&&!checkPsw()&&!checkPsw2()){
            return false;
        }
        return true;
    }
    return(
        <Fragment>
            <p style={{textAlign:"center"}}>
                Inserta el código de confirmación y tu nueva contraseña para continuar.
            </p>
            <Input 
                value={code} 
                setValue={setCode} 
                label="Código de validación"
                error={checkCodeError()} 
            />
            <Input 
                value={psw1} 
                setValue={setPsw1} 
                label={"Nueva contraseña"} 
                error={checkPsw()} 
                containerStyle={{
                    marginTop:5
                }}
                type="password"
            />
            <Input 
                value={psw2} 
                setValue={setPsw2} 
                label={"Repetir contraseña"} 
                error={checkPsw2()}
                containerStyle={{
                    marginTop:5
                }}
                type="password"
            />
            <div className="d-flex justify-content-center">
                <button onClick={checkCode} style={{backgroundColor:"#686868"}} className="btn btn-dark btn-block" disabled={checkErrors()}>
                    Enviar código
                </button>
            </div>
        </Fragment>
    )
}
const Congrats = ()=>{

    return(
        <Fragment>
            <p style={{textAlign:"center",fontSize:20}}>
                Tu contraseña ha sido actualizada exitosamente.
            </p>
        </Fragment>
    )
}