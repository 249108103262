import React, {Fragment} from "react";

const PropsViewer = ({linea1,linea2})=>{


    return(
        <Fragment>
            <div style={{width:"100%"}}>
                <div className="d-flex justify-content-center">
                    <div style={{display:"flex",flexDirection:"row"}}>
                        {
                            linea1.split("").map((char,indexChar)=>(
                                <div 
                                    key={indexChar}
                                    style={{
                                        width:13,
                                        height:13,
                                        marginLeft:2,
                                        marginRight:2,
                                        padding:2,
                                        backgroundColor:"#fff",
                                        borderRadius:2,
                                        display:"flex",
                                        justifyContent:"center",
                                        alignItems:"center"
                                    }}
                                >
                                    <span key={indexChar} style={{color:"#000",fontSize:10}}>
                                        {char}
                                    </span>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="d-flex justify-content-center mt-2 ">
                    <div style={{display:"flex",flexDirection:"row"}}>
                        {
                            linea2.split("").map((char,indexChar)=>(
                                <div 
                                    key={indexChar}
                                    style={{
                                        width:13,
                                        height:13,
                                        marginLeft:2,
                                        marginRight:2,
                                        padding:2,
                                        backgroundColor:"#fff",
                                        borderRadius:2,
                                        display:"flex",
                                        justifyContent:"center",
                                        alignItems:"center"
                                    }}
                                >
                                    <span key={indexChar} style={{color:"#000",fontSize:10}}>
                                        {char}
                                    </span>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default PropsViewer;