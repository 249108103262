import React from "react";
import { useCartScreen } from "../Context";
import ModalProfesional from "../../All/Modal";
import WebPayPlus from "../../All/WebPayPlus";
import { useCart } from "../../../../Context/ContextCart";

const ModalPago = ()=>{
    const {car} = useCart();
    const {
        showModalPago,
        handleToggleModalPago,
        getTotal,
        Purchase,
        toast
    } = useCartScreen();
    const callback_fail = ()=>{
        toast.error("Error al procesar su pago, Inténtelo nuevamente.");
        handleToggleModalPago(false)
    }
    if(showModalPago){
        return(
            <ModalProfesional 
                title={`PAGO SEGURO`}
                show={showModalPago}
                centrado={true}
                handleClose={()=>handleToggleModalPago(false)}
                size="md"
                Content={()=>
                    <WebPayPlus 
                        llave={"2A86161C90B2CB975F21019831708700"}
                        products={car}
                        total={getTotal(true)}
                        prodKeys={{
                            id_company:"7URA",
                            id_branch:"0004",
                            user:"7URASIUS1",
                            pwd:"T4LU5PJGH"
                        }}
                        production={true}
                        debug={false}
                        callback={Purchase}
                        callback_fail={callback_fail}
                    />
                }
            />
        )
    }
    return null;
}
export default ModalPago;