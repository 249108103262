import React from "react"
import { Container } from "react-bootstrap";
import { CartScreenProvider, useCartScreen } from "./Context";
import ErrorContainer from "../All/ErrorContainer";
import Loader from 'react-loader-spinner';
import Content from "./Content";
import {withRouter} from "react-router-dom";
import { Scrollbars } from 'react-custom-scrollbars';
const CartPage = ({history,toast,user})=>{

    return(
        <div className="backgroundProfesional">
            <Scrollbars style={{display:"flex",width:"100%",height:"100%"}}>
                <Container>
                    <CartScreenProvider user={user} history={history} toast={toast}>
                        <CartTunnel />
                    </CartScreenProvider>
                </Container>
            </Scrollbars>
        </div>
    )
}
export default withRouter(CartPage);
const CartTunnel = ()=>{
    const {
        cartScreenData,
        cartScreenError,
        cartScreenLoader,
        user,
        history
    } = useCartScreen();

    if(cartScreenLoader || (!cartScreenLoader&&!cartScreenData)){
        return(
            <div className="d-flex justify-content-center mt-5">
                <Loader
                    type="Oval"
                    color={"#fff"}
                    height={150}
                    width={150}
                    visible={true}
                />
            </div>
        );
    }
    if(cartScreenError){
        return <ErrorContainer error={cartScreenError} callback={{execute:()=>history.goBack(),label:"Regresar"}} />
    }
    return <Content />;
}