import React from "react";
import {OverlayTrigger,Tooltip} from "react-bootstrap";
import { FaInfo } from "react-icons/fa";
const InputProfesional = (props)=>{
  const p = props;
  const {style} = props;
  const generateID = ()=>{
    return "element_bdk_"+Math.random() * (9999 - 1) + 1;
  }
  return(
          <div className="fila">
            <div className="columna" style={{width:(p.errorMsg)?"90%":"100%"}}>
              <input
                type={(p.password)?"password":"text"}
                className={(p.cls)?"inputProfesional profesionalText "+p.cls:"inputProfesional profesionalText"}
                placeholder={(p.placeholder)?p.placeholder:""}
                style={{...style}}
                value={(p.value)?p.value:""}
                onChange={(p.onChange)?(e)=>p.onChange(e.target.value):(e)=>console.log("value cambio a:",e.target.value)}
                disabled={(props.disabled)?true:false}
              />
            </div>
            {
              (p.errorMsg) ?
                <div className="columna alineado" style={{cursor:"pointer",width:"10%"}}>
                  <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id={generateID()} >{p.errorMsg}</Tooltip>}>
                    <FaInfo style={{color:"#e84630",marginLeft:10}} />
                  </OverlayTrigger>
                </div> : null
            }
          </div>
  )
}

export default InputProfesional;
