import React,{useState,useEffect,Fragment,useRef} from "react";
import {Col,Row,Container,Dropdown, Modal} from "react-bootstrap";
import Magnifier from "react-magnifier";
import {imageDoNotExist, isMobile} from "../../../Utils/Const";
import ModalProfesional from "../All/Modal";
import ContentModalSoldOut from "./ContentModalSoldOut";
import ReactHtmlParser from "react-html-parser";
import { withRouter } from "react-router-dom";
import CepilloGrabado from "./CepilloGrabado";
import MobileProductList from "./MobileProductList";
import "./styles.css";
import WebRoad, { useWebRoad } from "../WebRoad";
import { Scrollbars } from 'react-custom-scrollbars';
import {number_format} from "locutus/php/strings";
const ProductView = (props)=>{
  const {
    product,
    quantity,
    onChange,
    addCart,
    relations,
    texts,
    history,
    caracteres,
    setNewValue,
    modalSoldOut,
    handleToggleSoldOut,
    loadBtn,
    createNotification
  } = props;
  const [isVisibleP,setIsVisibleP] = useState(false);
  const [selectedImage,setSelectedImage] = useState(0);
  const [modalDesc,setModalDesc] = useState(false);
  const handleToggleModal = (param)=>setModalDesc(param);
  const isWeb = !isMobile();
  
  const returnTexts = (name)=>{
    let filtered = texts.filter(el=>el.name===name);
    return  filtered[0] ? filtered[0].value : "Valor no encontrado."
  }
  const returnImgStyles = (index)=>{
    return {
      width:"100%",
      height:"auto",
      borderStyle:index===selectedImage?"solid":null,
      borderWidth:index===selectedImage?2:0,
      borderColor:"#fff",
      cursor:index===selectedImage?"no-drop":"pointer"
    }
  }
  const getCaracteresByLinea = (line)=>{
    let full_string = "";
    if(line===1){
      for (let i = 0; i < 27; i++) {
        const caracter = caracteres[i];
        if(caracter){
          full_string += caracter;
        }else{
          full_string += " ";
        }
      }
    }else{
      for (let i = 27; i < caracteres.length; i++) {
        const caracter = caracteres[i];
        if(caracter){
          full_string += caracter;
        }else{
          full_string += " ";
        }
      }
    }
    return full_string;
  }

  const getGrabadosStyle = (isLine2)=>{
    let styles = {
      fontSize:window.screen.availWidth>=600? 10 : 5
    };
    if(product.esGrabado&&product.posicion_grabados){
      if(isLine2){
        let top = parseFloat(product.posicion_grabados.top) + 2 + "%";
        styles.top = top;
      }else{
        styles.top = product.posicion_grabados.top + "%";
      }
      styles.left = product.posicion_grabados.left + "%";
    }
    return styles;
  }
  const ImgRef = useRef();
  const getMaxHeightRight = ()=>{
    let availWidth = window.screen.availWidth;
    //console.log({availWidth})
    if(availWidth>=1200){
      return 550;
    }else{
      if(availWidth>=1000){
        return 500;
      }else{
        if(availWidth>=800){
          return 400;
        }else{
          if(availWidth>=600){
            return 350;
          }else{
            return 300
          }
        }
      }
    }
  }
  return (
    <div className="backgroundProfesional">
        <Scrollbars style={{display:"flex",width:"100%",height:"100%"}}>
            <ModalProfesional 
              title={`Mayor información:`}
              show={modalDesc}
              handleClose={()=>handleToggleModal(false)}
              size="lg"
              Content={()=><ContentModalDesc desc_long={product.desc_long} />}
            />
            <ModalProfesional 
              title={`Producto agotado`}
              show={modalSoldOut}
              handleClose={()=>handleToggleSoldOut(false)}
              size="lg"
              centrado
              Content={()=><ContentModalSoldOut loadBtn={loadBtn} createNotification={createNotification} handleClose={()=>handleToggleSoldOut(false)} />}
            />
            <Container>
              {!isMobile()?<WebRoad level={4} />:null}
              <Row className="mt-2">
                <Col xs="12" xl="7">
                    <div>

                      {
                        product.images.length > 0 ?
                          product.esGrabado ?
                            <Fragment>
                              <img ref={ImgRef} style={{width:"100%",height:"auto"}} src={product.images[selectedImage].url} />
                              <div className="container-image-grabado-line1" style={{...getGrabadosStyle()}}>
                                <span className={window.screen.availWidth>=800?"text-image-grabado":"text-image-grabado-mobile"}>
                                  {
                                    getCaracteresByLinea(1)
                                  }
                                </span>
                              </div>
                              <div className="container-image-grabado-line2" style={getGrabadosStyle(true)}>
                                <span className={window.screen.availWidth>=800?"text-image-grabado":"text-image-grabado-mobile"}>
                                  {
                                    getCaracteresByLinea(2)
                                  }
                                </span>
                              </div>
                            </Fragment> :
                              <Fragment>
                                <Magnifier src={product.images[selectedImage].url} width={"auto"} mgWidth={300} mgHeight={300} zoomFactor={1.2} />
                                <div className="d-flex justify-content-center mt-2" style={{height:"100%"}}>
                                  {
                                    product.images.length > 0 ?
                                    <Row>
                                      {
                                        product.images.map((item,index)=>(
                                          <Col xs="3" key={index}>
                                            <div style={{width:"100%",padding:5}}>
                                              <img src={item.url} onClick={()=>setSelectedImage(index)} style={returnImgStyles(index)} />
                                            </div>
                                          </Col>
                                        ))
                                      }
                                    </Row> : 
                                    <span style={{fontWeight:"bold",fontSize:20,color:"#fff"}}>
                                      El producto no contiene imágenes.
                                    </span>
                                  }
                                </div>
                              </Fragment> :
                                <Magnifier src={imageDoNotExist} width={"auto"} mgWidth={300} mgHeight={300} zoomFactor={1.2} />
                      }
                    </div>
                  
                </Col>
                <Col xs="12" xl="5" style={{height:isWeb&&product.esGrabado?getMaxHeightRight():"100%"}}>
                  <span style={{fontWeight:"bold",fontSize:20,color:"#fff"}}>{product.label}</span><br></br>
                  <span className="mt-2" style={{fontSize:14,color:"#fff"}}>SKU: {product.P_Codigo}</span><br></br>
                  {
                    isWeb&&product.esGrabado? 
                  <Scrollbars style={{width:"100%",height:"100%"}}>
                    <TextComponent desc_long={product.desc_short} color={"#fff"} />
                    
                    <button className="btn btn-dark btn-block mt-3" onClick={()=>handleToggleModal(true)} style={{borderRadius:20,backgroundColor:"#686868"}}>{"Leer mas"}</button>
                  </Scrollbars> 
                  : 
                  <Fragment>
                    <span className="mt-2" style={{fontSize:14,color:"#fff"}}>Existencia: {parseInt(product.existencias)}</span>
                    <TextComponent desc_long={product.desc_short} color={"#fff"} />
                    
                    <button className="btn btn-dark btn-block mt-3" onClick={()=>handleToggleModal(true)} style={{borderRadius:20,backgroundColor:"#686868"}}>{"Leer mas"}</button>
                  </Fragment>
                  }
                </Col>
                {
                  product.esGrabado ? 
                  <Col xs="12" className="mt-4">
                    <CepilloGrabado caracteres={caracteres} setNewValue={setNewValue}/>
                  </Col> : null
                }
                
                <Col xs="12" className="mt-3">
                  <div className="table-responsive" style={{marginTop:0,width:"100%"}}>
                    <span style={{fontSize:12,color:"#fff"}}>
                        {returnTexts("TEXTO SUPERIOR PRODUCTOS RELACIONADOS")}
                    </span>
                    <TableContainer isWeb={isWeb} returnTexts={returnTexts} {...props} />
                    <p style={{textAlign:"right",fontSize:12,color:"#fff"}}>
                      {returnTexts("TEXTO INFERIOR PRODUCTOS RELACIONADOS")}
                    </p>
                  </div>
                </Col>

              </Row>
          </Container>
        </Scrollbars>
    </div>
  )
}
const TableContainer = (props)=>{
  const {product,returnTexts,quantity,onChange,addCart,relations,history,isWeb} = props;
  const {updateRoads} = useWebRoad();
  const [relacionados,setRelacionados] = useState([]);
  const getVal = (val)=>{
    if(!isNaN(val)){
      return parseFloat(val).toFixed(2)
    }else{
      return 0;
    }
  }
  const changeQuanity = (i,val) => {
    let newValues = relacionados.map((item,index)=>{
      if(index===i){
        if(!isNaN(val)){
          item.quantity = parseInt(val);
          return item;
        }else{
          return item;
        }
      }else{
        return item;
      }
    });
    setRelacionados(newValues.concat());
  }
  const returnProductCost = (precio,iva)=>{
    //console.log(producto)
    let iva_total = parseFloat(iva);
    let costo = parseFloat(precio);
    if(costo>0){
      return "$"+number_format(parseFloat(costo-iva_total),2)
    }else{
      return "- - -"
    }
  }
  useEffect(()=>{
    updateRoads({
        level:4,
        element:{
            name:product.label,
            value:product.id,
        }
    })
    if(relations!==relacionados){
      setRelacionados(relations)
    }
  },[relations])
  //console.log({isMobile:isMobile()})
  if(isMobile()){
    return <MobileProductList {...props} />
  }
    return(
      <table style={tableStyles.root} >
            <thead>
              <tr align="center">
                <th style={tableStyles.th}>CÓDIGO</th>
                <th style={tableStyles.th}>MODELO</th>
                <th style={tableStyles.th}>{returnTexts("CARACTERISTICAS")}</th>
                <th style={tableStyles.th}>UNIDADES</th>
                <th style={tableStyles.th}>PRECIOS</th>
                <th style={tableStyles.th}>AGREGAR AL CARRITO</th>
              </tr>
              <tr>
                <th style={tableStyles.td} colSpan="4">
                &nbsp;
                </th>
                <th style={tableStyles.td} colSpan="1">
                <table style={{width:"100%",minWidth:200}}>
                    <tbody>
                      <tr align="center">
                        <th style={{width:"33%",...tableStyles.th}}>Público</th>
                        <th style={{width:"34%",...tableStyles.th}}>Profesional</th>
                        <th style={{width:"33%",...tableStyles.th}}>Mayoreo</th>
                      </tr>
                    </tbody>
                  </table>
                </th>
                <th style={tableStyles.td} colSpan="4">
                &nbsp;
                </th>
              </tr>
            </thead>
            <tbody>
              <tr align="center">
                <td style={tableStyles.td}>
                  {product.P_Codigo}
                </td>
                <td style={tableStyles.td}>
                  {product.label}
                </td>
                <td style={tableStyles.td}>{/* Caracteristicas */}
                {
                  <span>
                  {
                    product.features.length > 0 ?
                    product.features.map((item,index)=>(
                        <Fragment key={index}>
                            {item.caracteristica},
                        </Fragment>
                      )) : 
                      <span>
                        Características de producto no disponible
                      </span>
                    }
                  </span>
                  
                }
                </td>
                <td style={tableStyles.td}>{/* Unidades */}
                  {parseInt(product.unidades)}
                </td>
      
                <td style={tableStyles.td}>{/* Precio a profesional */}
                  <table style={{width:"100%",minWidth:200}}>
                    <tbody>
                      <tr align="center">
                        <td style={{...tableStyles.td,width:"33%"}}>{/* Precio a publico */}
                          {returnProductCost(product.costo,0)}
                        </td>
                        <td style={{...tableStyles.td,width:"34%"}}>{returnProductCost(product.Precio_B,product.ivamb)}</td>
                        <td style={{...tableStyles.td,width:"33%"}}>{returnProductCost(product.Precio_C,0)}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td style={tableStyles.td}>{/* Agregar al carrito */}
                  <div className="d-flex justify-content-center">
                    <div style={tableStyles.container_add}>
                      <input type="text" style={tableStyles.input} value={quantity} onChange={(e)=>onChange(e)} />
                      <span onClick={()=>addCart()} style={{color:"#fff",marginLeft:10,marginTop:5,fontSize:13,cursor:"pointer"}}>
                        Agregar
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
              {
                relacionados.map((item,index)=>(
                    <tr align="center" key={index}>
                      <td style={tableStyles.td}>
                        {item.P_Codigo}
                      </td>
                      <td style={tableStyles.td}>
                        <a onClick={()=>history.push("/products/"+item.P_Id)} style={{color:"#33cc66",cursor:"pointer"}}>
                          {item.P_Nombre}
                        </a>
                      </td>
                      <td style={tableStyles.td}>{/* Caracteristicas */}
                      {
                        <span>
                        {
                            item.features.length > 0 ?
                            item.features.map((item,index)=>(
                              <Fragment key={index}>
                                  {item.caracteristica},
                              </Fragment>
                            )) : 
                            <span>
                              Características de producto no disponible
                            </span>
                        }
                        </span>
                        
                      }
                      </td>
                      <td style={tableStyles.td}>{/* Unidades */}
                        {parseInt(item.unidades)}
                      </td>
                      <td style={tableStyles.td}>{/* Precio a profesional */}
                        <table style={{width:"100%"}}>
                          <tbody>
                            <tr align="center">
                              <td style={{...tableStyles.td,width:"33%"}}>{/* Precio a publico */}
                                {returnProductCost(item.costo,0)}
                              </td>
                              <td style={{...tableStyles.td,width:"34%"}}>{returnProductCost(item.Precio_B,item.ivamb)}</td>
                              <td style={{...tableStyles.td,width:"33%"}}>{returnProductCost(item.Precio_C,item.ivamc)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style={tableStyles.td}>{/* Agregar al carrito */}
                        <div className="d-flex justify-content-center">
                          <div style={tableStyles.container_add}>
                            <input type="text" style={tableStyles.input} value={!isNaN(item.quantity)?item.quantity:0} onChange={(e)=>changeQuanity(index,e.target.value)} />
                            <span onClick={()=>addCart(item)} style={{color:"#fff",marginLeft:10,marginTop:5,fontSize:13,cursor:"pointer"}}>
                              Agregar
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                ))
              }
            </tbody>
          </table>
    )
}
const tableStyles = {
  root:{
    width:"100%",
    marginTop:5
  },
  th:{
    borderStyle:"solid",
    borderWidth:1,
    borderColor:"#fff",
    color:"#fff",
    fontSize:13
  },
  td:{
    borderStyle:"solid",
    borderWidth:1,
    borderColor:"#fff",
    color:"#fff",
    fontSize:12
  },
  container_add:{
    display:"flex",
    flexDirection:"row"
  },
  input:{
    width:70,
    textAlign:"center",
    background: "#686868",
    color:"#fff",
    fontWeight:"bold"
  }
}
const ContentModalDesc = ({desc_long})=>{
  return(
    <div className="container">
        {
          desc_long ? <TextComponent desc_long={desc_long} /> : <p>Descripción de producto no disponible.</p>
        }
    </div>
  )
}
const TextComponent = (props)=>{
  const {desc_long,color} = props;
  return(
    <div className="profesionalText" style={{color:color?color:"gray",fontSize:12,fontWeight:"normal"}}>
      {
        ReactHtmlParser(desc_long)
      }
    </div>
  );
}
export default withRouter(ProductView);
/* 
 <p className="mt-2" style={{fontSize:15,color:"#fff"}}>$ {(product.costo)?product.costo:"0.00"}</p>
              <input type="text" className="inputProfesional placeholderCentrado" style={{width:70}} value={quantity} onChange={(e)=>onChange(e)}/>
              <button className="btn btn-light btn-block mt-3" onClick={addCart} style={{borderRadius:20}}>Añadir al Carrito</button>
<Col xs="10">
                  <p style={{fontWeight:"bold",fontSize:25,color:"#fff"}}>{"Personalizar producto"}</p>
                </Col>
                <Col xs="2" className="d-flex justify-content-end">
                  {
                    (isVisibleP) ? <span style={{fontSize:25,cursor:"pointer",color:"#fff"}} onClick={()=>setIsVisibleP(false)}>-</span> : <span style={{fontSize:25,cursor:"pointer",color:"#fff"}} onClick={()=>setIsVisibleP(true)}>+</span>
                  }
                </Col>
 <Col xs="12" style={{display:(isVisibleP)?"block":"none"}}>
                  <Row>
                    <Col xs="12" md="4" lg="4">
                      <p style={{fontSize:16,color:"#fff"}}>{"Cliente / Negocio"}</p>
                    </Col>
                    <Col xs="12" md="8" lg="8">
                      <input className="inputDelgado" value={clienteTag} onChange={(e)=>setClienteTag(e.target.value)} type="text" style={{width:"100%",height:23}} />
                    </Col>
                    <Col xs="12" md="4" lg="4" style={{marginTop:-10}}>
                      <p style={{fontSize:16,color:"#fff"}}>{"Teléfono"}</p>
                    </Col>
                    <Col xs="12" md="8" lg="8" style={{marginTop:-10}}>
                      <input className="inputDelgado" value={telefono} onChange={(e)=>setTelefono(e.target.value)} type="text" style={{width:"100%",height:23}} />
                    </Col>
                    <Col xs="12" className="text-center">
                      <p style={{fontSize:16,color:"#fff"}}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      </p>
                    </Col>
                  </Row>
                  <Dropdown.Divider />
                </Col>

                
                      <SimpleImageSlider
                        width={150}
                        height={150}
                        showNavs={true}
                        images={product.images}
                        onCompleteSlide={(idx, length) => setSelectedImage(idx-1) }
                      />
*/