import React from 'react';
import Axios from 'axios';
import { Const } from '../../../../Utils/Const';
const Validate = React.createContext();
export const ValidateProvider = (props)=>{
    const {toast,history} = props;
    const [screenData,setScreenData] = React.useState(null);
    const [screenError,setScreenError] = React.useState(null);
    const [screenLoad,setScreenLoad] = React.useState(false);
    const handleScreenLoad = (param)=>setScreenLoad(param);
    const searchData = async(showLoad)=>{
        if(showLoad){
            handleScreenLoad(true);
        }
        let postData = {
            type:"get-user-validation",
            token:localStorage.conection||null
        }
        let r = await Axios.post(Const.backend,postData).catch(e=>{
            console.log(e);
            alert("Servidor no disponible.");
        })
        setTimeout(()=>{
            if(r){
                console.log({
                    data:r.data
                })
                if(r.data.error){
                    setScreenError(r.data.error);
                }else{
                    setScreenData(r.data.result);
                }
            }
            if(screenLoad){
                handleScreenLoad(false);
            }
        },500)
    }
    React.useEffect(()=>{
        if(!screenData){
            searchData(true)
        }
    },[])
    const value = React.useMemo(()=>{
        return({
            toast,
            history,
            screenData,
            screenError,
            screenLoad,
            searchData
        })
    },[screenData,screenError,screenLoad])
    return <Validate.Provider value={value} {...props}/>
}
export const useValidate = ()=> React.useContext(Validate);