import React, {Fragment} from "react";
import { useCepGrabContext } from "./Store";
import "./styles.css";

const InputCepGrab = ({caracter,indexCaracter})=>{
    const {
        setValue,
        changeToPrev,changeToNext,
        ref1,ref2,ref3,ref4,ref5,ref6,ref7,ref8,ref9,ref10,ref11,ref12,ref13,ref14,ref15,ref16,ref17,ref18,ref19,ref20,ref21,ref22,ref23,ref24,ref25,ref26,ref27,
        ref28,ref29,ref30,ref31,ref32,ref33,ref34,ref35,ref36,ref37,ref38,ref39,ref40,ref41,ref42,ref43,ref44,ref45,ref46,ref47,ref48,ref49,ref50,ref51,ref52,ref53,ref54
    } = useCepGrabContext();
    let execute = (value)=>{
        let lastChar = "";
        if(value){
            lastChar = value.substr(-1);
            setValue({
                index:indexCaracter,
                value:lastChar
            })
            changeToNext(indexCaracter);
        }else{
            setValue({
                index:indexCaracter,
                value:lastChar
            })
        }
    }
    const getRef = ()=>{
        switch (indexCaracter) {
            case 0: return ref1;
            case 1: return ref2;
            case 2: return ref3;
            case 3: return ref4;
            case 4: return ref5;
            case 5: return ref6;
            case 6: return ref7;
            case 7: return ref8;
            case 8: return ref9;
            case 9: return ref10;
            case 10: return ref11;
            case 11: return ref12;
            case 12: return ref13;
            case 13: return ref14;
            case 14: return ref15;
            case 15: return ref16;
            case 16: return ref17;
            case 17: return ref18;
            case 18: return ref19;
            case 19: return ref20;
            case 20: return ref21;
            case 21: return ref22;
            case 22: return ref23;
            case 23: return ref24;
            case 24: return ref25;
            case 25: return ref26;
            case 26: return ref27;
            case 27: return ref28;

            case 28: return ref29;
            case 29: return ref30;
            case 30: return ref31;
            case 31: return ref32;
            case 32: return ref33;
            case 33: return ref34;
            case 34: return ref35;
            case 35: return ref36;
            case 36: return ref37;
            case 37: return ref38;
            case 38: return ref39;
            case 39: return ref40;
            case 40: return ref41;
            case 41: return ref42;
            case 42: return ref43;
            case 43: return ref44;
            case 44: return ref45;
            case 45: return ref46;
            case 46: return ref47;
            case 47: return ref48;
            case 48: return ref49;
            case 49: return ref50;
            case 50: return ref51;
            case 51: return ref52;
            case 52: return ref53;
            default: return ref54;
        }
    }
    const checkDelete = (e)=>{
        if(e.key==="Backspace"){
            changeToPrev(indexCaracter);
        }
    }
    return(
        <Fragment>
            <div className="input-prof-grab-container">
                <input 
                    className="input-prof-grab"
                    value={caracter||""}
                    onChange={(e)=>execute(e.target.value)}
                    onKeyUp={(e)=>checkDelete(e)}
                    ref={getRef()}
                />
            </div>
        </Fragment>
    )
}
export default InputCepGrab;