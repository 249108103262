import React, {useState,useMemo,useEffect} from "react";
import axios from "axios";
import {Const} from "../../../Utils/Const";
import { useCart } from "../../../Context/ContextCart";

const OrderContext = React.createContext();

export const OrderProvider = (props)=>{
  const {id_order,user,toast} = props;
  const [order,setOrder] = useState(null);
  const {addMultipleItems,cleanCart} = useCart();
  const [auth,setAuth] = useState(null);
  const [loadingOrder,setLoadingOrder] = useState(false);
  const [errorMsg,setErrorMsg] = useState(null);
  const [isSearching,setIsSearching] = useState(false);
  const [banner,setBanner] = useState(null);


  const searchProducts = async()=>{
    setIsSearching(true);
    let r = await axios.post(Const.backend,{
      type:"getOrderProducts",
      order
    }).catch(e=>toast.error("Error al conectar al servidor, intente de nuevo mas tarde"))
    if(r&&r.data){
      let response = r.data;
      if(response.error){
        toast.error(response.error)
      }else{
        cleanCart();
        let result = response.result;
        addMultipleItems(result.car);
        toast.info("Se añadieron tus productos al carrito de compra.")
      }
    }
    setIsSearching(false);
  }
  const searchOrder = async()=>{
    setLoadingOrder(true);
    let r = await axios.post(Const.backend,{type:"orderData",folio:id_order,token:user}).catch(e=>{
      setErrorMsg("Error al conectar al servidor");
      setAuth(false);
    })
    if(r){
      if(r.data.error){
        setErrorMsg("Error del servidor: "+r.data.error);
        setAuth(false);
      }else{
        setOrder(r.data.order);
        setBanner(r.data.banner);
        setAuth(r.data.auth);
      }
    }
    setLoadingOrder(false);
  }
  useEffect(()=>{
    if(id_order){
      if(!order){
        searchOrder()
      }
    }else{
      setAuth(false);
      setOrder(null);
    }
  },[order])
  const value = useMemo(()=>{
    return({
      order,searchOrder,auth,loadingOrder,errorMsg,searchProducts,isSearching,banner
    })
  },[order,loadingOrder,isSearching,banner])
  return <OrderContext.Provider value={value} {...props}/>
}
export const useOrder = ()=>{
  return React.useContext(OrderContext);
}
