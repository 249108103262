import React, {useState,useMemo,useEffect} from "react";
import { REQUEST, GENERATESTRING, GENERATEXML, ENCRYPTDATA, descifradoAES } from "./Constants";
import { Const } from "../../../../Utils/Const";
import CryptoJS from "crypto-js";

const WebPayPlusContext = React.createContext();

export const WebPayPlusProvider = (props)=>{
    const {llave,products,total,production,prodKeys,callback,callback_fail} = props;
    const [url,setUrl] = useState(null);
    const [error,setError] = useState(null);
    const [load,setLoad] = useState(false);
    const [reference,setReference] = useState(null);
    const [firstTime,setFirstTime] = useState(true);
    const handleLoad = (param)=>setLoad(param);

    const checkPaymentStatus = async()=>{
        if(reference){
            let r = await REQUEST({
                url:Const.backend,
                postData:{
                    type:"checkPaymentStatus",
                    reference
                }
            })
            if(r.error){
                console.log("Error de servidor:",r.error)
            }else{
                if(r.response){
                    if(r.approved){
                        if(firstTime){
                            callback(reference);
                        }
                        setFirstTime(false)
                    }else{
                        callback_fail();
                    }
                }
            }
        }
    }
    const getUrl = async(ref)=>{
        console.log("REFERENCE_URL:",ref)
        const texto_datos = GENERATESTRING({products,total,prodKeys,production,reference:ref});
        const encryptedString = ENCRYPTDATA(texto_datos,llave);
        const xmlString = GENERATEXML(encryptedString,production);
        console.log({xmlString,texto_datos})
        let r = await REQUEST({
            url:Const.backend,
            postData:{
                type:"getWebPayPlusUrl",
                cypherText:xmlString,
                production_payment:production
            },
            handleLoad
        })
        if(r.error){
            console.log("Error de servidor:",r)
            setError(r.error);
        }else{
            let decrypted = descifradoAES(r.urlEncoded,llave);
            let testArrayString = decrypted.split("<nb_url>");
            if(testArrayString.length>1){
                console.log("arrayStgring",testArrayString);
                decrypted = decrypted.split("<nb_url>")[1].split("</nb_url>")[0];
                setUrl(decrypted);
            }else{
                setError(decrypted.toString(CryptoJS.enc.Utf8))
            }
        }
    }
    const getReference = async()=>{
        let r = await REQUEST({
            url:Const.backend,
            postData:{
                type:"getWebPayPlusReference",
                token:localStorage.conection||null
            },
            handleLoad
        })
        if(r.error){
            console.log("Error de servidor:",r)
            setError(r.error);
        }else{
            console.log("REFERENCE_POST",r.reference)
            setReference(r.reference);
            getUrl(r.reference);
        }
    }

    useEffect(()=>{
        if(!url){
            getReference();
        }
    },[url])
    const value = useMemo(()=>{
        return({
            url,
            load,
            error,
            checkPaymentStatus
        })
    },[url,load,error])
    return <WebPayPlusContext.Provider value={value} {...props}/>
}

export const useWebPayPlus = ()=>{
    return React.useContext(WebPayPlusContext);
}

