export const idioms =
[
  {
    countries:["MX"],
    name: "Español",
    views:{
      Home:{
        saludo: "Estas en Inicio",
        modulo: "Inicio",
        vista: "Inicio"
      },
      Navbar:{
        text:{
          myprofile:"Mi Cuenta",
          login:"Iniciar Sesión",
          register:"Registrarme",
          contact: "Contacto",
          categories:"Categorías",
          nosotros:"Nosotros",
          campos:"Campos"
        },
        inputs:{
          searchPlaceholder:"Buscar"
        }
      },
      login:{
        text:{
          login:"Iniciar Sesión",
          code: "Inicia sesión con un código promocional",
          forgot: "Olvidé mi contraseña",
          iniciar: "Iniciar"
        },
        inputs:{
          placeholderUser: "Usuario / Email",
          placeholderPsw: "Contraseña",
          placeholderCodigo:"Ingresa aquí tu código"
        }
      },
      register:{
        text:{
          register: "Registrar",
          register2: "Registro",
          privacy: "Su información personal siempre sera tratada y de acuerdo con las leyes, las directivas, los reglamentos y los principios aplicables a la protección de datos personales."
        },
        inputs:{
          phUser:"Nombre(s) Apellido(s)",
          phEmail: "Correo electrónico",
          phTel: "Teléfono",
          phEsp: "Especialidad",
          phDir: "Dirección",
          phPostal: "Código postal",
          phCity: "Ciudad",
          phState: "Estado"
        }
      }
    }
  },
  {
    countries:["US"],
    name: "English",
    views:{
      Home:{
        saludo: "You are in Home",
        modulo: "Inicio",
        vista: "Inicio"
      },
      Navbar:{
        text:{
          myprofile:"My Account",
          login:"Log in",
          register:"Register",
          contact: "Contact",
          categories:"Categories",
          nosotros:"We",
          campos:"Fields"
        },
        inputs:{
          searchPlaceholder:"Search"
        }
      },
      login:{
        text:{
          login:"Log In",
          code: "Sign in with a promotional code",
          forgot: "I forgot my password",
          iniciar: "Start"
        },
        inputs:{
          placeholderUser: "User / Email",
          placeholderPsw: "Password",
          placeholderCodigo:"Enter your code here"
        }
      },
      register:{
        text:{
          register: "Register",
          register2: "Register",
          privacy: "Su información personal siempre sera tratada y de acuerdo con las leyes, las directivas, los reglamentos y los principios aplicables a la protección de datos personales."
        },
        inputs:{
          phUser:"Name and second name",
          phEmail: "Email",
          phTel: "Telephone",
          phEsp: "Specialty",
          phDir: "Address",
          phPostal: "Postal Code",
          phCity: "City",
          phState: "state"
        }
      }
    }
  }
]
