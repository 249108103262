import React,{Fragment} from "react";
import {Container,Row,Col} from "react-bootstrap";
import { useProfile } from "./Store";
import Divider from "../All/Divider";
import "./styles.css";
const Content = ()=>{
    const {user} = useProfile();
    return(
        <Fragment>
            <Container>
                <Row className="mt-2">
                    <Col xs="12">
                        <Title />
                        <List />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

const Title = ()=>{
    return(
        <Fragment>
            <p className="title-profile">
                MI CUENTA
            </p>
            <Divider />
        </Fragment>
    )
}
const List = ()=>{
    return(
        <div className="list-profile-container">
            <Element name="Mi perfíl" to="myprofile" />
            <Element name="Mis compras" to="myshopping"/>
            <Element name="Compra rápida" to="fastbuy"/>
            <Element name="Mis favoritos" to="myfavourites"/>
            {/* <Element name="Estatus de compras" disabled/> */}
            <Element name="Facturación" to="billing"/>
            {/* <Element name="Personalizar producto" disabled/> */}
            {/* <Element name="Aclaraciónes" to="aclaraciones"/> */}
            <Element name="Validación" to="validate"/>
        </div>
    )
}
const Element = ({name,to,disabled})=>{
    const {history} = useProfile();
    const nav = ()=>{
        if(disabled){
            alert("Contenido en desarrollo.")
        }else{
            history.push(to);
        }
    }
    return(
        <div className="list-profile-element">
            <span onClick={nav} className={`list-profile-text ${disabled?"list-profile-disabled":""}`}>
                {name}
            </span>
        </div>
    )
}
export default Content;