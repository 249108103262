import React,{Fragment} from "react";
import Button from "../Button";
import "./styles.css";
const ErrorContainer = ({error,callback})=>{

    return(
        <Fragment>
            <div className="d-flex justify-content-center">
                <div className="error-prof-container" style={{backgroundClip:"#a1a1a1",borderColor:"#171717"}}>
                    <span className="error-prof-text">
                        {error}
                    </span>
                </div>
            </div>
            {
                callback ? 
                <div className="d-flex justify-content-center">
                    <Button execute={callback.execute} type="success">
                        {callback.label||"Sin acción"}
                    </Button>
                </div> : null
            }
        </Fragment>
    )
}
export default ErrorContainer;