import React from "react";
import { useStore } from "../Store";
import "./styles.css";
import {Row,Col} from "react-bootstrap";
import Loader from 'react-loader-spinner';
const Load = ()=>{
    return(
        <div className="d-flex justify-content-center mt-3">
            <div>
                <Loader
                    type="Oval"
                    color={"#1a1a1a"}
                    height={75}
                    width={75}
                    visible={true}
                />
                <span>
                    Buscando..
                </span>
            </div>
        </div>
    )
}
const ErrorContiner = ()=>{
    const {error} = useStore();
    return(
        <div className="d-flex justify-content-center">
            <div className="nav-error-container">
                {error}
            </div>
        </div>
    )
}
const FamContainer = ()=>{
    const {selectedCat,isLoading,families} = useStore();
    return(
        <div className="nav-fam-container">
            {
                isLoading ? 
                    <Load /> : 
                        selectedCat&&families.length > 0 ? 
                            families.map((family,indexElement)=>(
                                <FamOrProductContainer element={family} />
                            )) : <ErrorContiner />
            }
        </div>
    )
}

const FamOrProductContainer = ({element})=>{
    let {needhref,history} = useStore();
    const {elements} = element;
    const nav = ()=>{
        if(needhref){
            window.location.href = "/family?id="+ element.id_elemento
        }else{
            history.push({
                pathname: "/family",
                search: '?id='+element.id_elemento
            });
        }
    }
    if(element.tipo_elemento){
        return (
            <Row>
                <ProductViewer product={element} />
            </Row>
        )
    }
    return(
        <div className="nav-fam-container-title">
            <span onClick={nav} className="fam-text-title">
                {element.nombre_elemento}
            </span>
            <Row>
                {
                    elements.map((el,indexEl)=>(
                        <SubcOrProductContainer element={el} key={indexEl} />
                    ))
                }
            </Row>
        </div>
    )
}

const SubcOrProductContainer = ({element})=>{
    let {needhref,history} = useStore();
    const nav = ()=>{
        if(needhref){
            window.location.href = "/subcategories?id="+ element.id_elemento
        }else{
            history.push({
                pathname: "/subcategories",
                search: '?id='+element.id_elemento
            });
        }
    }
    if(element.tipo_elemento){
        return <ProductViewer product={element} />
    }
    return(
        <Col xs="6">
            <span onClick={nav} className="fam-text-item">
                {element.nombre_elemento}
            </span>
        </Col>
    )
}

const ProductViewer = ({product})=>{
    let {needhref,history} = useStore();
    const nav = ()=>{
        if(needhref){
            window.location.href = "/products/"+ product.id_elemento
        }else{
            history.push({
                pathname: "/products/"+product.id_elemento
            });
        }
    }
    return(
        <Col xs="12" style={{marginTop:5}}>
            <span onClick={nav} className="nav-prod-text">
                {product.nombre_elemento}
            </span>
        </Col>
    )
}
export default FamContainer;