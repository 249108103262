import React from 'react';
import { Const } from '../../../../Utils/Const';
import axios from "axios";
import md5 from 'md5';
const ModalPsw = React.createContext();
export const ModalPswProvider = (props)=>{
    const {toast} = props;
    const [email,setEmail] = React.useState("");
    const [code,setCode] = React.useState("");
    const [idUser,setIdUser] = React.useState(false);
    const [pswChanged,setPswChanged] = React.useState(false);
    const [psw1,setPsw1] = React.useState("");
    const [psw2,setPsw2] = React.useState("");
    const [isLoading,setIsLoading] = React.useState(false);
    const handleLoading = (arg)=>setIsLoading(arg);
    const url = Const.backend;
    const containNumbers = (text)=>{
        var numbers="0123456789";
        for(var i=0; i<text.length; i++){
           if (numbers.indexOf(text.charAt(i),0)!==-1){
              return true;
           }
        }
        return false;
     }
     const containMayus = (text) => {
        var mayus="ABCDEFGHIYJKLMNÑOPQRSTUVWXYZ";
        for(var i=0; i<text.length; i++){
           if (mayus.indexOf(text.charAt(i),0)!==-1){
              return true;
           }
        }
        return false;
     }
    const checkPsw = ()=>{
        if (!psw1) {
          return "Inserte su contraseña.";
        }else{
          if (containMayus(psw1)) {
            if (containNumbers(psw1)) {
              if(psw1.length>=8){
                return null;
              }else{
                return "La contraseña debe contener al menos 8 caracteres";
              }
            }else{
              return "La contraseña debe contener al menos un número";
            }
          }else{
            return "La contraseña debe contener una letra mayuscula";
          }
        }
      }
      const checkPsw2 = ()=>{
        if (psw1===psw2) {
          return null;
        }else{
          return "Las contraseñas no coinciden";
        }
      }
    const sendCode = async()=>{
        let postData = {
            type:"send-code-psw",
            email:email.trim()
        }
        handleLoading(true);
        let r = await axios.post(url,postData).catch(e=>{
            toast.error("Error al conectar al servidor.")
        })
        if(r&&r.data){
            if(r.data.error){
                toast.error(r.data.error);
            }else{
                setIdUser(r.data.id);
                toast.info(`Enviamos un código de confirmación a '${email}'.`)
            }
        }
        setTimeout(()=>handleLoading(false),1000)
        
    }
    const checkCode = async()=>{
        let postData = {
            type:"check-code-psw",
            idUser,
            code,
            newPsw:md5(psw1)
        }
        handleLoading(true);
        let r = await axios.post(url,postData).catch(e=>{
            toast.error("Error al conectar al servidor.")
        })
        if(r&&r.data){
            if(r.data.error){
                toast.error(r.data.error);
            }else{
                setPswChanged(true);
                toast.success("Éxito.")
            }
        }
        handleLoading(false);
    }
    const checkCodeError = ()=>{
        if(!isNaN(code)){
            if(code.length===6){
                return null
            }else{
                return "El código debe contener 6 dígitos."
            }
        }else{
            return "El código solo debe contener 6 números."
        }
    }
    const value = React.useMemo(()=>{
        return({
            email,setEmail,
            code,setCode,
            psw1,setPsw1,
            psw2,setPsw2,
            isLoading,
            idUser,
            pswChanged,
            handleLoading,
            sendCode,
            checkCode,
            checkCodeError,
            checkPsw,
            checkPsw2
        })
    },[email,code,isLoading,idUser,pswChanged,psw1,psw2])
    return <ModalPsw.Provider value={value} {...props}/>
}
export const useModalPsw = ()=> React.useContext(ModalPsw);