import React from "react";
import {colors} from "../../../Utils/Const";

const Divider = (props)=>{
  const {style} = props;
  const dividerStyle = {
    width:"100%",
    borderStyle:"solid",
    borderColor: "#fff",
    borderWidth:2,
    ...style
  }
  return(
    <div style={dividerStyle}>
    </div>
  )
}
export default Divider;
