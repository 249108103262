import React, { Fragment } from "react";
import {Row,Col} from "react-bootstrap";
import Divider from "../../All/Divider";
import Input from "../../All/InputV2";
import { useFastBuy } from "./store";
import Button from "../../All/Button";

const Content = ()=>{

    return(
        <Fragment>
            <Header />
            <Body />
            <Footer />
        </Fragment>
    )
}
const Header = ()=>{

    return(
        <Fragment>
            <div className="d-flex justify-content-start mt-3">
                <span style={{fontSize:20,fontWeight:"bold",color:"#fff"}}>
                    COMPRA RÁPIDA
                </span>
            </div>
            <div className="d-flex justify-content-start mt-1 mb-1">
                <span style={{fontSize:13,fontWeight:"bold",color:"#fff"}}>
                    Llena los siguientes espacios con el código del producto y la cantidad, al concluir presiona añadir al carrito.
                </span>
            </div>
            <Divider />
            <div style={{height:20}}></div>
        </Fragment>
    )
}
const Body = ()=>{
    const {
        val1,setVal1,can1,setCan1,
        val2,setVal2,can2,setCan2,
        val3,setVal3,can3,setCan3,
        val4,setVal4,can4,setCan4,
        val5,setVal5,can5,setCan5,
        val6,setVal6,can6,setCan6,
        val7,setVal7,can7,setCan7,
        val8,setVal8,can8,setCan8,
        val9,setVal9,can9,setCan9,
        val10,setVal10,can10,setCan10,
    } = useFastBuy()

    
    return(
        <Fragment>
            <BodyElement val={val1} setVal={setVal1} can={can1} setCan={setCan1} />
            <div style={{height:20}}></div>
            <Divider />
            <div style={{height:20}}></div>
        </Fragment>
    )
}
const BodyElement = ({val,setVal,can,setCan})=>{
    const {
        checkCant,
        getProductName,
        checkProductError,
        checkCode
    } = useFastBuy();
    const setQuantity = (value)=>{
        if(value){
            if(!isNaN(value)){
                setCan(value)
            }
        }else{
            setCan(value);
        }
    }
    
    return(
        <Fragment>
            <Row className="mt-4">
                <Col xs="12" md="3">
                    <Input 
                        label="Código de producto"
                        value={val}
                        setValue={setVal}
                        useLabelWhite
                        error={checkCode(val)}
                    />
                </Col>
                <Col xs="12" md="7">
                    <Input 
                        label="Nombre del producto"
                        value={getProductName(val)}
                        useLabelWhite
                        error={checkProductError(val)}
                        disabled
                    />
                </Col>
                <Col xs="12" md="2">
                    <Input 
                        label="Cantidad"
                        value={can}
                        setValue={setQuantity}
                        useLabelWhite
                        error={checkCant(can)}
                        disabled={!getProductName(val)}
                    />
                </Col>
            </Row>
        </Fragment>
    )
}
const Footer = ()=>{
    const {addToCart,checkFormErrors,loadCart} = useFastBuy();
    return(
        <div className="d-flex justify-content-end">
            <Button type="success" execute={addToCart} disabled={checkFormErrors()||loadCart}>
                {!loadCart?"Añadir al carrito":"Verificando existencias.."}
            </Button>
        </div>
    )
}
export default Content;