import React,{Fragment} from "react";
import {withRouter} from "react-router-dom";
import { MyFavouriteProvider, useMyFavourite } from "./Store";
import Loader from 'react-loader-spinner';
import ErrorContainer from "../All/ErrorContainer";
import Content from "./Content";
import Divider from "../All/Divider";
import { Container } from "react-bootstrap";

const MyFavourites = ({history})=>{

    return(
        <div className="backgroundProfesional">
            <Container>
                <Header />
                <MyFavouriteProvider history={history}>
                    <MyFavouriteTunnel />
                </MyFavouriteProvider>
            </Container>
        </div>
    )
}
export default withRouter(MyFavourites);

const Header = ()=>{

    return(
        <Fragment>
            <div className="d-flex justify-content-start mt-3">
                <span style={{fontSize:20,fontWeight:"bold",color:"#fff"}}>
                    Mis Favoritos.
                </span>
            </div>
            <Divider />
            <div style={{height:20}}></div>
        </Fragment>
    )
}
const MyFavouriteTunnel = ()=>{
    const {screenData,screenLoad,screenError,history} = useMyFavourite();
    if(!localStorage.conection){
        return <div className="p-2"><LoginComponent /></div>
    }
    if(screenLoad||(!screenData&&!screenError)){
        return(
            <div className="d-flex justify-content-center p-2">
              <Loader
                type="Oval"
                color={"#fff"}
                height={150}
                width={150}
                visible={true}
              />
            </div>
        )
    }
    if(screenError){
        return(
            <div className="p-2">
              <ErrorContainer 
                error={screenError}
                callback={{
                    label:"Regresar",
                    execute:()=>history.goBack()
                }}
              />
            </div>
        )
    }
    return <Content />
}
const LoginComponent = (props)=>{
    const {history} = useMyFavourite();
    return(
        <Fragment>
            <div className="d-flex justify-content-center">
                <button className="btn btn-dark mt-2" style={{backgroundColor:"#686868",width:200}} onClick={()=>history.push("/login")}>Iniciar Sesión</button>
            </div>
            <div className="d-flex justify-content-center mt-2">
                <button className="btn btn-dark mt-2" style={{backgroundColor:"#686868",width:200}} onClick={()=>history.push("/register")}>Registrarme</button>
            </div>
        </Fragment>
    )
}

