import React, {Fragment} from "react";
import {imageDoNotExist} from "../../../Utils/Const";
import { Slide } from 'react-slideshow-image';

const ImageSlider = (props)=>{
    const {images,execute,style,containerStyle} = props; 
    return(
        <Fragment>
            <Slide className="text-center" duration={5000} transitionDuration={500} infinite={true} indicators={false} scale={0.4} arrows={false}>
                {
                    images.map((each, index) => <ImgCmp key={index} item={each} execute={execute} containerStyle={containerStyle} style={style} />)
                }
            </Slide>
        </Fragment>
    )
}
export default ImageSlider;

const ImgCmp = (props)=>{
    const {item,execute,containerStyle,style} = props;
    let contStyle = {
      padding:10,
      width:"100%",
      height:"100%",
      ...containerStyle
    }
    let imgStyle = {
      width: "100%",
      height:"100%",
      cursor:"pointer",
      background:"#fff",
      ...style
    }
    return(
      <div style={contStyle} key={item.value}>
        <img onClick={()=>execute(item)} style={imgStyle} src={item.url||imageDoNotExist} alt="" />
      </div>
    )
}