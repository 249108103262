import React from "react";
import {BrowserRouter as Router,Switch,Route} from "react-router-dom";
import Home from "./Components/principal/Home/";
import Products from "./Components/principal/products/Product.js";
import Login from "./Components/principal/login/Login";
import Register from "./Components/principal/register/Register";
import Weare from "./Components/principal/weare/Weare";
import Cart from "./Components/principal/Cart";
import Search from "./Components/principal/search/Search";
import ActivateAccount from "./Components/principal/activate/verifyEmail";
import Quotation from "./Components/principal/pdf/Quotation";
import Contact from "./Components/principal/contact/Contact";
import Profile from "./Components/principal/profile/index";
import MyProfile from "./Components/principal/profile/MyProfile";
import RenewPassword from "./Components/principal/profile/RenewPassword";
import Billing from "./Components/principal/profile/Billing";
import Ubications from "./Components/principal/Ubications/Ubications";
//import PayMethods from "./Components/principal/payMethods/PayMethods";
import Thanks from "./Components/principal/Thanks";
import MyShopping from "./Components/principal/MyShopping";
import MyOrder from "./Components/principal/Order";
import FindByCategory from "./Components/principal/FindByCategory";
import Categorie from "./Components/principal/Categorie";
import Family from "./Components/principal/Family";
import SubCategorie from "./Components/principal/SubCategorie"
import Navbar from "./Components/principal/Navbar.js";
import Catalogo from "./Components/principal/Catalogo";
import FastBuy from "./Components/principal/profile/FastBuy";
import Validate from "./Components/principal/profile/Validate";
import MyFavourites from "./Components/principal/MyFavourites";
import NotFound from "./Components/principal/NotFound";
import NewNavBar from "./Components/principal/Navbar/index.js";
import InteractiveCatalog from "./Components/principal/InteractiveCatalog";

const Routes = (props)=>{
  const {idioms,idioma,toast,user,setUser,setReloadData,categories,locate} = props;
  return(
    <Router>
      <NewNavBar catList={categories} user={user} setUser={setUser} toast={toast} />
      <Switch>
        <Route exact path="/" component={()=><Home idioms={idioms} idioma={idioma} user={user} />} />
        <Route exact path="/cart" component={()=><Cart user={user} toast={toast} idioms={idioms} idioma={idioma} />} />
        <Route path="/products/:id" component={()=><Products toast={toast} idioms={idioms} idioma={idioma} />} />
        <Route exact path="/login" component={()=><Login setReloadData={setReloadData} user={user} toast={toast} idioms={idioms} idioma={idioma} />} />
        <Route exact path="/register" component={()=><Register setReloadData={setReloadData} user={user} setUser={setUser} idioms={idioms} idioma={idioma} toast={toast} />} />
        <Route exact path="/weare" component={()=><Weare idioms={idioms} idioma={idioma} />} />
        <Route exact path="/search" component={()=><Search idioms={idioms} idioma={idioma} />} />
        <Route exact path="/verifyEmail" component={()=><ActivateAccount idioms={idioms} idioma={idioma} />} />
        <Route exact path="/quotation" component={()=><Quotation idioms={idioms} idioma={idioma} />} />
        <Route exact path="/profile" component={()=><Profile idioms={idioms} idioma={idioma} user={user} />} />
        <Route exact path="/myprofile" component={()=><MyProfile idioms={idioms} idioma={idioma} user={user} />} />
        <Route exact path="/renewpassword" component={()=><RenewPassword idioms={idioms} idioma={idioma} user={user} />} />
        <Route exact path="/billing" component={()=><Billing idioms={idioms} idioma={idioma} user={user} toast={toast} />} />
        <Route exact path="/myfavourites" component={()=><MyFavourites idioms={idioms} idioma={idioma} user={user} toast={toast} />} />
        <Route exact path="/validate" component={()=><Validate idioms={idioms} idioma={idioma} user={user} toast={toast} />} />
        <Route exact path="/fastbuy" component={()=><FastBuy idioms={idioms} idioma={idioma} user={user} toast={toast} />} />
        <Route exact path="/ubications" component={()=><Ubications user={user} idioms={idioms} idioma={idioma} />} />
        {/* <Route exact path="/payMethods" component={()=><PayMethods user={user} idioms={idioms} idioma={idioma} />} /> */}
        
        <Route exact path="/contact" component={()=><Contact user={user} idioms={idioms} idioma={idioma} />} />
        <Route exact path="/catalogue" component={()=><Catalogo user={user} idioms={idioms} idioma={idioma} />} />
        <Route exact path="/interactive-catalog" component={()=><InteractiveCatalog user={user} idioms={idioms} idioma={idioma} />} />
        <Route exact path="/thanks" component={()=><Thanks user={user} idioms={idioms} idioma={idioma} />} />
        <Route exact path="/myshopping" component={()=><MyShopping user={user} idioms={idioms} idioma={idioma} />} />
        <Route exact path="/myorder" component={()=><MyOrder user={user} idioms={idioms} idioma={idioma} toast={toast}/>} />
        <Route exact path="/findbycetegorie" component={()=><FindByCategory user={user} idioms={idioms} idioma={idioma} />} />
        <Route exact path="/categorie" component={()=><Categorie user={user} idioms={idioms} idioma={idioma} />} />
        <Route exact path="/family" component={()=><Family user={user} idioms={idioms} idioma={idioma} />} />
        <Route exact path="/subcategories" component={()=><SubCategorie user={user} idioms={idioms} idioma={idioma} />} />
        <Route component={()=><NotFound />} />
      </Switch>
    </Router>
  )
}
export default(Routes);
